import { parsePhoneNumberFromString } from "libphonenumber-js";

interface PhoneNumberConstants {
  MIN_DIGITS: number;
  US_DIGITS: number;
  MAX_DIGITS: number;
  US_COUNTRY_CODE: string;
}

const PHONE_NUMBER_CONSTANTS: PhoneNumberConstants = {
  MIN_DIGITS: 10,
  US_DIGITS: 11,
  MAX_DIGITS: 15,
  US_COUNTRY_CODE: "1",
};

const isValidBasicFormat = (phoneNumber: string): boolean => {
  if (/[a-zA-Z]/.test(phoneNumber)) return false;

  const plusMatches = phoneNumber.match(/\+/g) || [];
  if (plusMatches.length > 1 || (plusMatches.length === 1 && !phoneNumber.startsWith("+"))) {
    return false;
  }

  const digits = phoneNumber.replace(/\D/g, "");
  return digits.length >= PHONE_NUMBER_CONSTANTS.MIN_DIGITS && digits.length <= PHONE_NUMBER_CONSTANTS.MAX_DIGITS;
};

const formatInternationalNumber = (phoneNumber: string, digits: string): string => {
  const parsedNumber = parsePhoneNumberFromString(phoneNumber);
  if (parsedNumber?.isValid() && !parsedNumber.country?.includes("US")) {
    return parsedNumber.format("E.164");
  }

  if (
    digits.length === PHONE_NUMBER_CONSTANTS.MIN_DIGITS &&
    !digits.startsWith(PHONE_NUMBER_CONSTANTS.US_COUNTRY_CODE)
  ) {
    return `+${PHONE_NUMBER_CONSTANTS.US_COUNTRY_CODE}${digits}`;
  }

  return `+${digits}`;
};

const formatUSNumber = (phoneNumber: string, digits: string): string => {
  if (digits.length === PHONE_NUMBER_CONSTANTS.MIN_DIGITS) {
    return `+${PHONE_NUMBER_CONSTANTS.US_COUNTRY_CODE}${digits}`;
  }

  if (digits.length === PHONE_NUMBER_CONSTANTS.US_DIGITS) {
    if (!digits.startsWith(PHONE_NUMBER_CONSTANTS.US_COUNTRY_CODE)) return "";
    return `+${digits}`;
  }

  const phone = parsePhoneNumberFromString(phoneNumber, "US");
  return phone?.isValid() ? phone.format("E.164") : "";
};

export const getCognitoPhoneNumber = (phoneNumber: string | null | undefined): string => {
  if (typeof phoneNumber !== "string" || !phoneNumber.trim()) return "";

  const trimmed = phoneNumber.trim();
  if (!isValidBasicFormat(trimmed)) return "";

  const digits = trimmed.replace(/\D/g, "");
  return trimmed.startsWith("+") ? formatInternationalNumber(trimmed, digits) : formatUSNumber(trimmed, digits);
};
