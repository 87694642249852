import { isNullOrUndefined } from "@rivial-security/func-utils";

/**
 * @description Regex class for regex operations and validation. This class is a wrapper for the native RegExp class.
 * It provides a set of methods to validate and manipulate regexes. It also provides a set of methods to match
 * and replace strings using regexes.
 * @param {string} email - the email string to check if a true email
 * @returns {boolean} - true if the email is a valid email
 */
export const CheckEmailRegex = (email) => {
  const emailRegex = new RegExp(
    '^(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$',
  );
  return emailRegex.test(email);
};

/**
 * Tests a given phone number to be valid for the platform
 * @param {string | null | undefined} phoneNumber - the text that represents a phone number to test
 * @returns {boolean} - TRUE if the provided phone number is valid
 */
export const CheckPhoneNumberRegex = (phoneNumber: string | null | undefined): boolean => {
  if (isNullOrUndefined(phoneNumber)) {
    return false;
  }

  const digits: string = phoneNumber!.replace(/\D/g, "");
  const hasPlusPrefix: boolean = phoneNumber!.startsWith("+");

  // Basic validation - must have + prefix
  if (!hasPlusPrefix) {
    return false;
  }

  // Edge case: if it starts with + but missing 1 for US format
  if (digits.length === 10) {
    return false; // Must have +1 prefix for 10-digit numbers
  }

  // US number validation:
  // Must start with +1
  // Must be exactly 11 digits (1 + 10 digits)
  // Area code must start with 2-9
  if (digits.startsWith("1")) {
    return digits.length === 11 && /^1[2-9]\d{9}$/.test(digits);
  }

  // International number validation:
  // Must start with + and 2-3 digit country code (not 1)
  // Must have 8-12 digits after country code
  return /^\+(?!1)[1-9]\d{1,2}[1-9]\d{7,12}$/.test(phoneNumber!);
};
