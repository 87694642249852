import React from "react";

import { NavigationSectionName } from "@application/Navigation/types";
import { IconName } from "@components/Ions/icons/components/Icons";

import { modules } from "@rivial-security/role-utils";

import i18next from "../../../../i18n/i18n";

import type { NavigationModule } from "@application/Navigation/types";

const MainDashboard = React.lazy(() => import("@dashboard/components/MainDashboard"));

export const dashboardNavigation: NavigationModule = {
  moduleName: modules.ACTION_CENTER,
  sectionName: NavigationSectionName.DASHBOARD,
  name: i18next.t("page title", { ns: "dashboard" }),
  path: "/dashboard",
  defaultRoutePath: "",
  icon: IconName.DASHBOARD,
  routes: [
    {
      path: "",
      name: i18next.t("page title", { ns: "dashboard" }),
      component: MainDashboard,
    },
  ],
};
