import React from "react";

import { dashboardNavigation } from "@application/Navigation/modules/astra/dashboardNavigation";

import type { Route } from "@application/Layout/hooks/types/Navigation";

const ActionCenter = React.lazy(() => import("@views/ActionCenter/ActionCenter"));

export const dashboardFallbackRoutes: Route[] = [
  {
    path: dashboardNavigation.path,
    exact: true,
    name: "Dashboard",
    component: ActionCenter,
  },
];
