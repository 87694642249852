import React from "react";

import { DETAILS_TYPES } from "@hooks/views/useGrid/enums/DETAILS_TYPES";
import CreateRecommendation from "@views/Risk/Recommendations/components/CreateRecommendation";
import { useRecommendationsDataGrid } from "@views/Risk/Recommendations/hooks/useRecommendationsDataGrid";

/**
 * Displays a list of Recommendations that are related directly to a System.
 * @param {string} organizationID - org ID for this system
 * @param {object} item - the system object
 */
export const useSystemRecommendationsGrid = ({ organizationID, item }) => {
  const recommendationGrid = useRecommendationsDataGrid({
    enablePersistence: false,
    persistenceUUID: "system-recommendations-grid",
    createResourceComponent: <CreateRecommendation organizationID={organizationID} system={item} />,
    detailsType: DETAILS_TYPES.NONE,
    enableQuickDetails: true,
    organizationID,
    systemID: item?.id,
  });

  return recommendationGrid;
};
