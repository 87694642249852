import { CUSTOM_CONTROL_FRAMEWORK_DASHBOARD } from "@compliance/api";
import { createQueryHook } from "@hooks/api/createQueryHook";

import type {
  CustomControlFrameworkDashboardQuery,
  CustomControlFrameworkDashboardQueryVariables,
} from "@graphql/types/graphql";

export const useCustomControlFrameworkDashboardName = createQueryHook<
  CustomControlFrameworkDashboardQuery,
  CustomControlFrameworkDashboardQueryVariables
>(CUSTOM_CONTROL_FRAMEWORK_DASHBOARD, "Unable to query custom control framework dashboard name");
