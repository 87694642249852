/**
 * Author: Anatoli Railean
 * Last Edit: 07/8/20 AR: Added required prop for the name field to be true
 */

import React, { useEffect, useState } from "react";

import {
  Button,
  Col,
  ListGroupItem,
  ListGroupItemHeading,
  ListGroupItemText,
  Row,
  Table,
  UncontrolledCollapse,
} from "reactstrap";

import { generateGraphql } from "@rivial-security/generategraphql";
import { modules, resources } from "@rivial-security/role-utils";

import { useCheckPermissions } from "../../../../../../hooks/permissions/useCheckPermissions/useCheckPermissions";
import { useModal } from "../../../../../../hooks/views/useModal";
import { useTable } from "../../../../../../hooks/views/useTable";
import { round } from "../../../../../../utils/Functions/Number/round";
import GenericEditField from "../../../../../../utils/GenericComponents/GenericEditFieldV2";
import NotEnoughData from "../../../../../../utils/GenericComponents/NotEnoughData";
import UncontrolledAccordionIcon from "../../../../../../utils/GenericComponents/UncontrolledAccordionIcon";
import CreateProgramSection from "../../ProgramSection/components/CreateProgramSection";
import ProgramSectionRow from "../../ProgramSection/components/ProgramSectionRow";
import MaturityProgressBar from "../../shared/MaturityProgressBar";
import { DeleteProgramElement } from "../functions/DeleteProgramElement";

const { updateMutation: updateProgramElement } = generateGraphql("ProgramElement", ["name"]);

/**
 * Displays a row of a Program Element
 *
 * @param item: ProgramElement object
 * @param resetFunction: Reset parent Program Blueprint
 * @param {boolean} [isTemplate = false] - determines if this UI needs to be trimmed down to display as a Template
 * @param module
 * @param resource
 * @param disableRoleChecking
 * @returns {*}
 * @constructor
 */

const ProgramElementRow = ({
  item,
  resetFunction,
  isTemplate = false,
  module = modules.GOVERNANCE,
  resource = resources.PROGRAM_ELEMENT,
  disableRoleChecking = false,
}) => {
  const [programSectionList, setProgramSectionList] = useState([]);
  const [currentImplementationLevels, setCurrentImplementationLevels] = useState([]);
  const [goalImplementationLevels, setGoalImplementationLevels] = useState([]);

  const checkPermissions = useCheckPermissions({
    module,
    resource,
    disableRoleChecking,
  });

  const checkPermissionsProgramSection = useCheckPermissions({
    module,
    resource: resources.PROGRAM_SECTION,
    disableRoleChecking,
  });

  useEffect(() => {
    if (item && item.programSections && item.programSections.items) {
      setProgramSectionList(item.programSections.items);
    }
  }, [item]);

  useEffect(() => {
    if (programSectionList && programSectionList.length > 0) {
      const currentImplementationLevelsLocal = [];
      const goalImplementationLevelsLocal = [];

      for (const section of programSectionList) {
        if (section.currentLevel && section.currentLevel.level) {
          currentImplementationLevelsLocal.push(section.currentLevel.level);
        }

        if (section.goalLevel && section.goalLevel.level) {
          goalImplementationLevelsLocal.push(section.goalLevel.level);
        }
      }

      setCurrentImplementationLevels(currentImplementationLevelsLocal);
      setGoalImplementationLevels(goalImplementationLevelsLocal);
    }
  }, [programSectionList]);

  const calculateArrayAverage = (array) => {
    let total = 0;
    for (const item of array) {
      total += item;
    }

    return isNaN(total / array.length) ? 0 : round(total / array.length, 1);
  };

  const programSectionTableHook = useTable({
    data: programSectionList,
    rowComponent: (
      <ProgramSectionRow
        isTemplate={isTemplate}
        module={module}
        resource={resource}
        disableRoleChecking={disableRoleChecking}
      />
    ),
    resetFunction,
    module,
    resource: resources.PROGRAM_SECTION,
    disableRoleChecking: disableRoleChecking,
  });

  useEffect(() => {
    programSectionTableHook.setData(programSectionList);
  }, [programSectionList]);

  const AddSingleProgramSectionHook = useModal(
    "Create a Program Section",
    <CreateProgramSection
      resetFunction={resetFunction}
      programElement={item}
      organizationID={item?.ownerGroup}
      module={module}
      resource={resource}
      disableRoleChecking={disableRoleChecking}
    />,
    <Button
      data-testid={"create_item-section"}
      id={"create_item-section"}
      size="sm"
      color="ghost-success"
      className="float-right btn-pill"
      title="Create a new Program Section"
    >
      <i className="icon-plus" /> Add a Program Section
    </Button>,
    {},
  );

  return (
    <ListGroupItem key={item.id ? item.id : JSON.stringify(item)}>
      <ListGroupItemHeading
        data-testId="program-element-row"
        id={`programElement${item && item.id}`}
        style={{
          cursor: "pointer",
        }}
      >
        <Row>
          <Col sm={4}>
            <span className="float-left">
              <GenericEditField
                item={item}
                mutation={updateProgramElement}
                module={module}
                resource={resource}
                disableRoleChecking={disableRoleChecking}
                field="name"
                required={true}
                deleteButton={
                  <>
                    {checkPermissions.resource.delete ? (
                      <Button
                        size="sm"
                        color="ghost-danger"
                        className="float-right btn-pill"
                        title="Delete Program Element"
                        onClick={() => {
                          if (window.confirm(`Are you sure you want to remove ${item && item.name}?`)) {
                            DeleteProgramElement(item).then(() => {
                              resetFunction?.();
                            });
                          }
                        }}
                      >
                        <i className="icon-trash" />
                      </Button>
                    ) : null}
                  </>
                }
              />
            </span>
          </Col>
          <Col sm={7}>
            <span className="float-left" style={{ marginRight: "1em" }}>
              Level: {calculateArrayAverage(currentImplementationLevels)}
            </span>
            <span className="float-right" style={{ marginLeft: "1em" }}>
              Goal: {calculateArrayAverage(goalImplementationLevels)}
            </span>
            <MaturityProgressBar
              currentLevel={calculateArrayAverage(currentImplementationLevels)}
              goalLevel={calculateArrayAverage(goalImplementationLevels)}
            />
          </Col>
          <Col sm={1}>
            <UncontrolledAccordionIcon toggler={`programElement${item && item.id}`} />
          </Col>
        </Row>
      </ListGroupItemHeading>
      <ListGroupItemText>
        <UncontrolledCollapse toggler={`#programElement${item && item.id}`}>
          <div className="shadow" style={{ padding: "1em 1em 0 1em" }}>
            <div className="float-right" style={{ marginBottom: "1em" }}>
              {checkPermissionsProgramSection.resource.create && AddSingleProgramSectionHook.modalButton}
            </div>
            {Array.isArray(programSectionList) && programSectionList.length > 0 ? (
              programSectionTableHook.display
            ) : (
              <Table responsive hover style={{ height: "100%" }}>
                <thead>
                  <tr>
                    {checkPermissionsProgramSection.resource.create ? (
                      <NotEnoughData
                        message={"There are no program sections in this program element."}
                        callToAction={{
                          message: " to add a program section.",
                          function: () => AddSingleProgramSectionHook.setModalIsOpen(true),
                        }}
                        itemName={"element"}
                      />
                    ) : (
                      <NotEnoughData
                        message={
                          "There are no program sections in this program element and you do not have permissions to create new ones."
                        }
                      />
                    )}
                  </tr>
                </thead>
              </Table>
            )}
          </div>
        </UncontrolledCollapse>
      </ListGroupItemText>
    </ListGroupItem>
  );
};

export default ProgramElementRow;
