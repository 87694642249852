import type { ComponentPropsWithoutRef, ReactElement } from "react";

import { format, parse } from "date-fns";
import { merge } from "lodash";
import { useTranslation } from "react-i18next";

import LineChartCard from "@components/Molecules/Card/LineChartCard/LineChartCard";

import type { AxisProps } from "@nivo/axes";

/**
 * Wraps the LineChartCard component with default props to format a bare line chart to display percentage values on the y-axis.
 */
const PercentageLineChartCard = ({
  data,
  legend,
  slotProps,
  title,
  onClick,
  isLoading,
  className,
}: ComponentPropsWithoutRef<typeof LineChartCard>): ReactElement => {
  const { t } = useTranslation();

  const yTickValues = [0, 25, 50, 75, 100];

  const percentageLeftAxis: AxisProps = {
    format: (value: number) => `${value}%`,
    legend: t("percentage"),
    tickValues: yTickValues,
  };

  const percentageBottomAxis: AxisProps = {
    legend: t("date"),
    legendOffset: 64,
    format: (value: string) => format(parse(value, "M/dd/yyyy", new Date()), "MMM yy"),
    tickRotation: -45,
  };

  // Create base configuration object
  const baseLineChartConfig = {
    lineChart: {
      gridYValues: yTickValues,
      yScale: {
        type: "linear",
        min: 0,
        max: 100,
      },
      axisLeft: percentageLeftAxis,
      axisBottom: percentageBottomAxis,
    },
  };

  // Merge configurations
  const mergedSlotProps = merge(baseLineChartConfig, slotProps);

  return (
    <LineChartCard
      className={className}
      data={data}
      legend={legend}
      title={title}
      onClick={onClick}
      isLoading={isLoading}
      slotProps={mergedSlotProps}
    />
  );
};

export default PercentageLineChartCard;
