import { getLongRiskControlName } from "./getLongRiskControlName";

/**
 * Parser function returning information about a risk control includes override information
 * @param {object} item - an object containing evidence, standardRiskControl, and riskControlOverride
 * @param {object} [item.evidence] - if included will be added to the object
 * @param {object} item.standardRiskControl - main source of information
 * @param {object} [item.riskControlOverride] - if included will override some values in standardRiskControl
 * @returns {object} an object ready to use in the useNonStandardRiskControlGrid hook
 */
export const getDetailedRiskControlItem = ({ evidence, standardRiskControl, riskControlOverride } = {}) => {
  const controlID = riskControlOverride.riskControlId;

  //generate a unique id for this connection
  let systemOverrideID = standardRiskControl?.id;
  if (!systemOverrideID) {
    systemOverrideID = controlID;
  }
  if (riskControlOverride.system?.id) {
    systemOverrideID += `|${riskControlOverride.system.id}`;
  }

  const fullControl = {
    id: systemOverrideID,
    evidence,
    risk: standardRiskControl?.controlCategoryName ?? standardRiskControl?.controlCategory?.name ?? "Unknown Risk",
    controlName: getLongRiskControlName({ control: standardRiskControl }),
    control: standardRiskControl,
    implementationRating:
      riskControlOverride?.implementationRating ?? standardRiskControl?.implementationRating ?? "Unknown",
    implementationDetails: riskControlOverride?.implementationDetails ?? "",
    costOfControl: riskControlOverride?.costOfControl ?? standardRiskControl?.costOfControl ?? "Unknown",
    system: riskControlOverride?.system,
    systemName: riskControlOverride?.system?.name ?? standardRiskControl?.system?.name ?? "Unknown System",
  };

  return fullControl;
};
