import { useActionDataGrid } from "../../../../views/Program/Actions/hooks/useActionDataGrid";
import { generatedNestedFieldsForDefinition } from "../../../functions/queries/generatedNestedFieldsForDefinition";
import { noteDefinition } from "../common/note";

const gridQueryFields = [
  "name",
  "description",
  "status",
  "pointOfContact",
  "dueDate",
  "priority",
  "completionDate",
  "recommendations",
  "tags",
  "enableNotifications",
  "targetVulnerabilityLinkID",
];

const detailsDisplayFields = [
  ...gridQueryFields.filter((field) => field !== "recommendations"),
  "module",
  "landingPageUrl",
  "createdAt",
];

const detailsQueryFields = [...detailsDisplayFields, "priorityHistory", "recommendations", "notes", "data"];

export const actionItemDefinition = {
  routes: {
    grid: "governance/action_items",
    details: "governance/action_items",
    helpCenter: "action-items",
  },

  schema: {
    indexNames: {
      byOwnerGroup: "actionsByOwnerGroup",
    },
  },

  //[APPEARANCE]
  displayName: "Action Item",
  icon: "simple-line-icons:rocket",

  //[COMPONENTS]
  gridHook: useActionDataGrid,

  fields: {
    name: {},
    description: {},
    status: {},
    module: {},
    pointOfContact: {
      default: {
        nestedFields: `{firstName lastName email id}`,
      },
    },
    landingPageUrl: {},
    dueDate: {},
    priority: {},
    completionDate: {},
    enableNotifications: {},
    createdAt: {},
    priorityHistory: {
      default: {
        nestedFields: `{ value reason date }`,
      },
    },
    recommendations: {
      default: {
        nestedFields: `(limit: 100) { items { recommendation { observations (limit: 100) { items { observation { isFinding } } } } } }`,
      },
      details: {
        nestedFields: `(limit: 1000) { items { recommendation { name observations (limit: 1000) { items { observation { name module risk formalResponse } } } } } }`,
      },
    },
    notes: {
      default: {
        nestedFields: generatedNestedFieldsForDefinition({
          definition: noteDefinition,
        }),
      },
    },
    data: {},
    tags: {
      default: {
        nestedFields: `(limit: 100) { items { __typename id tag { id name description fontColor backgroundColor } } }`,
      },
    },
    targetVulnerabilityLinkID: {},
  },

  views: {
    grid: {
      queryFields: gridQueryFields,
    },
    details: {
      displayFields: detailsDisplayFields,
      queryFields: detailsQueryFields,
    },
  },
};
