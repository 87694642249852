/** Use WebAPIs to parse a given string and ensure it is valid */
export class CssColor {
  private readonly value: string;

  private constructor(color: string) {
    this.value = color;
  }

  public static create(color = ""): CssColor {
    const style = new Option().style;
    style.color = color;

    return new CssColor(style.color);
  }

  public toString(): string {
    return this.value;
  }

  public equals(other: CssColor): boolean {
    return this.value === other.value;
  }

  public getValue(): string {
    return this.value;
  }
}
