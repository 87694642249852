import type { ReactElement } from "react";

import cx from "classnames";
import AutoSizer from "react-virtualized-auto-sizer";

import GridCard, { GridCardSize } from "@components/Molecules/Card/GridCard/GridCard";
import LineChart from "@components/Molecules/LineChart/LineChart";

import { CardFooter, type CardFooterProps } from "../shared/CardFooter";

import styles from "./LineChartCard.module.scss";

import type { GridCardProps } from "@components/Molecules/Card/GridCard/GridCard";
import type { LineChartProps } from "@components/Molecules/LineChart/LineChart";

export interface LineChartCardProps {
  /** Title of the card */
  title: GridCardProps["title"];
  /** Performs an action when clicked */
  onClick?: GridCardProps["onClick"];
  /** Shows a loading state */
  isLoading: GridCardProps["isLoading"];
  /** Data for the line chart */
  data: LineChartProps["data"];
  /** Text to display for each key provided */
  legend?: string[];
  /** The colors to use for the radar chart */
  colors?: string[];
  /** The className for the card */
  className?: string;
  /** Props for the child components */
  slotProps?: {
    /** Props for the underlying GridCard component */
    gridCard?: Omit<Partial<GridCardProps>, "title" | "onClick" | "isLoading">;
    /** Props for the LineChart component */
    lineChart?: Omit<LineChartProps, "data" | "width" | "height">;
    /** Props for the CardFooter component */
    footer?: Partial<CardFooterProps>;
  };
}

/**
 * Shows a nivo line chart for given series data in a card component.
 * Line charts represent variation in y over x.
 */
const LineChartCard = ({
  title,
  onClick,
  isLoading,
  data,
  legend,
  className,
  slotProps,
}: LineChartCardProps): ReactElement => {
  const { gridCard, lineChart, footer } = slotProps || {};

  return (
    <GridCard
      title={title ?? ""}
      showTopSeparator
      hasData={!!data?.length}
      size={GridCardSize.EXPAND}
      className={cx(styles["line-chart"], className)}
      onClick={onClick}
      isLoading={isLoading}
      footer={footer && legend ? <CardFooter keys={legend} {...footer} /> : undefined}
      {...gridCard}
    >
      <div className={styles["line-chart__chart-container"]}>
        <AutoSizer>
          {({ width, height }) => {
            return <LineChart data={data} width={width} height={height} {...lineChart} />;
          }}
        </AutoSizer>
      </div>
    </GridCard>
  );
};

export default LineChartCard;
