import React, { useEffect, useState } from "react";

import PostAdd from "@mui/icons-material/PostAdd";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";

import { useUIContext } from "@utils/Context/UIContext";

import { formattedPercent, isNullOrUndefined } from "@rivial-security/func-utils";
import { generateGraphql } from "@rivial-security/generategraphql";
import { REPORT_TEMPLATES } from "@rivial-security/report-utils";
import { modules as MODULES, resources } from "@rivial-security/role-utils";

import { useCheckPermissions } from "../../../../hooks/permissions/useCheckPermissions/useCheckPermissions";
import { useAccordion } from "../../../../hooks/views/useAccordion/useAccordion";
import { useDetailsCard } from "../../../../hooks/views/useDetailsCardV2";
import AccessControl from "../../../../utils/AccessControl/components/AccessControl";
import AddAccessControl from "../../../../utils/AccessControl/components/AddAccessControl";
import Dashboard from "../../../../utils/GenericComponents/Dashboard";
import GenerateReportModal from "../../../../utils/GenericComponents/GenerateReportModal";
import AutomationsButton from "../../../OrganizationManager/Automations/components/AutomationsButton";
import FindingsCard from "../../../Program/Findings/components/FindingsCard/FindingsCard";
import useConfigurePlaybookButton from "../../Incidents/components/useConfigurePlaybookButton";
import GroupPlaybook from "../../Plan/Playbooks/components/GroupPlaybook";
import { usePlaybookTotalCompletionTracker } from "../../Plan/Playbooks/hooks/usePlaybookTotalCompletionTracker";
import { getProceduresNestedFields } from "../../PlaybookBuilder/functions/getProceduresNestedFields";
import ExerciseDocuments from "../components/ExerciseDocuments";
import ExerciseInjections from "../components/ExerciseInjections";
import ExerciseMeetingButton from "../components/ExerciseMeetingButton";
import ExerciseObjectives from "../components/ExerciseObjectives";
import ExerciseResponseTeams from "../components/ExerciseResponseTeams";
import ExerciseResponseTeamsButton from "../components/ExerciseResponseTeamsButton";
import UploadExerciseDocumentButton from "../components/UploadExerciseDocumentButton";

/**
 * @description Exercise Details hook
 * @param {string} itemId - exercise id
 * @param {string} organizationID - selected organization
 * @param {object} props
 * @returns {object} {{isLoading: boolean | undefined, setInputId: {(value: (((prevState: *) => *) | *)): void, (value: (((prevState: undefined) => undefined) | undefined)): void}, inputId: * | undefined, item: unknown, tableDisplay: *, display: *|JSX.Element, reset: function(): void, setItem: {(value: unknown): void, (value: (((prevState: undefined) => undefined) | undefined)): void}}}
 */
export const useExerciseDetails = ({ itemId, organizationID, ...props }) => {
  const module = MODULES.INCIDENT_RESPONSE;
  const resource = resources.EXERCISE;

  const { width } = useUIContext();

  const checkPermissionsHook = useCheckPermissions({ module, resource });

  const { getQuery, updateMutation } = generateGraphql(
    "Exercise",
    [
      "name",
      "description",
      "scenario",
      "documents",
      "observations",
      "injections",
      "objectives",
      "responseTeams",
      "meeting",
      "accessControl",
      "notes",
      "procedures",
    ],
    {
      documents: `(limit: 500) { items { id createdAt name avStatus lastAVCheck file { key bucket region } ownerGroup } }`,
      observations: `(limit: 1000) { items { id name recommendations (limit: 500) { items { id recommendation { id name } } } } }`,
      injections: `{ id name met notes { id type ownerGroup author timeStamp content tag observationID }}`,
      objectives: `{ id name met notes { id type ownerGroup author timeStamp content tag observationID }}`,
      responseTeams: `(limit: 500) { items { id responseTeam { id name description pointOfContactLinks (limit: 500) { items { id pointOfContact { id firstName lastName title email phone } } nextToken } }} }`,
      meeting: `{ id  }`,
      notes: `{ id type ownerGroup author timeStamp content tag observationID }`,
      accessControl: `{ password passwordOwnerEmail roles pointOfContacts }`,
      ...getProceduresNestedFields({ includeNotes: true }),
    },
  );

  const fields = ["name", "description", "scenario"];

  const queryConfig = {
    query: getQuery,
    itemId,
  };

  const detailsConfig = {
    fields,
    updateMutation,
  };

  const actions = [
    {
      name: "Generate an Exercise Report",
      onClick: () => {},
      icon: <PostAdd />,
    },
  ];

  const detailsHook = useDetailsCard({
    queryConfig,
    detailsConfig,
    module,
    resource,
    config: {
      enableNotes: true,
      observationConnectionField: "exerciseID",
    },
    ...props,
  });

  const { configurePlaybookModalButton, setPlaybookBuilderModalIsOpen } = useConfigurePlaybookButton({
    item: detailsHook?.item,
    resetFunction: detailsHook?.reset,
    typename: "Exercise",
    organizationID,
  });

  // default the switch ON if there are no checklist items yet
  const [enableEditsObjectives, setEnableEditsObjectives] = useState(false);
  const [enableEditsInjections, setEnableEditsInjections] = useState(false);

  useEffect(() => {
    if (isNullOrUndefined(detailsHook?.item?.objectives?.length) || detailsHook?.item?.objectives?.length < 1) {
      setEnableEditsObjectives(checkPermissionsHook.resource.update);
    }
    if (isNullOrUndefined(detailsHook?.item?.injections?.length) || detailsHook?.item?.injections?.length < 1) {
      setEnableEditsInjections(checkPermissionsHook.resource.update);
    }
  }, [detailsHook.item]);

  const { setTotalCompletion, completedDecimal } = usePlaybookTotalCompletionTracker({ item: detailsHook?.item });

  const accordion = useAccordion({
    items: [
      {
        title: "General",
        subTitle: "Context of the Exercise",
        component: detailsHook.tableDisplay,
        icon: "icon-list",
        defaultExpanded: true,
      },
      {
        title: "Findings",
        subTitle: "Associated Observations, Recommendations, and Actions",
        component: <FindingsCard exercise={detailsHook?.item} organizationID={organizationID} />,
        icon: "icon-rocket",
      },
      {
        title: "Injections",
        subTitle: "Special circumstances surrounding the training or Incident details",
        component: (
          <div>
            <ExerciseInjections item={detailsHook?.item} disableEdits={!enableEditsInjections} />
            {checkPermissionsHook.resource.update && (
              <FormControlLabel
                style={{ position: "absolute", bottom: 0, right: 0 }}
                className={"float-right"}
                control={
                  <Switch
                    size="small"
                    onClick={() => setEnableEditsInjections(!enableEditsInjections)}
                    checked={enableEditsInjections}
                    className={"float-right"}
                  />
                }
                label="Edits"
              />
            )}
          </div>
        ),
        icon: "icon-shuffle",
      },
      {
        title: "Objectives",
        subTitle: "Tasks to complete during training sessions",
        component: (
          <div>
            <ExerciseObjectives item={detailsHook?.item} disableEdits={!enableEditsObjectives} />
            {checkPermissionsHook.resource.update && (
              <FormControlLabel
                style={{ position: "absolute", bottom: 0, right: 0 }}
                className={"float-right"}
                control={
                  <Switch
                    size="small"
                    onClick={() => setEnableEditsObjectives(!enableEditsObjectives)}
                    checked={enableEditsObjectives}
                    className={"float-right"}
                  />
                }
                label="Edits"
              />
            )}
          </div>
        ),
        icon: "icon-target",
      },
      {
        title: "Response Teams",
        subTitle: "People involved in this practice scenario",
        component: (
          <div style={{ height: "30em" }}>
            <ExerciseResponseTeams item={detailsHook?.item} resetFunction={detailsHook?.reset} />
          </div>
        ),
        headerButtons: checkPermissionsHook.resource.update
          ? [
              <ExerciseResponseTeamsButton
                item={detailsHook?.item}
                organizationID={organizationID}
                resetFunction={detailsHook?.reset}
              />,
            ]
          : [],
        icon: "icon-people",
      },
      {
        title: "Documents",
        subTitle: "Uploaded supplemental files",
        component: <ExerciseDocuments item={detailsHook?.item} />,
        headerButtons: checkPermissionsHook.resource.update
          ? [
              <UploadExerciseDocumentButton
                item={detailsHook?.item}
                organizationID={organizationID}
                resetFunction={detailsHook?.reset}
              />,
            ]
          : [],
        icon: "icon-docs",
      },
      {
        title: "Playbook",
        subTitle: `Procedures to complete in response to the hypothetical Incident
        ${completedDecimal !== 0 ? `(${formattedPercent(completedDecimal)} Done)` : ""}`,
        component: (
          <GroupPlaybook
            module={module}
            resource={resource}
            typename={"Exercise"}
            item={{ ...detailsHook?.item, resetIndex: detailsHook?.resetIndex }}
            resetFunction={detailsHook.reset}
            setTotalCompletion={setTotalCompletion}
            setPlaybookBuilderModalIsOpen={setPlaybookBuilderModalIsOpen}
            observationConnectionField={"exerciseID"}
            exerciseID={itemId}
          />
        ),
        icon: "icon-book-open",
        headerButtons: [...(checkPermissionsHook?.resource?.update ? [configurePlaybookModalButton] : [])],
      },
    ],
    titleFlexBasis: "30%",

    /**
     * NOTE: This is needed for injection/objectives checklists to hold state without having to re-query data
     * or pass back updated info from children
     */
    unmountOnExit: false,
  });

  const display = (
    <AccessControl item={detailsHook?.item} resetFunction={detailsHook?.reset} typename={"Exercise"}>
      <Dashboard
        item={detailsHook?.item}
        subTitle={detailsHook?.item?.name}
        actions={actions}
        headerButtons={
          checkPermissionsHook.resource.update && [
            <GenerateReportModal
              itemId={itemId}
              organizationID={organizationID}
              type={REPORT_TEMPLATES.INCIDENT_RESPONSE_EXERCISE_TEMPLATE}
              module={MODULES.INCIDENT_RESPONSE}
            />,
            <ExerciseMeetingButton item={detailsHook?.item} resetFunction={detailsHook.reset} />,
            <AddAccessControl resetFunction={detailsHook?.reset} typename={"Exercise"} accessControlEnabled={true} />,
            <AutomationsButton itemId={itemId} typename={"Exercise"} />,
          ]
        }
        resetFunction={detailsHook.reset}
      >
        {accordion.display}
      </Dashboard>
    </AccessControl>
  );

  return {
    ...detailsHook,
    display,
  };
};
