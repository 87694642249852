import { useEffect } from "react";

import { COMPLIANCE_TREND_COORDINATES } from "@compliance/api";
import { createQueryHook } from "@hooks/api/createQueryHook";

import type { QueryHookOptions } from "@apollo/client";
import type { ComplianceTrendCoordinatesQuery, ComplianceTrendCoordinatesQueryVariables } from "@graphql/types/graphql";
import type { QueryHookResult } from "@hooks/api/createQueryHook";

const useBaseComplianceTrendData = createQueryHook<
  ComplianceTrendCoordinatesQuery,
  ComplianceTrendCoordinatesQueryVariables
>(COMPLIANCE_TREND_COORDINATES, "Unable to query compliance trend coordinates");

export const useComplianceTrendData = (
  options: QueryHookOptions<ComplianceTrendCoordinatesQuery, ComplianceTrendCoordinatesQueryVariables>,
): QueryHookResult<ComplianceTrendCoordinatesQuery, ComplianceTrendCoordinatesQueryVariables> => {
  const queryResult = useBaseComplianceTrendData(options);
  const { data, fetchMore } = queryResult;

  // Fetch additional compliance trend data when more is available
  // This effect handles pagination by fetching more data when a nextToken is present
  useEffect(() => {
    const nextToken = data?.controlFramework.complianceTrendCoordinates?.nextToken;

    if (nextToken && fetchMore) {
      void fetchMore({
        variables: {
          nextToken,
        },
        // Using updateQuery allows us to manually control how new data is merged
        // This is important because:
        // 1. We can preserve existing data while adding new items
        // 2. We maintain a single source of truth in Apollo cache
        // 3. We can handle the merge logic specific to our data structure
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prev;

          // Combine previous and new items arrays while handling potential null values
          // This creates a continuous timeline of compliance trend data
          return {
            controlFramework: {
              ...prev.controlFramework,
              complianceTrendCoordinates: {
                ...fetchMoreResult.controlFramework.complianceTrendCoordinates,
                items: [
                  ...(prev.controlFramework.complianceTrendCoordinates?.items ?? []),
                  ...(fetchMoreResult.controlFramework.complianceTrendCoordinates?.items ?? []),
                ],
              },
            },
          };
        },
      });
    }
  }, [data?.controlFramework.complianceTrendCoordinates?.nextToken, fetchMore]);

  return queryResult;
};
