import type { ReactElement, ReactNode } from "react";

import cx from "classnames";
import { useTranslation } from "react-i18next";

import IconNoData from "@assets/svg/others/icon-no-data.svg";
import Text, { TextVariant } from "@components/Atoms/Text/Text";
import { TextColor } from "@components/Ions/colors/constants/Color";
import Spinner, { SpinnerVariant } from "@components/Ions/motion/components/Spinner";

import styles from "./GridCardContent.module.scss";

export interface GridCardContentProps {
  /** Whether data is still being retrieved to display. Shows the spinner icon if `true`. */
  isLoading?: boolean;
  /** Whether there is data to be displayed in the grid card. Used to control displaying the "No Data" icon. */
  hasData: boolean;
  /** Set to `true` when the user hasn't purchased the required module to view this data. */
  moduleNotPurchased?: boolean;
  /** Set to `true` when the user doesn't have permission to access this data. */
  accessRestricted?: boolean;
  /** Content to render when in the normal content state */
  children?: ReactNode;
}

const NoDataIcon = () => <IconNoData className={styles["grid-card-content__icon"]} />;
const Container = ({ children, className }: { children: ReactNode; className?: string }) => (
  <div className={cx(styles["grid-card-content"], className)}>{children}</div>
);
const DescriptiveText = ({ children }) => (
  <Text htmlElement="span" color={TextColor.LIGHT_SECONDARY} variant={TextVariant.BODY3}>
    {children}
  </Text>
);
const TitleText = ({ children }) => (
  <Text htmlElement="span" color={TextColor.LIGHT_PRIMARY} variant={TextVariant.SUBTITLE2}>
    {children}
  </Text>
);

/**
 * A component that centralizes the logic for rendering different states of a grid card.
 * States include content, loading, no data, access restricted, and module not purchased.
 */
const GridCardContent = ({
  isLoading,
  hasData,
  moduleNotPurchased,
  accessRestricted,
  children,
}: GridCardContentProps): ReactElement => {
  const { t } = useTranslation();

  // Determine what to render based on the props
  if (moduleNotPurchased) {
    return (
      <Container>
        <NoDataIcon />
        <TitleText>{t("module.not included")}</TitleText>
        <DescriptiveText>{t("module.purchase required")}</DescriptiveText>
      </Container>
    );
  }

  if (accessRestricted) {
    return (
      <Container>
        <NoDataIcon />
        <TitleText>{t("access.restricted")}</TitleText>
        <DescriptiveText>{t("access.contact admin")}</DescriptiveText>
      </Container>
    );
  }

  if (isLoading) {
    return (
      <Container className={styles["grid-card-content--no-bg"]}>
        <Spinner className={styles["grid-card-content__loading-spinner"]} variant={SpinnerVariant.BLUE} />
        <DescriptiveText>{t("loading")}</DescriptiveText>
      </Container>
    );
  }

  if (!hasData) {
    return (
      <Container className={styles["grid-card-content--no-bg"]}>
        <NoDataIcon />
        <DescriptiveText>{t("no data")}</DescriptiveText>
      </Container>
    );
  }

  return <>{children}</>;
};

export default GridCardContent;
