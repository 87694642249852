import type { ReactElement } from "react";

import GridCard, { GridCardSize } from "@components/Molecules/Card/GridCard/GridCard";
import RadarChart from "@components/Molecules/RadarChart/RadarChart";

import CardFooter from "../shared/CardFooter";

import styles from "./RadarChartCard.module.scss";

import type { CardFooterProps } from "../shared/CardFooter";
import type { GridCardProps } from "@components/Molecules/Card/GridCard/GridCard";
import type { RadarChartProps } from "@components/Molecules/RadarChart/RadarChart";

export interface RadarChartCardProps {
  /** Title of the card */
  title: GridCardProps["title"];
  /** Performs an action when clicked */
  onClick: GridCardProps["onClick"];
  /** Shows a loading state */
  isLoading: GridCardProps["isLoading"];
  /** Data for the radar chart */
  data: RadarChartProps["data"];
  /** Property to use as the radar chart index */
  indexBy: RadarChartProps["indexBy"];
  /** The properties with values to display on the radar chart */
  keys: RadarChartProps["keys"];
  /** Text to display for each key provided */
  legend: string[];
  /** The colors to use for the radar chart */
  colors?: string[];
  /** Props for the child components */
  slotProps?: {
    /** Props for the underlying GridCard component */
    gridCard?: Omit<Partial<GridCardProps>, "title" | "onClick" | "isLoading">;
    /** Props for the RadarChart component */
    radarChart?: Omit<RadarChartProps, "data" | "indexBy" | "keys" | "legend">;
    /** Props for the CardFooter component */
    footer?: Partial<CardFooterProps>;
  };
}

/**
 * A card component that displays a radar chart for comparing multiple data series across categories.
 * Wraps the RadarChart component in a GridCard with loading state and title support.
 * Uses slotProps pattern to pass props to child components.
 */
const RadarChartCard = ({
  data,
  indexBy,
  isLoading,
  keys,
  legend,
  onClick,
  slotProps,
  title,
}: RadarChartCardProps): ReactElement => {
  const { gridCard, radarChart, footer } = slotProps || {};
  const margin = { top: 30, right: 20, bottom: 20, left: 50 };

  return (
    <GridCard
      title={title}
      showTopSeparator
      showBottomSeparator
      hasData={!!data?.length}
      size={GridCardSize.EXPAND}
      className={styles["radar-chart"]}
      onClick={onClick}
      isLoading={isLoading}
      footer={<CardFooter keys={legend} {...footer} />}
      {...gridCard}
    >
      <div className={styles["radar-chart__chart-container"]}>
        <RadarChart data={data} indexBy={indexBy} keys={keys} margin={margin} {...radarChart} />
      </div>
    </GridCard>
  );
};

export default RadarChartCard;
