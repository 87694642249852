export const disallowedFileUploadMimeTypes = new Set([
  "application/x-msdownload",
  "application/x-msdos-program",
  "application/x-executable",
  "application/vnd.microsoft.portable-executable",
  "application/x-elf",
  "application/x-sh",
  "application/x-binary",
  "application/x-mach-binary",
  "application/x-mach-o-binary",
  "application/x-macbinary",
  "application/x-python",
  "application/x-perl",
  "application/x-ruby",
  "application/x-php",
  "application/javascript",
]);
