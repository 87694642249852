import type { ReactElement } from "react";

import { GENERIC_FIELD_TYPES } from "@utils/GenericComponents/GenericEditFieldV3/constants/GENERIC_FIELD_TYPES";
import GenericEditFieldV3 from "@utils/GenericComponents/GenericEditFieldV3/GenericEditFieldV3";

import { enumToDropdownData } from "@rivial-security/func-utils";
import { modules, resources } from "@rivial-security/role-utils";
import type { GenericActionItem } from "@rivial-security/schema-types";
import { ActionItemStatus } from "@rivial-security/schema-types";

import { useStatusField } from "./useStatusField";

interface StatusProps {
  item: GenericActionItem;
  updateItemById: VoidFunction;
  resetFunction?: VoidFunction;
  field?: string;
}

/**
 * Displays the Status for an Action Item
 */
const Status = ({ item, updateItemById, resetFunction, field = "status", ...rest }: StatusProps): ReactElement => {
  const { customFormat, mutationFunction, sendEmailConfirmation } = useStatusField(item, resetFunction);
  const module = modules.GOVERNANCE;
  const resource = resources.ACTION_ITEM;

  return (
    <GenericEditFieldV3
      item={item}
      inputType={GENERIC_FIELD_TYPES.DROPDOWN}
      inputConfig={{
        data: enumToDropdownData({ ENUM: ActionItemStatus }),
      }}
      customFormat={customFormat}
      displayConfig={{
        forceSentenceCase: false,
      }}
      confirmFunction={sendEmailConfirmation}
      mutationFunction={({ status }: { status: ActionItemStatus }) => {
        return mutationFunction(status);
      }}
      field={field}
      updateItemById={updateItemById}
      resource={resource}
      module={module}
      {...rest}
    />
  );
};

export default Status;
