import { useTranslation } from "react-i18next";

import { useOrganizationAudits } from "@compliance/hooks";
import { ArtifactGroup } from "@graphql/types/graphql";

import type { BaseUseDownloadArtifactsFilter } from "./types/DownloadArtifactsFilter";
import type { SelectOption } from "@components/Atoms/SelectField/SelectField";
import type { UserCreateableFrameworkTemplatesQuery } from "@graphql/types/graphql";

interface UseDownloadArtifactsFilter extends BaseUseDownloadArtifactsFilter {
  getOrganizationAudits: VoidFunction;
  organizationAuditsLoading: boolean;
}

export const useDownloadArtifactsOrganizationFilter = (
  frameworks?: UserCreateableFrameworkTemplatesQuery,
): UseDownloadArtifactsFilter => {
  const { t } = useTranslation("compliance");

  const {
    execute: getOrganizationAudits,
    data: organizationAuditsData,
    loading: organizationAuditsLoading,
  } = useOrganizationAudits();

  const frameworkOptions = [
    {
      label: t("all frameworks", { ns: "compliance" }),
      value: "all",
    },
    ...(frameworks?.userCreateableFrameworkTemplates ?? []),
  ] satisfies SelectOption[];

  const auditOptions = [
    {
      label: t("all audits", { ns: "compliance" }),
      value: "all",
    },
    ...(organizationAuditsData?.audits?.items ?? []),
  ] satisfies SelectOption[];

  const getFilterOptions = (groupBy: ArtifactGroup): SelectOption[] => {
    if (groupBy === ArtifactGroup.Audit && auditOptions.length <= 1) {
      return [];
    }

    if (groupBy !== ArtifactGroup.Audit && frameworkOptions.length <= 1) {
      return [];
    }

    return groupBy === ArtifactGroup.Audit ? auditOptions : frameworkOptions;
  };

  return {
    getOrganizationAudits,
    getFilterOptions,
    organizationAuditsLoading,
  };
};
