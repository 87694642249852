import React from "react";

import { Grid } from "@mui/material";

import { modules, resources } from "@rivial-security/role-utils";

import PermissionsOverlay from "../../../../utils/Overlays/PermissionsOverlay";
import EvidenceMultiDocumentViewer from "../../Evidence/components/EvidenceDocumentViewers/EvidenceMultiDocumentViewer";
import { useArtifactDetails } from "../General/hooks/useArtifactDetails";

import ValidationPanel from "./components/ValidationPanel";

/**
 * Page for validating a single artifact with a built-in viewer and details panel on the right side
 * @param {string} organizationID - the currently selected organization ID
 * @param {string} itemId - the database ID of the artifact to validate
 * @param {string} module - the module this artifact validation is taking place for role checking
 * @param {string} resource - the resource this artifact validation is taking place, for role checking
 * @returns {{display: JSX.Element}}
 */
export const useArtifactValidation = ({
  organizationID,
  itemId,
  module = modules.COMPLIANCE,
  resource = resources.ARTIFACT,
}) => {
  const detailsHook = useArtifactDetails({ itemId, module, resource });

  const display = (
    <PermissionsOverlay module={module} resource={resource}>
      <Grid container spacing={1} style={{ padding: 0, margin: 0 }}>
        <Grid lg={8} md={7} sm={7} xs={7} item>
          <div style={{ minHeight: "85vh" }}>
            <EvidenceMultiDocumentViewer artifact={detailsHook?.item} organizationID={organizationID} />
          </div>
        </Grid>
        <Grid lg={4} md={5} sm={5} xs={5} item>
          <ValidationPanel organizationID={organizationID} artifact={detailsHook?.item} detailsHook={detailsHook} />
        </Grid>
      </Grid>
    </PermissionsOverlay>
  );

  return { display };
};
