export enum ReactstrapColor {
  SUCCESS = "success",
  WARNING = "warning",
  DANGER = "danger",
  SECONDARY = "secondary",
  PRIMARY = "primary",
}

export interface PriorityScaleSegment {
  text: string;
  color: ReactstrapColor;
  chipTextColor: string;
  chipBackgroundColor: string;
  maxPriority: number;
}

export const PriorityLevel = {
  NONE: "None",
  LOW: "Low",
  LOW_MEDIUM: "Low-Medium",
  MEDIUM: "Medium",
  MEDIUM_HIGH: "Medium-High",
  HIGH: "High",
  CRITICAL: "Critical",
};
