import React from "react";

import Tooltip from "@mui/material/Tooltip";
import { withStyles } from "@mui/styles";
import { Button } from "reactstrap";

import { useModal } from "../../../../../../hooks/views/useModal";
import KnowBe4PhishingDetails from "../../../../../Tools/KnowBe4/Phishing/components/KnowBe4PhishingDetails";
import { getFullName } from "../functions/getFullName";

/**
 * @description Display Phishing Activity for an Evidence
 * @param {string} phishingID - phishing id
 * @param {object} pointOfContact - point of contact
 * @returns {JSX.Element}
 * @constructor
 */
const PhishingActivity = ({ phishingID, pointOfContact }) => {
  const detailsModal = useModal(
    "Phishing Details",
    <KnowBe4PhishingDetails item={{ pst_id: phishingID }} />,
    <Button color={"success"}>
      <i className={"icon-eye"} style={{ marginRight: "1em" }} />
      View Phishing Test
    </Button>,
    {
      width: "80vw",
    },
  );

  const fullName = getFullName({ pointOfContact });

  return (
    <span>
      {detailsModal.modal}
      {fullName || "Someone"} referenced a{" "}
      <LightTooltip arrow interactive title={<div>{detailsModal.modalButton}</div>}>
        <span
          style={{
            borderBottom: "2px dashed",
            textDecoration: "none",
            cursor: "pointer",
          }}
        >
          Phishing Test
        </span>
      </LightTooltip>
    </span>
  );
};

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "rgb(238,237,237)",
    color: "rgba(0, 0, 0, 0.87)",
  },
}))(Tooltip);

export default PhishingActivity;
