import { type ReactElement } from "react";

import cx from "classnames";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";

import { useControlFrameworksComplianceTrends } from "@compliance/hooks";
import { ChartTooltip } from "@components/Molecules/Card/LineChartCard/ChartTooltip";
import PercentageLineChartCard from "@components/Molecules/Card/LineChartCard/PercentageLineChartCard";
import { CardFooter } from "@components/Molecules/Card/shared/CardFooter";
import { defaultColors } from "@components/Molecules/LineChart/LineChart";
import { useCheckPermissions } from "@hooks/permissions/useCheckPermissions/useCheckPermissions";

import { modules } from "@rivial-security/role-utils";

import styles from "./ComplianceHistoryCard.module.scss";

import type { PointTooltip } from "@nivo/line";

export interface ComplianceHistoryCardProps {
  className?: string;
  onClick?: VoidFunction;
}

const Tooltip: PointTooltip = ({ point }): ReactElement => {
  const dateValue = point.data.x;

  return (
    <ChartTooltip
      point={{
        ...point,
        data: {
          ...point.data,
          xFormatted: format(new Date(dateValue), "MM/dd/yyyy"),
        },
      }}
    />
  );
};

const ComplianceHistoryCard = ({ className, onClick }: ComplianceHistoryCardProps): ReactElement => {
  const { t } = useTranslation(["compliance"]);
  const {
    data: complianceData,
    hasData,
    loading: isComplianceLoading,
  } = useControlFrameworksComplianceTrends({
    monthsToFetch: 12,
  });

  const permissions = useCheckPermissions({
    module: modules.COMPLIANCE,
  });

  return (
    <PercentageLineChartCard
      title={t("compliance history card.title")}
      data={complianceData}
      isLoading={isComplianceLoading}
      legend={complianceData.map(({ id }) => id)}
      onClick={onClick}
      slotProps={{
        gridCard: {
          className: cx(styles["compliance-history-card"], className),
          hasData,
          helpText: t("compliance history card.help text"),
          accessRestricted: permissions.module.isEnabled === false,
          showTopSeparator: true,
          showBottomSeparator: true,
          footer: (
            <CardFooter colors={defaultColors} keys={complianceData.map(({ id }) => id)}>
              {t("compliance history card.footer text")}
            </CardFooter>
          ),
        },
        lineChart: {
          xScale: {
            type: "time",
            precision: "day",
            format: "%m/%d/%Y",
          },
          axisLeft: {
            legend: t("compliance history card.y"),
          },
          axisBottom: {
            format: "%m/%d/%y",
            legend: t("compliance history card.x"),
            legendOffset: 40,
            tickRotation: 0,
            tickValues: 4,
          },
          tooltip: Tooltip,
        },
      }}
    />
  );
};

export default ComplianceHistoryCard;
