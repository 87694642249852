import { GetQuery } from "@rivial-security/appsync-utils";
import { useEffect } from "react";

import { withOrganizationCheck } from "@utils/Context/withOrganizationCheck";
import TabCard from "@utils/GenericComponents/TabCard";
import { getDetailedRiskControlItem } from "@views/Compliance/EvidenceRiskControlLinks/functions/getDetailedRiskControlItem";
import { listSystemRiskOverrides } from "@views/Compliance/EvidenceRiskControlLinks/functions/listSystemRiskOverrides";
import { getRiskControlSystems } from "@views/Risk/graphql/getRiskControlSystems";
import { useNonStandardControlsGrid } from "@views/Risk/RiskControls/hooks/useNonStandardControlsGrid";
import RiskControlEvidenceList from "@views/Risk/Systems/components/SystemAccordion/ControlCategories/components/Controls/components/RiskControlEvidenceList";
import SystemDetails from "@views/Risk/Systems/components/SystemDetails";
import { useSystemDataGrid } from "@views/Risk/Systems/hooks/useSystemDataGrid";

import { processControlSystemLinks } from "../functions/processControlSystemLinks";

/**
 * Displays lists of systems that are associated with a risk control (both standard and non-standard)
 * @param {string} organizationID - the organization identifier for the risk control
 * @param {object} riskControl - risk control for which to show systems (at least the id)
 * @return {JSX.Element}
 */
const RiskControlSystems = ({ organizationID, riskControl } = {}) => {
  const queryConfig = {
    query: null,
  };

  const gridConfig = {
    groupSettings: {
      columns: [],
      showDropArea: false,
    },
    detailsComponent: <SystemDetails organizationID={organizationID} />,
    route: "#/risk/systems/",
  };

  const fields = [
    {
      name: "risk",
      visible: false,
    },
    {
      name: "controlName",
      visible: false,
    },
    {
      name: "evidences",
      visible: true,
      component: <RiskControlEvidenceList riskControl={riskControl} isNonStandard={true} includeHeader={false} />,
    },
  ];

  const nonStandardControlsGrid = useNonStandardControlsGrid({
    organizationID,
    fieldOverrides: fields,
    ...queryConfig,
    ...gridConfig,
    riskControlID: riskControl?.id,
  });

  const standardSystemsGrid = useSystemDataGrid({
    organizationID,
    queryConfig,
  });

  //Query and set data in a use effect (if risk control doesn't have the system data)
  useEffect(() => {
    const getData = async () => {
      const item = await GetQuery({
        query: getRiskControlSystems,
        variables: {
          id: riskControl?.id,
        },
      });

      let riskControlEvidenceLinks = item?.evidences?.items;

      //Filter out any links without a system
      riskControlEvidenceLinks = riskControlEvidenceLinks?.filter((link) => link?.system?.id);

      //For each link with a system create a new object with the system override data
      const evidenceData = {};
      const systemsEncountered = {};
      const systemsWithOverrides = [];
      riskControlEvidenceLinks?.forEach((link) => {
        if (link?.system?.id) {
          //Non standard control
          const systemWasEncountered = systemsEncountered.hasOwnProperty(link.system.id);
          if (systemWasEncountered) {
            evidenceData[`${link?.system?.id}|${link?.riskControl?.id}`].push(link?.evidence);
            return;
          } else {
            systemsEncountered[link.system.id] = true;
            evidenceData[`${link?.system?.id}|${link?.riskControl?.id}`] = [link?.evidence];
          }

          //Get overrides for the system
          let systemOverrides = listSystemRiskOverrides({
            system: link?.system,
          });

          //Filter out any system overrides that don't have the risk control id
          systemOverrides = systemOverrides?.filter((override) => override?.riskControlId === riskControl?.id);

          //Check there is at least one override for the risk control
          if (systemOverrides.length < 1) {
            return;
          }

          //If found the correct override then use its data in the grid
          systemsWithOverrides.push({
            ...getDetailedRiskControlItem({
              standardRiskControl: riskControl,
              riskControlOverride: systemOverrides?.[0],
              evidences: evidenceData[`${link?.system?.id}|${link?.riskControl?.id}`] ?? [],
            }),
            id: link?.system?.id,
          });
        }
      });

      const controlSystemLinks = item?.controlCategory?.systems?.items;
      const { updatedSystemsEncountered, controlSystemLinksWithOverrides } = processControlSystemLinks({
        controlSystemLinks,
        systemsEncountered,
        riskControl,
      });

      Object.assign(systemsEncountered, updatedSystemsEncountered);
      systemsWithOverrides.push(...controlSystemLinksWithOverrides);

      //Find all systems with the risk control but no overrides for it
      const standardSystems = [];
      if (Array.isArray(controlSystemLinks)) {
        for (const link of controlSystemLinks) {
          // - get the system
          const system = link?.system;
          // - check that the system has not been added to the non-standard list, if not added it to the standard list
          if (!systemsEncountered.hasOwnProperty(system?.id)) {
            standardSystems.push(system);
          }
        }
      }
      //Set the data to the grids
      nonStandardControlsGrid.setData(systemsWithOverrides);
      standardSystemsGrid.setData(standardSystems);
    };

    getData();
  }, [riskControl?.systems]);

  return (
    <TabCard
      items={[
        {
          title: "Standard",
          total: (standardSystemsGrid?.data ?? []).length,
          component: <div style={{ height: "30em" }}> {standardSystemsGrid.gridDisplay}</div>,
          icon: <i className={"icon-energy"} />,
        },
        {
          title: "Non-Standard",
          total: (nonStandardControlsGrid?.data ?? []).length,
          component: <div style={{ height: "30em" }}> {nonStandardControlsGrid.gridDisplay}</div>,
          icon: <i className={"icon-vector"} />,
        },
      ]}
      badgeColor={"primary"}
    />
  );
};

export default withOrganizationCheck(RiskControlSystems);
