import { useLazyQuery } from "@apollo/client";

import { getIsLoading, handleError } from "./utils/queryHookUtils";

import type {
  DocumentNode,
  LazyQueryExecFunction,
  OperationVariables,
  QueryHookOptions,
  TypedDocumentNode,
} from "@apollo/client";

export interface LazyQueryHookResult<TData, TVariables extends OperationVariables> {
  execute: LazyQueryExecFunction<TData, TVariables>;
  loading: boolean;
  data?: TData;
  refetch?: ReturnType<typeof useLazyQuery<TData, TVariables>>[1]["refetch"];
  fetchMore?: ReturnType<typeof useLazyQuery<TData, TVariables>>[1]["fetchMore"];
}

export const createLazyQueryHook = <TData, TVariables extends OperationVariables>(
  query: DocumentNode | TypedDocumentNode<TData, TVariables>,
  errorMessage: string,
) => {
  return (options?: QueryHookOptions<TData, TVariables>): LazyQueryHookResult<TData, TVariables> => {
    const [execute, { loading, error, data, refetch, networkStatus, fetchMore }] = useLazyQuery<TData, TVariables>(
      query,
      {
        ...options,
        notifyOnNetworkStatusChange: true,
      },
    );

    handleError(error, errorMessage);

    return {
      execute,
      loading: getIsLoading(loading, networkStatus, data),
      data,
      refetch,
      fetchMore,
    };
  };
};
