import type { ComponentPropsWithoutRef, ForwardedRef, ReactElement } from "react";

import cx from "classnames";
import { forwardRef } from "react";

import styles from "./List.module.scss";

export type ListProps = ComponentPropsWithoutRef<"ol">;

/** An ordered list with clickable items. Useful for creating menus or dropdown selections. Only accepts children of type `List`. */
const List = forwardRef(
  ({ children, className, ...rest }: ListProps, ref: ForwardedRef<HTMLOListElement>): ReactElement => {
    const classNames = cx(styles.list, className);

    return (
      <ol ref={ref} className={classNames} {...rest}>
        {children}
      </ol>
    );
  },
);

List.displayName = "List";

export default List;
