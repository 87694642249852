import { useMutation } from "@apollo/client";

import { CONTROL_FRAMEWORK_DASHBOARDS, DELETE_CONTROL_FRAMEWORK_DASHBOARD } from "@compliance/api";

import type { FetchResult } from "@apollo/client";
import type {
  DeleteControlFrameworkDashboardMutation,
  DeleteControlFrameworkDashboardMutationVariables,
} from "@graphql/types/graphql";

interface UseDeleteDashboardResult {
  deleteControlFrameworkDashboard: (
    variables: DeleteControlFrameworkDashboardMutationVariables,
  ) => Promise<FetchResult<DeleteControlFrameworkDashboardMutation>>;
  isLoading: boolean;
}

export const useDeleteControlFrameworkDashboard = (): UseDeleteDashboardResult => {
  const [mutateFunction, { loading }] = useMutation<
    DeleteControlFrameworkDashboardMutation,
    DeleteControlFrameworkDashboardMutationVariables
  >(DELETE_CONTROL_FRAMEWORK_DASHBOARD, {
    refetchQueries: [CONTROL_FRAMEWORK_DASHBOARDS],
  });

  const deleteControlFrameworkDashboard = (
    variables: DeleteControlFrameworkDashboardMutationVariables,
  ): Promise<FetchResult<DeleteControlFrameworkDashboardMutation>> => mutateFunction({ variables });

  return {
    deleteControlFrameworkDashboard,
    isLoading: loading,
  };
};
