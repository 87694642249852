import { useMemo } from "react";

import { subMonths } from "date-fns";

import { CONTROL_FRAMEWORKS_COMPLIANCE_TRENDS } from "@compliance/api";
import { createQueryHook } from "@hooks/api/createQueryHook";

import type {
  ControlFrameworksComplianceTrendsQuery,
  ControlFrameworksComplianceTrendsQueryVariables,
} from "@graphql/types/graphql";

interface ComplianceLineData {
  x: Date;
  y: number;
}

interface ComplianceSeries {
  id: string;
  data: ComplianceLineData[];
}

interface QueryOptions {
  skip?: boolean;
  variables?: ControlFrameworksComplianceTrendsQueryVariables;
  monthsToFetch?: number;
}

const baseHook = createQueryHook<
  ControlFrameworksComplianceTrendsQuery,
  ControlFrameworksComplianceTrendsQueryVariables
>(CONTROL_FRAMEWORKS_COMPLIANCE_TRENDS, "Error fetching compliance trends data");

export const useControlFrameworksComplianceTrends = (options: QueryOptions = {}) => {
  const { monthsToFetch = 12, ...queryOptions } = options;

  // Calculate date range if not provided
  const dateRange = useMemo(() => {
    if (options.variables?.dateRange) {
      return options.variables.dateRange;
    }

    const today = new Date();
    const from = subMonths(today, monthsToFetch);

    return {
      from,
    };
  }, [options.variables?.dateRange, monthsToFetch]);

  const updatedOptions = {
    ...queryOptions,
    variables: {
      ...queryOptions.variables,
      dateRange,
    },
  };

  const result = baseHook(updatedOptions);

  // Transform data for the line chart
  const data: ComplianceSeries[] = useMemo(() => {
    if (!result.data?.controlFrameworksComplianceTrends) {
      return [];
    }

    return result.data.controlFrameworksComplianceTrends.map((framework) => ({
      id: framework.id,
      data: framework.data.map(({ x, y }) => ({
        x: new Date(x),
        y,
      })),
    }));
  }, [result.data]);

  return {
    ...result,
    data,
    hasData: data && data.length > 0 && data.some((series) => series.data.length > 0),
  };
};
