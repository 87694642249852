import Text, { TextVariant } from "@components/Atoms/Text/Text";
import Tooltip from "@components/Atoms/Tooltip/Tooltip";
import { defaultColors } from "@components/Molecules/RadarChart/RadarChart";

import styles from "./Legend.module.scss";

import type { RadarChartCardProps } from "../RadarChartCard/RadarChartCard";

export interface LegendProps {
  /**
   * Array of key names to display in the legend
   */
  keys: RadarChartCardProps["keys"];

  /**
   * Color mapping for each key
   */
  colors?: RadarChartCardProps["colors"];
}

/**
 * Legend component for radar chart that displays series names with their color swatches
 */
const Legend = ({ keys, colors = defaultColors }: LegendProps) => {
  return (
    <div className={styles.legend}>
      {keys?.map((key, index) => (
        <div key={key} className={styles.legend__item}>
          <div className={styles.legend__swatch} style={{ backgroundColor: colors[index] }} />
          <Tooltip content={key}>
            <Text variant={TextVariant.CAPTION} className={styles.legend__text}>
              {key}
            </Text>
          </Tooltip>
        </div>
      ))}
    </div>
  );
};

export default Legend;
