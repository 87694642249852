import React from "react";

import { modules, resources } from "@rivial-security/role-utils";

import { useQueryGetItem } from "../../../../../../../../../hooks/graphql/useQueryGetItem";
import { withOrganizationCheck } from "../../../../../../../../../utils/Context/withOrganizationCheck";
import NotesV2 from "../../../../../../../../../utils/Notes/components/NotesV2";
import CreateRecommendation from "../../../../../../../Recommendations/components/CreateRecommendation";
import { getNotes } from "../../../graphql/getNotes";
import { updateNotes } from "../../../graphql/updateNotes";

/**
 * Sub Control Notes
 * @param {object} item - the control
 * @param {string} label - the label for the notes
 * @param {string} organizationID - current organization ID
 * @param {boolean} disableTitle - boolean indicating if the title should be disabled
 * @param {object} system - the System that this Control is associated with
 * @param {boolean} isNonStandard - boolean indicating if this Control is Non-Standard
 * @param {boolean} disableEdits - boolean indicating if the edits should be disabled
 * @return {JSX.Element}
 */
const SubControlNotes = ({ item, label, organizationID, disableTitle, system, isNonStandard, disableEdits }) => {
  const module = modules.RISK;
  const resource = resources.RISK_CONTROL;

  const query = useQueryGetItem({
    itemId: item?.id,
    query: getNotes,
    module,
    resource,
  });

  return (
    <NotesV2
      label={label}
      item={query.item}
      isLoading={query?.isLoading}
      module={module}
      resource={resource}
      tooltip="These are Organization-Level notes for this sub control in general. These notes will be visible to all Information Systems associated with this Sub-Control."
      organizationID={organizationID}
      mutation={updateNotes}
      resetFunction={query.reset}
      maxInitialNotes={1}
      disableTitle={disableTitle}
      disableEdits={disableEdits}
      observationConnectionField={"riskControlID"}
      observationFormOverride={
        <CreateRecommendation
          organizationID={organizationID}
          system={isNonStandard ? system : undefined} // only pre-set the 'system' if this Control is Non-Standard
          riskControl={item}
        />
      }
    />
  );
};

export default withOrganizationCheck(SubControlNotes);
