import { ErrorLogger, InfoLogger } from "../../EventLogger";

import { GetQuery } from "./GetQuery";

/**
 * @description Generic Delete Function
 * Note: Your parent object or query should have "__typename" field
 * Note: Pass ether just an object to delete or a query string with an id
 * @param {object} params - main object to pass
 * @param {string} [query] - graphql string
 * @param {string} [id] - id of an item to be deleted
 * @param {object} objectToDelete - object to be deleted
 * @return {object} {Promise<Array|null>}
 */
export const GenericDeleteGQL = async ({ query, id, objectToDelete }) => {
  if (objectToDelete) {
    if (typeof objectToDelete !== "object" || Object.keys(objectToDelete).length === 0) {
      ErrorLogger("Error! Invalid input for GenericDeleteGQL. Missing object to delete.");
      return null;
    }
  } else if (query && id) {
    if (!query || !id || typeof query !== "string" || typeof id !== "string") {
      ErrorLogger("Error! Invalid input for GenericDeleteGQL. Missing query or id.");
      return null;
    }
  }

  const genericDeleteGraphql = `
    query genericDeleteGQL($input: AWSJSON) {
      genericDeleteGQL(input: $input)
    }
  `;

  InfoLogger("Sending request to the GenericDeleteGQL lambda.");

  return await GetQuery({
    query: genericDeleteGraphql,
    variables: {
      input: JSON.stringify({
        dataToDelete: objectToDelete,
        graphql: {
          query: query,
          id: id,
        },
      }),
    },
  });
};
