import cx from "classnames";
import { Progress } from "reactstrap";

import { useFeatureFlagsContext } from "../../../../../featureFlags/FeatureFlagsProvider";
import styles from "../shared/MaturityProgressBar.module.scss";

interface MaturityProgressBarProps {
  /** The current implementation level */
  currentLevel: number;
  /** The target implementation level */
  goalLevel: number;
  /** Optional pre-calculated percentage (if provided, will use this instead of calculating from levels) */
  percentage?: number;
}

/**
 * Displays a progress bar for showing maturity level progress
 */
const MaturityProgressBar = ({ currentLevel, goalLevel, percentage }: MaturityProgressBarProps) => {
  const { flags } = useFeatureFlagsContext();

  const calculatePercent = (): number => {
    if (percentage !== undefined) {
      return percentage;
    }

    if (currentLevel && goalLevel) {
      const res = (currentLevel / goalLevel) * 100;
      return isNaN(res) ? 0 : res;
    }
    return 0;
  };

  const percent = calculatePercent();

  return (
    <Progress
      className={cx({
        [styles["progress-bar"]!]: flags?.isHomepageEnabled,
      })}
      value={percent}
    >
      {Math.round(percent)}%
    </Progress>
  );
};

export default MaturityProgressBar;
