import { ErrorLogger } from "@utils/EventLogger";

import { generateGraphql } from "@rivial-security/generategraphql";

import { QueryGetItem } from "../../../../../hooks/graphql/useQueryGetItem";
import { GenericDeleteGQL } from "../../../../../utils/Functions/Graphql/GenericDeleteGQL";
import { ItemMutation } from "../../../../../utils/Functions/Graphql/ItemMutation";
import { DeleteS3Object } from "../../../../../utils/Functions/S3Storage/DeleteS3Object";

/**
 * @description: Delete a single artifact from the database
 * @param {object} item - The artifact to delete
 * @returns {Promise<void>}
 * @constructor
 */
export const deleteArtifact = async (item) => {
  /**
   * Validate the input
   */
  if (!item || !item?.id) {
    return null;
  }

  const { getQuery: queryArtifactDocuments } = generateGraphql("Artifact", ["file", "evidenceActivity"], {
    file: `{ bucket key }`,
    evidenceActivity: `{ id documents { items { id file { bucket key } } } }`,
  });

  const artifactWithDocuments = await QueryGetItem({
    query: queryArtifactDocuments,
    itemId: item?.id,
  });

  if (artifactWithDocuments?.id) {
    try {
      if (artifactWithDocuments?.file?.bucket && artifactWithDocuments?.file?.key) {
        await DeleteS3Object({
          bucketName: artifactWithDocuments?.file?.bucket,
          objectKey: artifactWithDocuments?.file?.key,
          organizationID: artifactWithDocuments?.ownerGroup,
        });
      }
    } catch (e) {
      ErrorLogger(`Error! Cannot delete artifact file from S3: ${JSON.stringify(e)}`);
    }

    if (artifactWithDocuments?.evidenceActivity?.id) {
      try {
        await Promise.allSettled(
          artifactWithDocuments?.evidenceActivity?.documents?.items?.map(async (document) => {
            await ItemMutation(generateGraphql("Document").deleteMutation, {
              id: document?.id,
            });
            await DeleteS3Object({
              bucketName: document?.file?.bucket,
              objectKey: document?.file?.key,
              organizationID: artifactWithDocuments?.ownerGroup,
            });
          }),
        );
      } catch (e) {
        ErrorLogger(`Error! Cannot delete document file from S3: ${JSON.stringify(e)}`);
      }
    }
  }

  const { getQuery: queryArtifact } = generateGraphql("Artifact", ["__typename"]);

  /**
   * Get the artifact from the database and delete it
   */
  return await QueryGetItem({ query: queryArtifact, itemId: item?.id }).then(async (artifact) => {
    await GenericDeleteGQL({ objectToDelete: artifact });
  });
};
