/**
 * Updates the raw input before it's passed to the rest of the function
 *
 * @param {string|number|boolean} value
 * @param {boolean} [forceLowercase]
 * @param {function} [updateInputFunction]
 * @param {function} [setValue]
 * @returns {string}
 */
export const handleUpdateInput = async ({ value, forceLowercase, updateInputFunction, setValue }) => {
  /**
   * Mutate the value based on configurations
   */
  let finalValue = value;

  /**
   * Forces the value to be lower case
   */
  if (forceLowercase) {
    finalValue = value.toLowerCase();
  }

  /**
   * Pipes the finalValue through the optional updateInputFunction
   */
  if (updateInputFunction && typeof updateInputFunction === "function") {
    finalValue = await updateInputFunction(finalValue);
  }

  if (finalValue) setValue(finalValue);

  return finalValue;
};
