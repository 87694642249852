import { gql } from "@graphql/types";

export const CONTROL_FRAMEWORKS_COMPLIANCE_TRENDS = gql(`
  query controlFrameworksComplianceTrends($dateRange: DateRange) {
    controlFrameworksComplianceTrends(dateRange: $dateRange) {
      id: name
      data: coordinates {
        x
        y
      }
    }
  }
`);
