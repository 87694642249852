import { useQuery } from "@apollo/client";

import { getIsLoading, handleError } from "./utils/queryHookUtils";

import type { DocumentNode, OperationVariables, QueryHookOptions, TypedDocumentNode } from "@apollo/client";

export interface QueryHookResult<TData, TVariables extends OperationVariables> {
  loading: boolean;
  data?: TData;
  refetch: ReturnType<typeof useQuery<TData, TVariables>>["refetch"];
  fetchMore?: ReturnType<typeof useQuery<TData, TVariables>>["fetchMore"];
}

export const createQueryHook = <TData, TVariables extends OperationVariables>(
  query: DocumentNode | TypedDocumentNode<TData, TVariables>,
  errorMessage: string,
) => {
  return (options?: QueryHookOptions<TData, TVariables>): QueryHookResult<TData, TVariables> => {
    const { loading, error, data, refetch, networkStatus, fetchMore } = useQuery<TData, TVariables>(query, {
      ...(options || {}),
      notifyOnNetworkStatusChange: true,
    });

    handleError(error, errorMessage);

    return {
      loading: getIsLoading(loading, networkStatus, data),
      data,
      refetch,
      fetchMore,
    };
  };
};
