import type { ReactElement } from "react";

import Text, { TextVariant } from "@components/Atoms/Text/Text";
import Tooltip from "@components/Atoms/Tooltip/Tooltip";
import { TextColor } from "@components/Ions/colors/constants/Color";

import type { Point } from "@nivo/line";

interface ChartTooltipProps {
  /** The point data to display in the tooltip. */
  point: Point;
}

/**
 * A tooltip component that displays the formatted x and y values of a point on a line chart.
 */
export const ChartTooltip = ({ point }: ChartTooltipProps): ReactElement => {
  const { xFormatted, yFormatted } = point.data;
  const seriesName = point.serieId;

  return (
    <Tooltip
      inPortal={false}
      slotProps={{
        root: {
          defaultOpen: true,
        },
      }}
      content={
        <Text variant={TextVariant.CAPTION} color={TextColor.DARK_PRIMARY}>
          {xFormatted}
          <br />
          {`${seriesName}: ${Number(yFormatted).toFixed(1)}%`}
        </Text>
      }
    >
      <div />
    </Tooltip>
  );
};
