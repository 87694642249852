import type { RefObject } from "react";
import { useEffect, useRef, useState } from "react";

export interface HandleEllipsisDetectionParams<T> {
  elementRef: RefObject<T>;
  isEllipsisActive: boolean;
}

const useEllipsisDetection = <T extends HTMLElement>({
  content,
  isMultiLine = false,
}: {
  content?: string | number | null;
  isMultiLine?: boolean;
}): HandleEllipsisDetectionParams<T> => {
  const elementRef = useRef<T>(null);
  const [isEllipsisActive, setIsEllipsisActive] = useState<boolean>(false);

  useEffect(() => {
    if (elementRef.current) {
      if (isMultiLine) {
        const lineHeight = parseInt(window.getComputedStyle(elementRef.current).lineHeight);
        const maxHeight = parseFloat(window.getComputedStyle(elementRef.current).maxHeight);
        const actualHeight = elementRef.current.scrollHeight;
        const isActualHeightGreaterThanMaxHeight = actualHeight > maxHeight;
        const isActualHeightGreaterThanThreeLines = actualHeight > lineHeight * 3;
        setIsEllipsisActive(isActualHeightGreaterThanMaxHeight || isActualHeightGreaterThanThreeLines);
      } else {
        const elementVisibleWidth = elementRef.current.offsetWidth;
        const elementEntireWidth = elementRef.current.scrollWidth;
        setIsEllipsisActive(elementEntireWidth > elementVisibleWidth);
      }
    }
  }, [content, isMultiLine]);

  return { elementRef, isEllipsisActive };
};

export default useEllipsisDetection;
