import { useQuery } from "@apollo/client";
import { useCallback, useEffect, useState } from "react";

import { SortDirection } from "@rivial-security/schema-types";

import { CONTROL_FRAMEWORK_DASHBOARDS } from "@compliance/api";
import { ErrorLogger } from "@utils/EventLogger";

import type { ControlFrameworkDashboardsQuery } from "@graphql/types/graphql";

interface UseComplianceFrameworkDashboardsResult {
  loading: boolean;
  error?: Error;
  data?: ControlFrameworkDashboardsQuery;
  frameworkDashboardLogos: Record<string, string>;
}

export const useControlFrameworkDashboards = (): UseComplianceFrameworkDashboardsResult => {
  const { loading, error, data } = useQuery<ControlFrameworkDashboardsQuery>(CONTROL_FRAMEWORK_DASHBOARDS, {
    variables: {
      sort: { name: SortDirection.ASC },
    },
  });
  const [frameworkDashboardLogos, setFrameworkDashboardLogos] = useState<Record<string, string>>({});

  const getDashboardLogos = useCallback(() => {
    const dashboards = data?.controlFrameworkDashboards?.items || [];

    return dashboards.reduce((logoMap: Record<string, string>, dashboard) => {
      const logo = dashboard.controlFramework.dashboardLogo;
      if (logo) logoMap[dashboard.id] = logo;
      return logoMap;
    }, {});
  }, [data]);

  useEffect(() => {
    if (data) {
      setFrameworkDashboardLogos(getDashboardLogos());
    }
  }, [data, getDashboardLogos]);

  useEffect(() => {
    if (error) {
      ErrorLogger("Unable to fetch logos for control framework dashboards", error);
    }
  }, [error]);

  return {
    loading,
    error,
    data,
    frameworkDashboardLogos,
  };
};
