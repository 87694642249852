export const THEME_COLORS = [
  "pink",
  "red",
  "orange",
  "yellow",
  "lime",
  "green",
  "teal",
  "blue",
  "ultramarine",
  "purple",
] as const;

export type ThemeColor = (typeof THEME_COLORS)[number];

export const isThemeColor = (color?: string): color is ThemeColor => THEME_COLORS.includes(color as ThemeColor);
export const getThemeColor = (color: string): ThemeColor | undefined => (isThemeColor(color) ? color : undefined);
