export enum HelpCenterRoute {
  ActionItems = "action-items",
  AssignEvidenceOwners = "assign-evidence-owners",
  AutoSetupResponsePlan = "auto-setup-a-response-plan",
  Blueprint = "blueprint",
  BoardReport = "board-report",
  ContinuousCompliance = "continuous-compliance",
  Control = "controls",
  ControlFrameworks = "control-frameworks",
  CreateRiskRecommendation = "create-a-risk-recommendation",
  CustomPlaybooks = "custom-playbooks",
  EnableDisableAutomaticEvidenceGathering = "enable-disable-automatic-evidence-gathering",
  Evidence = "evidence",
  FindingsImporter = "findings-importer",
  GenerateRiskAssessmentReport = "generate-risk-assessment-report",
  GenerateVulnerabilityAssessmentReport = "generate-a-vulnerability-assessment-report",
  ImportScanResults = "import-scan-results",
  IncidentResponseExercises = "incident-response-exercises",
  InformationSystems = "information-systems",
  LossTolerance = "loss-tolerance",
  MarkVulnerabilityAsFalsePositive = "mark-a-vulnerability-as-a-false-positive",
  PerformVendorReview = "perform-a-vendor-review",
  Policies = "policies",
  RiskChanges = "risk-changes",
  ScheduleNewMeeting = "schedule-a-new-meeting",
  ValidatingEvidence = "validating-an-evidence",
  Vendors = "vendors",
}
