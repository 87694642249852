import React, { useEffect, useState } from "react";

import { Auth } from "@aws-amplify/auth";

import { ErrorLogger } from "@utils/EventLogger";

import Loader from "../../../../utils/LoadingComponents/Loader";

const UNAUTHENTICATED_ACCESS_STATES = {
  notInitialized: "notInitialized",
  initialized: "initialized",
  error: "error",
};

/**
 * A higher order component that wraps all UI under the guest route, checks if user is a robot prior to anonymous authentication
 * @param {object} props - the component to wrap
 * @return {JSX.Element|*} - the wrapped UI
 */
const GuestAuthentication = (props) => {
  const [unauthenticatedAccessState, setUnauthenticatedAccessState] = useState(
    UNAUTHENTICATED_ACCESS_STATES.notInitialized,
  );

  //Check that the user can get an identity id token as an unauthenticated uses to access backend resources
  useEffect(() => {
    Auth.currentCredentials()
      .then((data) => {
        if (data?.identityId) {
          setUnauthenticatedAccessState(UNAUTHENTICATED_ACCESS_STATES.initialized);
        } else {
          setUnauthenticatedAccessState(UNAUTHENTICATED_ACCESS_STATES.error);
        }
      })
      .catch((e) => {
        setUnauthenticatedAccessState(UNAUTHENTICATED_ACCESS_STATES.error);
        ErrorLogger("Could not get unauthenticated user credentials", e);
      });
  }, []);

  let content = (
    <div style={{ textAlign: "center" }}>
      <h1>Could not create session!</h1>
      <p style={{ fontSize: "1.5em" }}>Something went wrong, please try again later.</p>
    </div>
  );

  if (unauthenticatedAccessState === UNAUTHENTICATED_ACCESS_STATES.notInitialized) {
    content = (
      <span style={{ fontSize: "2em" }}>
        <Loader style={{ width: "1.3em", height: "1.3em" }} /> Initializing session...
      </span>
    );
  } else if (unauthenticatedAccessState !== UNAUTHENTICATED_ACCESS_STATES.error) {
    return React.cloneElement(props.children, {
      isGuestUser: true,
    });
  }

  return (
    <div
      style={{
        height: "80vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {content}
    </div>
  );
};

export default GuestAuthentication;
