import styles from "./GridLabel.module.scss";

import type { GridLabelComponent } from "@nivo/radar";

type GridLabelComponentProps = Parameters<GridLabelComponent>[0];

export interface GridLabelProps extends GridLabelComponentProps {
  lineHeight?: number;
}

/**
 * Custom grid label component that wraps text
 */
export const GridLabel: GridLabelComponent = ({ id, anchor, x, y, lineHeight = 14 }: GridLabelProps) => {
  // Split the label on spaces to create multiple lines
  const words = String(id).split(" ");

  return (
    <g className={styles["grid-label--no-pointer"]} transform={`translate(${x}, ${y})`}>
      {words.map((word, i) => (
        <text
          key={i}
          className={styles["grid-label"]}
          style={{
            textAnchor: anchor,
          }}
          y={(i - (words.length - 1) / 2) * lineHeight}
        >
          {word}
        </text>
      ))}
    </g>
  );
};
