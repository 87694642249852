import type { PropsWithChildren } from "react";

import cx from "classnames";

import Text, { TextVariant } from "@components/Atoms/Text/Text";
import { TextColor } from "@components/Ions/colors/constants/Color";
import { defaultColors } from "@components/Molecules/RadarChart/RadarChart";

import styles from "./CardFooter.module.scss";
import Legend from "./Legend";

export interface CardFooterProps extends PropsWithChildren {
  keys: string[];
  colors?: string[];
  elideLegend?: boolean;
  slotProps?: {
    container?: React.HTMLAttributes<HTMLDivElement>;
    text?: React.ComponentProps<typeof Text>;
    legend?: Omit<React.ComponentProps<typeof Legend>, "keys" | "colors">;
  };
}

export const CardFooter = ({ keys, colors, children, slotProps }: CardFooterProps) => {
  return (
    <div className={styles["card-footer__container"]} {...slotProps?.container}>
      <Text
        htmlElement="span"
        variant={TextVariant.CAPTION}
        color={TextColor.LIGHT_SECONDARY}
        {...slotProps?.text}
        className={cx(styles["card-footer__text"], slotProps?.text?.className)}
      >
        {children}
      </Text>
      <Legend keys={keys} colors={colors ?? defaultColors} {...slotProps?.legend} />
    </div>
  );
};

export default CardFooter;
