"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createInlineText = void 0;
/**
 * Used to piece together multiple separate parts of text into a single line of text.
 */
const createInlineText = (text, _styleName = undefined, config) => {
    return {
        characterFormat: {
            bidi: false,
            fontSize: config?.fontSize || 11,
            fontFamily: config?.fontFamily, //useful for creating symbols
            underline: config?.underline,
            bold: config?.bold,
            italic: config?.italic,
            fontColor: config?.fontColor || undefined,
        },
        text: `${text}`,
    };
};
exports.createInlineText = createInlineText;
