import { useEffect } from "react";

import { modules, resources } from "@rivial-security/role-utils";

import { useAccordion } from "../../../../hooks/views/useAccordion/useAccordion";
import { useDataGrid } from "../../../../hooks/views/useDataGrid/useDataGrid";
import { processFieldOverrides } from "../../../../utils/Functions/Views/grid/proccessFieldOverrides";
import { GENERIC_FIELD_TYPES } from "../../../../utils/GenericComponents/GenericEditFieldV3/constants/GENERIC_FIELD_TYPES";
import NonStandardControlCostOfControl from "../../../Compliance/EvidenceRiskControlLinks/components/NonStandardControlCostOfControl";
import NonStandardControlImplementationDetails from "../../../Compliance/EvidenceRiskControlLinks/components/NonStandardControlImplementationDetails";
import NonStandardControlImplementationRating from "../../../Compliance/EvidenceRiskControlLinks/components/NonStandardControlImplementationRating";
import NonStandardControlRiskControl from "../../../Compliance/EvidenceRiskControlLinks/components/NonStandardControlRiskControl";
import NonStandardControlSystem from "../../../Compliance/EvidenceRiskControlLinks/components/NonStandardControlSystem";
import NonStandardRiskControlGrid from "../components/NonStandardRiskControlGrid";

/**
 * Displays a custom list of risk control overrides
 * @param {string} organizationID - the organization for the risk control overrides
 * @param {object[]} fieldOverrides - custom grid field definitions to merge with the default definitions
 * @param {object} gridConfig - custom grid settings
 * @param item
 * @return {{gridDisplay: JSX.Element, data: *, lastSelectedItem: string, setData: (value: (((prevState: *) => *) | *)) => void, display: JSX.Element, error: unknown, isLoading: boolean, createResourceComponent: JSX, ref: string, setSelectedItems: (value: (((prevState: []) => []) | [])) => void, setIsLoading: (value: (((prevState: boolean) => boolean) | boolean)) => void, setLastSelectedItem: (value: (((prevState: string) => string) | string)) => void, itemsToCheck: unknown, setError: (value: unknown) => void, resetFunction: function(): void, fields: Object[], setItemsToCheck: (value: unknown) => void, selectedItems: []}}
 */
export const useNonStandardControlsAccordion = ({ organizationID, fieldOverrides, gridConfig, resetFunction }) => {
  const module = modules.RISK;
  const resource = resources.INFORMATION_SYSTEM;
  const route = "#/risk/risk_controls/";
  const typename = "RiskControl";

  //Define grid hook to display systems with non-standard controls info
  const fields = [
    {
      name: "controlName",
      friendlyName: "Control",
      flex: 1,
      minWidth: 300,

      sortComparator: (a, b) => {
        return a?.localeCompare(b, undefined, {
          numeric: true,
          sensitivity: "base",
        });
      },

      sort: {
        direction: "asc",
        priority: 1,
      },
    },
    {
      name: "control",
      visible: false,
      component: <NonStandardControlRiskControl />,
    },
    {
      name: "risk",
      width: 125,
    },
    {
      name: "system",
      component: <NonStandardControlSystem />,
      width: 200, // keep this to allow for horizontal scrollbar to appear when little space is available
      valueFormatter: (value) => {
        return value?.name;
      },
    },
    {
      name: "implementationRating",
      inputType: GENERIC_FIELD_TYPES.PERCENTAGE,
      component: <NonStandardControlImplementationRating />,
      width: 175,
    },
    {
      name: "implementationDetails",
      component: <NonStandardControlImplementationDetails />,
      width: 175,
    },
    {
      name: "costOfControl",
      inputType: GENERIC_FIELD_TYPES.DOLLAR,
      component: <NonStandardControlCostOfControl />,
      width: 125,
    },
  ];

  //Go through all override fields and see if there are any that need to be added to the grid, match by field name
  useEffect(() => {
    processFieldOverrides({ originalFields: fields, fieldOverrides });
  }, [fieldOverrides]);

  gridConfig = {
    resource,
    fields,
    typename,
    route,
    ...gridConfig,
  };

  const roleConfig = {
    module,
    resource,
  };

  const cardConfig = {
    title: "Information Systems with Non-Standard Risk Controls",
    headerIcon: "icon-screen-desktop",
    enableSticky: false,
  };

  const extractUniqueSystems = (data) => {
    const systemSet = new Set(data.map((item) => item?.systemName));
    return Array.from(systemSet);
  };

  const filterBySystem = (arr, systemName) => {
    return arr.filter((item) => item?.systemName === systemName);
  };

  const nonStandardControlSystemsGrid = useDataGrid({
    queryConfig: {
      query: null,
    },
    ...cardConfig,
    ...gridConfig,
    ...roleConfig,
    resetFunction,
  });

  const formatAccordionItems = (data) => {
    const uniqueSystems = extractUniqueSystems(data);
    if (Array.isArray(uniqueSystems)) {
      return uniqueSystems.map((systemName) => {
        const systemData = filterBySystem(data, systemName);
        return {
          title: systemName,
          subTitle:
            systemData.length > 1
              ? `${systemData.length} Linked Non Standard Risk Controls`
              : `${systemData.length} Linked Non Standard Risk Control`,
          items: systemData,
          icon: "icon-screen-desktop",
          component: (
            <NonStandardRiskControlGrid
              sx={{ height: "30em" }}
              organizationID={organizationID}
              data={systemData}
              cardConfig={cardConfig}
              gridConfig={gridConfig}
              roleConfig={roleConfig}
              resetFunction={resetFunction}
            />
          ),
        };
      });
    }
  };
  const accordion = useAccordion({
    items: formatAccordionItems(nonStandardControlSystemsGrid.data),
  });

  return {
    ...nonStandardControlSystemsGrid,
    accordion,
  };
};
