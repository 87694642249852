import { useMutation } from "@apollo/client";

import { CONTROL_FRAMEWORK_DASHBOARDS, UPDATE_CONTROL_FRAMEWORK_DASHBOARD } from "@compliance/api";

import type { FetchResult } from "@apollo/client";
import type {
  UpdateControlFrameworkDashboardMutation,
  UpdateControlFrameworkDashboardMutationVariables,
} from "@graphql/types/graphql";

interface UseUpdateControlFrameworkDashboardResult {
  updateControlFrameworkDashboard: (
    variables: UpdateControlFrameworkDashboardMutationVariables,
  ) => Promise<FetchResult<UpdateControlFrameworkDashboardMutation>>;
  isLoading: boolean;
}

export const useUpdateControlFrameworkDashboard = (): UseUpdateControlFrameworkDashboardResult => {
  const [mutateFunction, { loading }] = useMutation<
    UpdateControlFrameworkDashboardMutation,
    UpdateControlFrameworkDashboardMutationVariables
  >(UPDATE_CONTROL_FRAMEWORK_DASHBOARD, {
    refetchQueries: [CONTROL_FRAMEWORK_DASHBOARDS],
  });

  const updateControlFrameworkDashboard = (
    variables: UpdateControlFrameworkDashboardMutationVariables,
  ): Promise<FetchResult<UpdateControlFrameworkDashboardMutation>> => mutateFunction({ variables });

  return {
    updateControlFrameworkDashboard,
    isLoading: loading,
  };
};
