import React from "react";

import ComplianceHistoryCard from "@compliance/components/ComplianceHistoryCard";

import { modules } from "@rivial-security/role-utils";

import { useDocumentDataGrid } from "../../../../hooks/views/useDocumentGrid/hooks/useDocumentDataGrid";
import { useAuditDataGrid } from "../../../Compliance/Audit/hooks/useAuditDataGrid";
import { useControlDataGrid } from "../../../Compliance/Controls/Controls/hooks/useControlGrid/useControlDataGrid";
import { useControlSetDataGrid } from "../../../Compliance/Controls/ControlSets/hooks/useControlSetDataGrid";
import { useEvidenceDataGrid } from "../../../Compliance/Evidence/hooks/useEvidenceGrid/useEvidenceDataGrid";
import { useExerciseDataGrid } from "../../../Response/Exercises/hooks/useExerciseDataGrid";
import { useIncidentDataGrid } from "../../../Response/Incidents/hooks/useIncidentDataGrid";
import { useRiskChangeDataGrid } from "../../../Risk/RiskChanges/hooks/useRiskChangeDataGrid";
import { useRiskControlDataGrid } from "../../../Risk/RiskConfig/ControlCategories/components/SubControls/hooks/useRiskControlDataGrid";
import { useControlCategoriesDataGrid } from "../../../Risk/RiskConfig/ControlCategories/hooks/useControlCategoriesDataGrid";
import { useKeyRiskIndicatorsDataGrid } from "../../../Risk/RiskConfig/KeyRiskIndicators/hooks/useKeyRiskIndicatorsDataGrid";
import { useThreatDataGrid } from "../../../Risk/RiskConfig/Threats/hooks/useThreatDataGrid";
import { useSystemDataGrid } from "../../../Risk/Systems/hooks/useSystemDataGrid";
import { useAssessmentDataGrid } from "../../../Testing/Assessments/hooks/useAssessmentDataGrid";
import { useTargetsGrid } from "../../../Testing/Targets/hooks/useTargetsGrid";
import { useVulnerabilityDataGrid } from "../../../Testing/Vulnerabilities/hooks/useVulnerabilityDataGrid";
import { useVendorDataGrid } from "../../../Vendor/Vendor/hooks/useVendorDataGrid";
import { useVendorControlCategoryDataGrid } from "../../../Vendor/VendorControlCategories/hooks/useVendorControlCategoryDataGrid";
import { useVendorReviewDataGrid } from "../../../Vendor/VendorSolutionReviews/hooks/useVendorReviewDataGrid";
import { useVendorSolutionDataGrid } from "../../../Vendor/VendorSolutions/hooks/useVendorSolutionDataGrid";
import { useVendorSubControlDataGrid } from "../../../Vendor/VendorSubControls/hooks/useVendorSubControlDataGrid";

const SystemDataGrid = React.lazy(() => import("../../../Risk/Systems/components/SystemDataGrid"));
const RiskChangeDataGrid = React.lazy(() => import("../../../Risk/RiskChanges/components/RiskChangeDataGrid"));
const ThreatDataGrid = React.lazy(() => import("../../../Response/Threats/components/ThreatDataGrid"));
const RiskControlDataGrid = React.lazy(
  () => import("../../../Risk/RiskConfig/ControlCategories/components/SubControls/components/RiskControlDataGrid"),
);
const ControlCategoriesDataGrid = React.lazy(
  () => import("../../../Risk/RiskConfig/ControlCategories/components/ControlCategoriesDataGrid"),
);
const KeyRiskIndicatorsDataGrid = React.lazy(
  () => import("../../../Risk/RiskConfig/KeyRiskIndicators/components/KeyRiskIndicatorsDataGrid"),
);
const FindingsCard = React.lazy(() => import("../../../Program/Findings/components/FindingsCard/FindingsCard"));
const EvidenceDataGrid = React.lazy(() => import("../../../Compliance/Evidence/components/EvidenceDataGrid"));
const ControlSetDataGrid = React.lazy(
  () => import("../../../Compliance/Controls/ControlSets/components/ControlSetDataGrid"),
);
const ControlDataGrid = React.lazy(() => import("../../../Compliance/Controls/Controls/components/ControlDataGrid"));
const AuditDataGrid = React.lazy(() => import("../../../Compliance/Audit/components/AuditDataGrid"));
const DocumentDataGrid = React.lazy(
  () => import("../../../../hooks/views/useDocumentGrid/components/DocumentDataGrid"),
);
const AssessmentGrid = React.lazy(() => import("../../../Testing/Assessments/components/AssessmentDataGrid"));
const VulnerabilityDataGrid = React.lazy(
  () => import("../../../Testing/Vulnerabilities/components/VulnerabilityDataGrid"),
);
const TargetsGrid = React.lazy(() => import("../../../Testing/Targets/components/TargetsGrid"));
const VendorReviewDataGrid = React.lazy(
  () => import("../../../Vendor/VendorSolutionReviews/components/VendorReviewDataGrid"),
);
const VendorDataGrid = React.lazy(() => import("../../../Vendor/Vendor/components/VendorDataGrid"));
const VendorSolutionsDataGrid = React.lazy(
  () => import("../../../Vendor/VendorSolutions/components/VendorSolutionsDataGrid"),
);
const VendorControlCategoryDataGrid = React.lazy(
  () => import("../../../Vendor/VendorControlCategories/components/VendorControlCategoryDataGrid"),
);
const VendorSubControlGrid = React.lazy(
  () => import("../../../Vendor/VendorSubControls/components/VendorSubControlGrid"),
);
const ExerciseGrid = React.lazy(() => import("../../../Response/Exercises/components/ExerciseGrid"));
const IncidentGrid = React.lazy(() => import("../../../Response/Incidents/components/IncidentGrid"));
const ComplianceTotalsChart = React.lazy(() => import("../../../ActionCenter/components/Charts/ComplianceTotalsChart"));
const RiskTotalsStatsCard = React.lazy(() => import("../../../Risk/Dashboard/components/RiskTotalsStatsCard"));
const RiskGaugeCard = React.lazy(() => import("../../WidgetSelector/components/RiskGaugeCard"));
const DashboardCard = React.lazy(
  () => import("../../../../utils/GenericComponents/DashboardCard/components/DashboardCard"),
);
const RiskDashboardTrends = React.lazy(() => import("../../../Risk/Dashboard/components/RiskDashboardTrends"));
const AverageResidualRiskCard = React.lazy(
  () => import("../../../../features/risk/components/AverageResidualRiskCard"),
);
const AverageInherentRiskCard = React.lazy(
  () => import("../../../../features/risk/components/AverageInherentRiskCard"),
);

export const TABLE = {
  // Risk Module
  INFORMATION_SYSTEM: {
    id: "built_in_widget_risk_information_systems",
    icon: "simple-line-icons:screen-desktop",
    name: "Information Systems",
    gridComponent: <SystemDataGrid dashboardDisplay={true} />,
    gridHook: useSystemDataGrid,
    module: modules.RISK,
  },
  RISK_CHANGE: {
    id: "built_in_widget_risk_changes",
    icon: "simple-line-icons:graph",
    name: "Risk Changes",
    gridComponent: <RiskChangeDataGrid />,
    gridHook: useRiskChangeDataGrid,
    module: modules.RISK,
  },
  THREAT: {
    id: "built_in_widget_risk_threats",
    icon: "simple-line-icons:target",
    name: "Threats",
    gridComponent: <ThreatDataGrid />,
    gridHook: useThreatDataGrid,
    module: modules.RISK,
  },
  RISK_CONTROL: {
    id: "built_in_widget_risk_controls",
    icon: "simple-line-icons:list",
    name: "Risk Controls",
    gridComponent: <RiskControlDataGrid showNotes={false} />,
    gridHook: useRiskControlDataGrid,
    module: modules.RISK,
  },
  RISK_CONTROL_CATEGORY: {
    id: "built_in_widget_risk_control_category",
    icon: "simple-line-icons:list",
    name: "Risk Control Categories",
    gridComponent: <ControlCategoriesDataGrid />,
    gridHook: useControlCategoriesDataGrid,
    module: modules.RISK,
  },
  KEY_RISK_INDICATOR: {
    id: "built_in_widget_risk_key_risk_indicators",
    icon: "simple-line-icons:fire",
    name: "Key Risk Indicators",
    gridComponent: <KeyRiskIndicatorsDataGrid />,
    gridHook: useKeyRiskIndicatorsDataGrid,
    module: modules.RISK,
  },
  RISK_FINDINGS_TABLE: {
    id: "built_in_widget_risk_findings_card",
    name: "Risk Findings Card",
    component: () => <FindingsCard module={modules.RISK} />,
    icon: "simple-line-icons:eye",
  },

  // Compliance Module
  EVIDENCE: {
    id: "built_in_widget_compliance-evidence",
    icon: "simple-line-icons:badge",
    name: "Evidence",
    gridComponent: <EvidenceDataGrid />,
    gridHook: useEvidenceDataGrid,
    module: modules.COMPLIANCE,
  },
  CONTROL_FRAMEWORK: {
    id: "built_in_widget_compliance_control_frameworks",
    icon: "simple-line-icons:list",
    name: "Control Frameworks",
    gridComponent: <ControlSetDataGrid />,
    gridHook: useControlSetDataGrid,
    module: modules.COMPLIANCE,
  },
  CONTROL: {
    id: "built_in_widget_compliance_controls",
    icon: "simple-line-icons:list",
    name: "Compliance Controls",
    gridComponent: <ControlDataGrid />,
    gridHook: useControlDataGrid,
    module: modules.COMPLIANCE,
  },
  AUDIT: {
    id: "built_in_widget_compliance_audits",
    icon: "simple-line-icons:film",
    name: "Audits",
    gridComponent: <AuditDataGrid />,
    gridHook: useAuditDataGrid,
    module: modules.COMPLIANCE,
  },
  DOCUMENT: {
    id: "built_in_widget_compliance_documents",
    icon: "simple-line-icons:docs",
    name: "Documents",
    gridComponent: <DocumentDataGrid />,
    gridHook: useDocumentDataGrid,
    module: modules.COMPLIANCE,
  },

  // Testing Module
  ASSESSMENT: {
    id: "built_in_widget_testing_assessments",
    icon: "simple-line-icons:list",
    name: "Assessments",
    gridComponent: <AssessmentGrid />,
    gridHook: useAssessmentDataGrid,
    module: modules.VULNERABILITIES,
  },
  VULNERABILITY: {
    id: "built_in_widget_testing_vulnerabilities",
    icon: "simple-line-icons:fire",
    name: "Vulnerabilities",
    gridComponent: <VulnerabilityDataGrid />,
    gridHook: useVulnerabilityDataGrid,
    module: modules.VULNERABILITIES,
  },
  TARGET: {
    id: "built_in_widget_testing_targets",
    icon: "simple-line-icons:target",
    name: "Targets",
    gridComponent: <TargetsGrid />,
    gridHook: useTargetsGrid,
    module: modules.VULNERABILITIES,
  },

  // Vendors Module
  VENDOR: {
    id: "built_in_widget_vendors_vendors",
    icon: "simple-line-icons:tag",
    name: "Vendors",
    gridComponent: <VendorDataGrid />,
    gridHook: useVendorDataGrid,
    module: modules.VENDORS,
  },
  VENDOR_SOLUTION: {
    id: "built_in_widget_vendors_vendor_solutions",
    icon: "simple-line-icons:cart",
    name: "Vendor Solutions",
    gridComponent: <VendorSolutionsDataGrid />,
    gridHook: useVendorSolutionDataGrid,
  },
  VENDOR_REVIEW: {
    id: "built_in_widget_vendors_vendor_reviews",
    icon: "simple-line-icons:list",
    name: "Vendor Reviews",
    gridComponent: <VendorReviewDataGrid />,
    gridHook: useVendorReviewDataGrid,
  },
  VENDOR_CONTROL_CATEGORY: {
    id: "built_in_widget_vendors_vendor_control_categories",
    icon: "simple-line-icons:list",
    name: "Vendor Control Categories",
    gridComponent: <VendorControlCategoryDataGrid />,
    gridHook: useVendorControlCategoryDataGrid,
  },
  VENDOR_CONTROL: {
    id: "built_in_widget_vendors_vendor_controls",
    icon: "simple-line-icons:list",
    name: "Vendor Controls",
    gridComponent: <VendorSubControlGrid />,
    gridHook: useVendorSubControlDataGrid,
  },

  // Response Module
  EXERCISE: {
    id: "built_in_widget_response_exercises",
    icon: "simple-line-icons:fire",
    name: "Exercises",
    gridComponent: <ExerciseGrid />,
    gridHook: useExerciseDataGrid,
  },
  INCIDENT: {
    id: "built_in_widget_response_incidents",
    icon: "simple-line-icons:fire",
    name: "Incidents",
    gridComponent: <IncidentGrid />,
    gridHook: useIncidentDataGrid,
  },
};

export const PIE_CHART = {
  COMPLIANCE_PIE_CHART: {
    id: "built_in_widget_compliance_pie_chart",
    name: "Compliance Pie Chart",
    component: (
      <DashboardCard style={{ height: "100%" }} title="Compliance Pie Chart" icon="simple-line-icons:pie-chart">
        <ComplianceTotalsChart />
      </DashboardCard>
    ),
    icon: "simple-line-icons:pie-chart",
  },
};

export const BUILT_IN_CARD_WIDGETS = {
  RISK_STAT_TOTALS_CARD: {
    id: "built_in_widget_risk_stat_totals_card",
    name: "Risk Totals Card",
    component: <RiskTotalsStatsCard />,
    icon: "simple-line-icons:chart",
  },
  AVERAGE_INHERENT_RISK_CARD: {
    id: "built_in_widget_inherent_risk_card",
    name: "Average Inherent Risk",
    component: <AverageInherentRiskCard />,
    icon: "simple-line-icons:speedometer",
  },
  AVERAGE_RESIDUAL_RISK_CARD: {
    id: "built_in_widget_residual_risk_card",
    name: "Average Residual Risk",
    component: <AverageResidualRiskCard />,
    icon: "simple-line-icons:speedometer",
  },
};

export const GAUGE = {
  // Risk Module
  INHERENT_RISK_GAUGE_CARD: {
    id: "built_in_widget_inherent_risk_gauge_card",
    name: "Inherent Risk Gauge Card",
    component: <RiskGaugeCard type={"Inherent"} />,
    icon: "simple-line-icons:speedometer",
  },
  RESIDUAL_RISK_GAUGE_CARD: {
    id: "built_in_widget_residual_risk_gauge_card",
    name: "Residual Risk Gauge Card",
    component: <RiskGaugeCard type={"Residual"} />,
    icon: "simple-line-icons:speedometer",
  },
};

export const HISTORY_CHART = {
  // Risk Module
  RISK_TREND_HISTORY_CHART: {
    id: "built_in_widget_risk_trend_history_chart",
    name: "Risk Trend History Chart",
    component: (
      <DashboardCard isUsingAstraTheme title="Risk Trends" style={{ height: "100%" }} icon={"icon-graph"}>
        <RiskDashboardTrends />
      </DashboardCard>
    ),
    icon: "simple-line-icons:chart",
  },
  COMPLIANCE_TREND_HISTORY_CHART: {
    id: "built_in_widget_compliance_trend_history_chart",
    name: "Compliance Trend History Chart",
    component: <ComplianceHistoryCard />,
    icon: "simple-line-icons:chart",
  },
};

export const BUILT_IN_WIDGETS = {
  ...GAUGE,
  ...HISTORY_CHART,
  ...BUILT_IN_CARD_WIDGETS,
  ...TABLE,
  ...PIE_CHART,
};
