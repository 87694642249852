import { USER_CREATABLE_FRAMEWORK_TEMPLATES } from "@compliance/api";
import { createLazyQueryHook } from "@hooks/api/createLazyQueryHook";

import type { OperationVariables } from "@apollo/client";
import type { UserCreateableFrameworkTemplatesQuery } from "@graphql/types/graphql";

export const useUserCreateableFrameworkTemplates = createLazyQueryHook<
  UserCreateableFrameworkTemplatesQuery,
  OperationVariables
>(USER_CREATABLE_FRAMEWORK_TEMPLATES, "Unable to query user creatable framework templates");
