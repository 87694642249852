import { useMutation } from "@apollo/client";

import { CONTROL_FRAMEWORK_DASHBOARDS, CREATE_CONTROL_FRAMEWORK_DASHBOARD } from "@compliance/api";
import { ErrorLogger } from "@utils/EventLogger";

import type { ApolloError, FetchResult } from "@apollo/client";
import type {
  CreateControlFrameworkDashboardMutation,
  CreateControlFrameworkDashboardMutationVariables,
} from "@graphql/types/graphql";

interface UseCreateDashboardResult {
  createControlFrameworkDashboard: (
    variables: CreateControlFrameworkDashboardMutationVariables,
  ) => Promise<FetchResult<CreateControlFrameworkDashboardMutation>>;
  reset: VoidFunction;
  loading: boolean;
  data?: CreateControlFrameworkDashboardMutation | null;
  error?: ApolloError;
  isMutationSuccessful: boolean;
}

export const useCreateControlFrameworkDashboard = (): UseCreateDashboardResult => {
  const [mutateFunction, { data, loading, error, reset }] = useMutation<
    CreateControlFrameworkDashboardMutation,
    CreateControlFrameworkDashboardMutationVariables
  >(CREATE_CONTROL_FRAMEWORK_DASHBOARD, {
    refetchQueries: [CONTROL_FRAMEWORK_DASHBOARDS],
  });

  if (error) {
    ErrorLogger("Unable to create control framework dashboard", error);
  }

  const createControlFrameworkDashboard = (
    variables: CreateControlFrameworkDashboardMutationVariables,
  ): Promise<FetchResult<CreateControlFrameworkDashboardMutation>> => mutateFunction({ variables });

  return {
    createControlFrameworkDashboard,
    reset,
    loading,
    data,
    error,
    isMutationSuccessful: !!data?.createControlFrameworkDashboard,
  };
};
