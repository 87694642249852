import { isNullOrUndefined } from "@rivial-security/func-utils";
import { generateGraphql } from "@rivial-security/generategraphql";

import GenericEditFieldV3 from "../../../../utils/GenericComponents/GenericEditFieldV3/GenericEditFieldV3";
import { CheckEmailRegex } from "../../../../utils/Regex/Regex";
/**
 * Display Point of Contact email field
 * @param {object} item - point of contact object for which to display the email
 * @param {string} module - the module to use for role checking
 * @param {string} resource - the resource to use for role checking
 * @param {function} updateItemById - callback for when the item is updated through this UI
 * @param {boolean} disableEdits - if TRUE will not allow to change the value of this field
 * @returns {JSX.Element}
 */
export const CustomPointOfContactEmailField = ({
  item,
  module,
  resource,
  updateItemById,
  disableEdits: disableEditsInit = false,
}) => {
  const { updateMutation } = generateGraphql("PointOfContact", ["email"]);
  const disableEdits = isNullOrUndefined(disableEditsInit) ? item && item.user : disableEditsInit;
  return (
    <GenericEditFieldV3
      item={item}
      module={module}
      resource={resource}
      field="email"
      forceLowercase={true}
      mutation={updateMutation}
      disableEdits={disableEdits}
      updateItemById={updateItemById}
      inputConfig={{
        isValidInput: (input) => {
          return CheckEmailRegex(input);
        },
        validInputMessage: "Email is valid",
        invalidInputMessage: "Invalid email format",
      }}
    />
  );
};
