import React from "react";

import { modules, resources } from "@rivial-security/role-utils";

import { useAutomationsGrid } from "../../OrganizationManager/Automations/hooks/useAutomationsGrid";

import AutomationTemplateDetails from "./AutomationTemplateDetails";
import AutomationTemplateForm from "./AutomationTemplateForm";

/**
 * Automation Templates Grid
 * @param {string} organizationID - the ownerGroup to use for creating/querying templates
 * @param {string} module - the module to use for role checking
 * @param {string} resource - the resource to use for role checking
 * @param {boolean} disableRoleChecking - if TRUE will disable role checking
 * @param {object} ...props - any other props to pass to the grid
 * @returns {JSX.Element}
 */
const AutomationTemplateGrid = ({
  organizationID,
  module = modules.ORGANIZATION_MANAGER,
  resource = resources.AUTOMATION,
  disableRoleChecking = false,
  queryConfig = {},
  gridConfig = {},
  cardConfig = {},
  additionalFields = [],
  ...props
}) => {
  const visibleFields = ["name", "description"];
  if (Array.isArray(additionalFields)) {
    additionalFields.forEach((field) => {
      if (field?.name) {
        visibleFields.push(field.name);
      }
    });
  }

  const grid = useAutomationsGrid({
    organizationID,
    persistenceUUID: "b9f903a6-3762-4bcd-bde4-123445h412",
    detailsTitle: "Automation Template Details",
    detailsComponent: <AutomationTemplateDetails organizationID={organizationID} module={module} isTemplate={true} />,
    createResourceComponent: <AutomationTemplateForm organizationID={organizationID} module={module} />,
    createItemModalHeader: "Create an Automation Template",
    route: null,
    resource,
    module,
    disableRoleChecking,
    isTemplate: true,
    visibleFields,
    additionalFields,
    ...queryConfig,
    ...gridConfig,
    ...cardConfig,
    ...props,
  });

  return <div style={{ height: "70vh" }}>{grid.display}</div>;
};

export default AutomationTemplateGrid;
