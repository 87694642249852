import { useMemo } from "react";
import type { ComponentProps, ReactElement } from "react";

import { ResponsiveLine } from "@nivo/line";

import { ChartTooltip } from "@components/Molecules/Card/LineChartCard/ChartTooltip";

import type { AxisProps } from "@nivo/axes";
import type { LineSvgProps } from "@nivo/line";

export interface LineChartProps extends ComponentProps<typeof ResponsiveLine> {
  /** Width of the chart */
  width?: string | number;
  /** Height of the chart */
  height?: string | number;
}

export const defaultColors = [
  `var(--color-primary-main)`,
  `var(--color-primary-light1)`,
  `var(--color-secondary-main)`,
  `var(--theme-color-pink-06)`,
  `var(--theme-color-lime-06)`,
  `var(--theme-color-ultramarine-06)`,
  `var(--theme-color-red-06)`,
  `var(--theme-color-green-06)`,
  `var(--theme-color-purple-06)`,
  `var(--theme-color-orange-06)`,
  `var(--theme-color-teal-06)`,
  `var(--theme-color-yellow-06)`,
  `var(--theme-color-blue-06)`,
  `var(--theme-color-pink-04)`,
  `var(--theme-color-lime-04)`,
  `var(--theme-color-ultramarine-04)`,
  `var(--theme-color-red-04)`,
  `var(--theme-color-green-04)`,
  `var(--theme-color-purple-04)`,
  `var(--theme-color-orange-04)`,
  `var(--theme-color-teal-04)`,
  `var(--theme-color-yellow-04)`,
  `var(--theme-color-blue-04)`,
];

export const defaultAxisLeft: AxisProps = {
  legendOffset: -50,
  legendPosition: "middle",
  tickSize: 0,
};

export const defaultAxisBottom: AxisProps = {
  legendPosition: "middle",
  legendOffset: 0,
  tickSize: 5,
  tickPadding: 5,
};

/**
 * Shows a nivo line chart for given series data. Line chart represent variation in y over x.
 */
const LineChart = ({
  theme,
  axisLeft,
  axisBottom,
  width = "100px",
  height = "100px",
  ...rest
}: LineChartProps): ReactElement => {
  const defaultTheme = useMemo<LineSvgProps["theme"]>(
    () =>
      ({
        fontFamily: "var(--font-family)",
        fontSize: 13,
        textColor: "var(--color-text-on-light-secondary)",
        crosshair: {
          line: {
            strokeWidth: 2,
            stroke: "var(--color-bg-gray5)",
          },
        },
        annotations: {
          text: {
            fontFamily: "var(--font-family)",
            fontSize: "var(--font-caption)",
            fontWeight: "var(--font-weight-regular)",
            lineHeight: "var(--line-height-caption)",
          },
        },
        grid: {
          line: {
            stroke: "var(--color-bg-gray3)",
            strokeWidth: 1,
          },
        },
        axis: {
          legend: {
            text: {
              fill: "var(--color-text-on-light-primary)",
              fontWeight: "var(--font-weight-regular)",
              fontSize: "var(--font-body3)",
              fontFamily: "var(--font-family)",
            },
          },
          ticks: {
            text: {
              fill: "var(--color-text-on-light-secondary)",
              fontWeight: "var(--font-weight-regular)",
              fontSize: "var(--font-caption)",
              fontFamily: "var(--font-family)",
            },
          },
        },
        labels: {
          text: {
            fontFamily: "var(--font-family)",
            fontSize: "var(--font-caption)",
            fill: "var(--color-text-on-light-secondary)",
          },
        },
        ...theme,
      }) satisfies LineSvgProps["theme"],
    [theme],
  );

  return (
    <div style={{ width, height }}>
      <ResponsiveLine
        animate={false}
        enablePoints={false}
        margin={{ top: 10, right: 20, bottom: 70, left: 60 }}
        tooltip={ChartTooltip}
        colors={defaultColors}
        useMesh={true}
        enableGridX={false}
        theme={defaultTheme}
        axisLeft={{
          ...defaultAxisLeft,
          ...axisLeft,
        }}
        axisBottom={{
          ...defaultAxisBottom,
          ...axisBottom,
        }}
        {...rest}
      />
    </div>
  );
};

export default LineChart;
