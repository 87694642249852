import React from "react";

import Tooltip from "@mui/material/Tooltip";
import { useHistory } from "react-router-dom";
/**
 * Displays an icon and tooltip explaining that this point of contact has a currently pending evidence
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const InProgressStanding = ({ pointOfContact = {}, actionItem = {} }) => {
  const history = useHistory();

  return (
    <Tooltip
      arrow
      interactive
      title={
        <div style={{ fontSize: "1.2em", textAlign: "center" }}>
          {pointOfContact?.firstName} is currently gathering evidence
          <hr style={{ color: "white" }} />
          <span
            style={{
              borderBottom: "2px dashed",
              textDecoration: "none",
              cursor: "pointer",
              marginTop: "1.0em",
            }}
            onClick={() => history.push(`/continuous_compliance/evidence_response/${actionItem.id}`)}
          >
            Go to landing page
          </span>
        </div>
      }
    >
      <i className="icon-clock" />
    </Tooltip>
  );
};

export default InProgressStanding;
