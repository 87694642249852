import { withOrganizationCheck } from "../../../../utils/Context/withOrganizationCheck";
import { useVendorDataGrid } from "../hooks/useVendorDataGrid";

/**
 * Component instance that displays all Vendors for a specific organization
 * @param props
 */
const VendorDataGrid = (props) => {
  const result = useVendorDataGrid({ dashboardDisplay: true, ...props }).display;

  return result;
};

export default withOrganizationCheck(VendorDataGrid);
