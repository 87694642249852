import type { ComponentPropsWithoutRef, ReactElement, ReactNode } from "react";

import cx from "classnames";

import Text, { TextVariant } from "../Text/Text";

import styles from "./ListItem.module.scss";

export enum ListItemVariant {
  DEFAULT = "default",
  ALERT = "alert",
}

export interface ListItemProps extends ComponentPropsWithoutRef<"button"> {
  /** The variant of the list item, affects the color of the text (example: `default`, `alert`). */
  variant?: ListItemVariant;
  /** A boolean flag to indicate if the `ListItem` is selected. */
  selected?: boolean;
  /** Icon to display on the left side of the text content within the `ListItem`. */
  icon?: ReactElement;
  /** The text content of the `ListItem`. */
  children: ReactNode;
}

/** An individual clickable item in a list.  Useful for an expandable menu or a select dropdown.  Should be used as a child within `List` only. */
const ListItem = ({
  variant = ListItemVariant.DEFAULT,
  children,
  selected = false,
  className,
  icon,
  ...rest
}: ListItemProps): ReactElement => {
  const listItemStyles = cx(
    styles["list-item"],
    {
      [styles[`list-item--alert`]!]: variant === ListItemVariant.ALERT,
      [styles[`list-item--selected`]!]: selected,
    },
    className,
  );

  return (
    <li className={listItemStyles}>
      <button type="button" className={styles[`list-item__button`]} {...rest}>
        {icon && <span className={styles["list-item__icon"]}>{icon}</span>}
        <Text className={styles["list-item__label"]} variant={TextVariant.BODY3} htmlElement="span">
          {children}
        </Text>
      </button>
    </li>
  );
};

const ListItemSeparator = (): ReactElement => (
  <li aria-hidden="true" className={cx(styles["list-item__separator"])}>
    <div role="separator" />
  </li>
);

ListItem.Separator = ListItemSeparator;

export default ListItem;
