export enum TextColor {
  DARK_PRIMARY = "text-on-dark-primary",
  DARK_SECONDARY = "text-on-dark-secondary",
  LIGHT_PRIMARY = "text-on-light-primary",
  LIGHT_SECONDARY = "text-on-light-secondary",
  DISABLED_ITEM = "text-on-disabled-item",
  LINK = "text-on-link",
  SUCCESS = "text-on-success",
  INFO = "text-on-info",
  WARNING = "text-on-warning",
  ERROR = "text-on-error",
}

export enum ThemeColor {
  PRIMARY_MAIN = "primary-main",
  PRIMARY_DARK1 = "primary-dark1",
  PRIMARY_LIGHT1 = "primary-light1",
  PRIMARY_LIGHT2 = "primary-light2",
  SECONDARY_MAIN = "secondary-main",
  DENOTIVE_SUCCESS_MAIN = "denotive-success-main",
  DENOTIVE_SUCCESS_LIGHT = "denotive-success-light",
  DENOTIVE_INFO_MAIN = "denotive-info-main",
  DENOTIVE_INFO_LIGHT = "denotive-info-light",
  DENOTIVE_WARNING_MAIN = "denotive-warning-main",
  DENOTIVE_WARNING_LIGHT = "denotive-warning-light",
  DENOTIVE_ERROR_MAIN = "denotive-error-main",
  DENOTIVE_ERROR_LIGHT = "denotive-error-light",
  BG_GRAY0 = "bg-gray0",
  BG_GRAY1 = "bg-gray1",
  BG_GRAY2 = "bg-gray2",
  BG_GRAY3 = "bg-gray3",
  BG_GRAY4 = "bg-gray4",
  BG_GRAY5 = "bg-gray5",
  BG_GRAY6 = "bg-gray6",
  BG_TRANSPARENT_DARK1 = "bg-transparent-dark1",
  BG_BACKDROP = "bg-backdrop",
}
