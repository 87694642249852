import { NetworkStatus } from "@apollo/client";

import { ErrorLogger } from "@utils/EventLogger";

// Shared helper function to check for nextToken in nested objects
export const hasNextToken = (obj: unknown): boolean => {
  if (!obj || typeof obj !== "object") return false;

  return (
    ("nextToken" in obj && obj.nextToken !== null) ||
    Object.values(obj).some((value) => typeof value === "object" && value !== null && hasNextToken(value))
  );
};

// Shared loading state logic
export const getIsLoading = (loading: boolean, networkStatus: NetworkStatus, data: unknown): boolean => {
  return (
    loading ||
    (networkStatus === NetworkStatus.fetchMore && hasNextToken(data)) ||
    networkStatus === NetworkStatus.refetch
  );
};

// Shared error handling
export const handleError = (error: Error | undefined, errorMessage: string): void => {
  if (error) {
    ErrorLogger(errorMessage, error);
  }
};
