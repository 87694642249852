import React from "react";

import { NavigationSectionName } from "@application/Navigation/types";
import { IconName } from "@components/Ions/icons/components/Icons";
import { modules } from "role-utils";

import type { NavigationModule } from "@application/Navigation/types";

const TaskManagementUI = React.lazy(
  () => import("@views/Program/Tasks/components/TasksUI/components/TaskManagementUI"),
);
const VCISOMaturityMatrix = React.lazy(
  () => import("@views/Program/VCISOMaturityMatrix/components/MaturityMatrix/components/DisplayOneMaturityMatrix"),
);
const VCISOMaturityMatrixDetails = React.lazy(
  () => import("@views/Program/VCISOMaturityMatrix/components/MaturityMatrix/components/BlueprintDetails"),
);
const VCISOMaturityMatrixImplementationLevelDetails = React.lazy(
  () =>
    import(
      "@views/Program/VCISOMaturityMatrix/components/ImplementationLevel/components/ImplementationLevelDetailsCard"
    ),
);
const MeetingDashboard = React.lazy(() => import("@views/Program/Meetings/components/MeetingsDataGrid"));
const MeetingDetails = React.lazy(() => import("@views/Program/Meetings/components/MeetingDetails"));
const ProgramReport = React.lazy(() => import("@views/Reports/ProgramReport/components/ProgramReport"));
const BlueprintReport = React.lazy(() => import("@views/Reports/BlueprintReport/components/BlueprintReport"));
const PoliciesList = React.lazy(() => import("@views/Program/Policies/components/PolicyDataGrid"));
const PolicyDetails = React.lazy(() => import("@views/Program/Policies/components/PolicyDetails"));
const ReviewPolicy = React.lazy(() => import("@views/Program/Policies/components/ReviewPolicy"));
const FindingsDashboard = React.lazy(
  () => import("@views/Program/Findings/components/FindingsDashboard/FindingsDashboard"),
);
const ActionItemGrid = React.lazy(() => import("@views/Program/Actions/components/ActionDataGrid"));
const ActionItemDetails = React.lazy(() => import("@views/Program/Actions/components/ActionItemDetails"));
const ObservationDetails = React.lazy(() => import("@views/Program/Observations/components/ObservationDetails"));
const ObservationDataGrid = React.lazy(() => import("@views/Program/Observations/components/ObservationsDataGrid"));
const RecommendationDetails = React.lazy(
  () => import("@views/Program/Recommendations/components/RecommendationDetails"),
);
const RecommendationGrid = React.lazy(
  () => import("@views/Program/Recommendations/components/RecommendationsDataGrid"),
);
const SourceDataGrid = React.lazy(() => import("@views/Program/Sources/components/SourceDataGrid"));
const SourceDetails = React.lazy(() => import("@views/Program/Sources/components/SourceDetails"));
const TaskDetails = React.lazy(() => import("@views/Program/Tasks/components/TaskDetails"));
const FindingsImporter = React.lazy(() => import("@views/Program/FindingsImporter/components/FindingsImporter"));
const AIPolicyGeneration = React.lazy(() => import("@views/AI/AIPolicyGeneration/AIPolicyGeneration"));

export const governanceNavigation: NavigationModule = {
  moduleName: modules.GOVERNANCE,
  sectionName: NavigationSectionName.SERVICES,
  name: "Governance",
  path: "/governance",
  deprecatedPaths: ["/program"],
  defaultRoutePath: "/blueprints",
  icon: IconName.GOVERNANCE,
  routes: [
    {
      path: "/blueprints",
      deprecatedPaths: ["/matrix"],
      name: "Blueprints",
      component: VCISOMaturityMatrix,
    },
    {
      path: "/blueprints/:id",
      deprecatedPaths: ["/matrix/:id"],
      name: "Program Blueprint Details",
      component: VCISOMaturityMatrixDetails,
    },
    {
      path: "/findings_dashboard",
      name: "Findings and Observations",
      component: FindingsDashboard,
      navigationBar: {
        name: "Findings",
      },
    },
    {
      path: "/policies",
      name: "Policies",
      component: PoliciesList,
    },
    {
      path: "/policies/ai_policy_generation",
      name: "AI Policy Generation",
      component: AIPolicyGeneration,
      navigationBar: {
        hidden: true,
      },
    },
    {
      path: "/policies/:id",
      name: "Policy Details",
      component: PolicyDetails,
    },
    {
      path: "/implementationLevels/:id",
      deprecatedPaths: ["/implementationLevels/:id"],
      name: "Blueprint Implementation Level Details",
      component: VCISOMaturityMatrixImplementationLevelDetails,
    },
    {
      path: "/meetings",
      name: "Meetings",
      component: MeetingDashboard,
    },
    {
      path: "/meetings/:id",
      name: "Meeting Details",
      component: MeetingDetails,
    },
    {
      path: "/executive_summary_report",
      deprecatedPaths: ["/program_report"],
      name: "Board Report",
      component: ProgramReport,
      navigationBar: {
        name: "Executive Summary",
      },
    },
    {
      path: "/blueprint_reports",
      name: "Blueprint Report",
      component: BlueprintReport,
    },

    {
      path: "/review_policy/:id",
      name: "Acknowledge Security Policy",
      component: ReviewPolicy,
    },
    {
      path: "/action_items",
      name: "Action Items",
      component: ActionItemGrid,
      navigationBar: {
        hidden: true,
      },
    },
    {
      path: "/action_items/:id",
      name: "Action Item Details",
      component: ActionItemDetails,
    },
    {
      path: "/observations",
      name: "Observations",
      component: ObservationDataGrid,
      navigationBar: {
        hidden: true,
      },
    },
    {
      path: "/observations/:id",
      name: "Observation Details",
      component: ObservationDetails,
    },
    {
      path: "/recommendations",
      name: "Recommendations",
      component: RecommendationGrid,
      navigationBar: {
        hidden: true,
      },
    },
    {
      path: "/recommendations/:id",
      name: "Recommendation Details",
      component: RecommendationDetails,
    },
    {
      path: "/sources",
      name: "Sources",
      component: SourceDataGrid,
      navigationBar: {
        hidden: true,
      },
    },
    {
      path: "/sources/:id",
      name: "Source Details",
      component: SourceDetails,
    },
    {
      path: "/tasks",
      name: "Tasks",
      component: TaskManagementUI,
      navigationBar: {
        hidden: true,
      },
    },
    {
      path: "/tasks/:id",
      name: "Task Details",
      component: TaskDetails,
    },

    {
      path: "/import_findings",
      name: "Import Findings",
      component: FindingsImporter,
      navigationBar: {
        hidden: true,
      },
    },
  ],
};
