import "../styles/UploadDocument.css";

import React, { useContext, useState } from "react";

import { Auth } from "@aws-amplify/auth";
import * as Sentry from "@sentry/react";
import { v4 as uuid } from "uuid";

import { isNullOrUndefined } from "@rivial-security/func-utils";
import { generateGraphql } from "@rivial-security/generategraphql";

import { S3_BUCKET_REGION } from "../../../../env-config";
import { useMutation } from "../../../../hooks/graphql/useMutation/useMutation";
import { useQueryGetItem } from "../../../../hooks/graphql/useQueryGetItem";
import { usePleaseWaitModal } from "../../../../hooks/views/usePleaseWaitModal";
import { UIContext } from "../../../../utils/Context/UIContext";
import { generateS3ObjectKey } from "../../../../utils/Functions/S3Storage/generateS3ObjectKey";
import { onS3UserErrorMessage } from "../../../../utils/Functions/S3Storage/onS3UserErrorMessage";
import { s3MultiPartUpload } from "../../../../utils/Functions/S3Storage/s3MultiPartUpload";
import { getOrganization_minimal } from "../../../AdminPanel/Organizations/graphql/__organizationGQL";

import SelectDocuments from "./SelectDocuments";

/**
 * @typedef UploadDocumentProps
 * @property {function} onSubmit - function to run after document is uploaded
 * @property {function} resetFunction - function to run after document is uploaded
 * @property {function} onSelected - function to run after document is selected from the native window
 * @property {string} organizationID - the organization that the document is a part of
 */

/**
 * Allows user to upload a Document corresponding to an Organization
 * @param {UploadDocumentProps} props
 * @returns {ReactElement}
 */
const UploadDocument = ({ onSubmit, resetFunction, onSelected, organizationID }) => {
  const [, setLastUpdate] = useState(new Date().toISOString());
  const [, setOwner] = useState("");

  const { addToast, updateToast } = useContext(UIContext);
  const pleaseWaitModal = usePleaseWaitModal({
    progressTotal: 100,
  });

  const { createMutation: createDocumentMutation } = generateGraphql(
    "Document",
    ["name", "owner", "visibility", "createdAt", "file"],
    {
      file: "{ bucket region key }",
    },
  );

  const createDocumentHook = useMutation({
    mutation: createDocumentMutation,
    disableRoleChecking: true,
    typename: "Document",
    disableToast: true,
  });

  const getInitialState = () => {
    setLastUpdate(new Date().toISOString());
    setOwner("");
  };

  const getOrganizationQueryHook = useQueryGetItem({
    query: getOrganization_minimal,
    itemId: organizationID,
    disableRoleChecking: true,
  });

  const handleSubmit = async ({ files } = {}) => {
    const toastId = addToast({
      header: `Uploading Document, please wait...`,
      icon: "spinner",
      color: "success",
    });

    pleaseWaitModal.setModalIsOpen(true);

    const onprogress = (loaded) => {
      if (loaded === 100) {
        updateToast({
          id: toastId,
          header: "Document was successfully uploaded",
          icon: "success",
        });
        pleaseWaitModal.setModalIsOpen(false);
      }
      pleaseWaitModal?.setProgress(loaded);
    };

    const onUploadError = (err) => {
      const errorMessage = onS3UserErrorMessage({ error: err });

      updateToast({
        id: toastId,
        header: errorMessage,
        icon: "danger",
      });

      pleaseWaitModal.setModalIsOpen(false);
    };

    if (!files.length > 0) return await onUploadError(new Error("Suspicious file upload"));

    const region = S3_BUCKET_REGION;
    const organization = getOrganizationQueryHook?.item;
    const bucketName = organization?.s3BucketName;

    const { username: owner } = await Auth.currentUserInfo();
    const docs = [];

    const fileUploadThreads = files.map(async (f) => {
      const file = f?.rawFile;
      let fileName = file?.name;
      if (!fileName) {
        fileName = `no-name-${uuid()}`;
      }
      const objectKey = generateS3ObjectKey(`evidence/${fileName}`);

      const fileInfo = {
        bucket: bucketName,
        region: region,
        key: objectKey,
      };

      try {
        await s3MultiPartUpload({
          file,
          bucket: bucketName,
          key: objectKey,
          organizationID,
          uploadProgress: onprogress,
        });
      } catch (e) {
        onUploadError(e);
        Sentry.captureMessage(`Failed to upload document: ${document?.name}. Error: ${e?.message}`);
        return;
      }

      const document = await createDocumentHook.createItem({
        name: fileName,
        owner: owner,
        documentOrganizationId: organizationID,
        file: fileInfo,
        ownerGroup: organizationID,
      });

      if (!isNullOrUndefined(document)) docs.push(document);
    }); // End forEach loop.

    await Promise.all(fileUploadThreads);
    pleaseWaitModal.setModalIsOpen(false);
    getInitialState();
    onSubmit?.(docs);
    resetFunction?.();
  }; // End "handleSubmit" function.

  return (
    <div className="animated fadeIn">
      {pleaseWaitModal.modal}
      <SelectDocuments
        onSubmit={handleSubmit}
        onSelected={onSelected}
        buttons={{
          browse: "Choose Files",
          clear: "Clear All",
          upload: "Upload",
        }}
      />
    </div>
  );
};

export default UploadDocument;
