export const getRiskControlSystems = /* GraphQL */ `
  fragment ContactInfo on PointOfContact {
    id
    ownerGroup
    firstName
    lastName
    title
    email
  }

  fragment SystemDetails on System {
    id
    name
    description
    pointOfContact {
      ...ContactInfo
    }
    hosting
    lastReviewed
    adminPointOfContact {
      ...ContactInfo
    }
    tags(limit: 100) {
      items {
        __typename
        id
        tag {
          id
          name
          description
          fontColor
          backgroundColor
        }
      }
    }
    ownerGroup
    riskControlOverrides {
      enabledFields
      riskControlId
      costOfControl
      strengthRating
      implementationRating
      implementationDetails
    }
    inherentRisk
    residualRisk
    riskRating
    greatestEnterpriseRisk
    greatestBusinessRisk
    greatestKeyRiskIndicator
  }

  query GetRiskControl($id: ID!) {
    getRiskControl(id: $id) {
      id
      name
      statementNumber
      costOfControl
      implementationRating
      strengthRating
      controlCategory {
        id
        systems(limit: 250) {
          items {
            id
            system {
              ...SystemDetails
            }
          }
        }
      }
      evidences(limit: 500) {
        nextToken
        items {
          id
          evidence {
            id
            name
            enabled
            status
          }
          system {
            ...SystemDetails
          }
        }
      }
    }
  }
`;
