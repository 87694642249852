import React from "react";

import { modules, resources } from "@rivial-security/role-utils";

import { useDepartmentGrid } from "../../OrganizationManager/Departments/hooks/useDepartmentGrid";

import DepartmentTemplateDetails from "./DepartmentTemplateDetails";
import DepartmentTemplateForm from "./DepartmentTemplateForm";

/**
 * Department Templates Grid
 * @param {string} organizationID - the ownerGroup to use for creating/querying templates
 * @param {string} module - the module to use for role checking
 * @param {string} resource - the resource to use for role checking
 * @param {boolean} disableRoleChecking - if TRUE will disable role checking
 * @param {object} ...props - any other props to pass to the grid
 * @returns {JSX.Element}
 */
const DepartmentTemplateGrid = ({
  organizationID,
  module = modules.ORGANIZATION_MANAGER,
  resource = resources.DEPARTMENT,
  disableRoleChecking = false,
  queryConfig = {},
  gridConfig = {},
  cardConfig = {},
  additionalFields = [],
  ...props
}) => {
  const visibleFields = ["name", "description"];
  if (Array.isArray(additionalFields)) {
    additionalFields.forEach((field) => {
      if (field?.name) {
        visibleFields.push(field.name);
      }
    });
  }

  const grid = useDepartmentGrid({
    organizationID,
    persistenceUUID: "b9f903a6-3762-4bcd-bde4-123998877",
    detailsTitle: "Department Template Details",
    detailsComponent: <DepartmentTemplateDetails organizationID={organizationID} module={module} isTemplate={true} />,
    createResourceComponent: <DepartmentTemplateForm organizationID={organizationID} module={module} />,
    createItemModalHeader: "Create an Department Template",
    route: null,
    resource,
    module,
    disableRoleChecking,
    isTemplate: true,
    visibleFields,
    additionalFields,
    ...queryConfig,
    ...gridConfig,
    ...cardConfig,
    ...props,
  });

  return <div style={{ height: "70vh" }}>{grid.display}</div>;
};

export default DepartmentTemplateGrid;
