import { modules, resources } from "@rivial-security/role-utils";

export const ICON_MAP = {
  MISC: {
    CREATE: "simple-line-icons:plus",
    EDIT: "simple-line-icons:pencil",
    DELETE: "simple-line-icons:trash",
    DUPLICATE: "akar-icons:copy",
    DASHBOARD: "simple-line-icons:grid",
    ADD_REMOVE: "",
    SEARCH: "akar-icons:search",
    FILTER: "bi:filter",
  },

  MODULES: {
    [modules.ACTION_CENTER]: "icon-rocket",
    [modules.GOVERNANCE]: "icon-user-female",
    [modules.RISK]: "icon-energy",
    [modules.COMPLIANCE]: "icon-shield",
    [modules.VULNERABILITIES]: "",
    [modules.VENDORS]: "",
    [modules.INCIDENT_RESPONSE]: "",
    [modules.TOOLS]: "",
    [modules.ORGANIZATION_MANAGER]: "",
    [modules.REPORTS]: "",
    [modules.METRICS]: "",
    [modules.ADMINISTRATOR]: "",
  },

  RESOURCES: {
    [resources.DEPARTMENT]: "heroicons:building-office-2",
    [resources.EVIDENCE]: "",
    [resources.CONTROL]: "",
    [resources.CONTROL_FRAMEWORK]: "",
    [resources.AUDIT]: "",
    [resources.POINT_OF_CONTACT]: "",
    [resources.METRIC]: "",
    [resources.KEY_PERFORMANCE_INDICATOR]: "",
    [resources.OBSERVATION]: "",
    [resources.RECOMMENDATION]: "",
    [resources.ACTION_ITEM]: "",
    [resources.ORGANIZATION]: "",
    [resources.POLICY]: "",
    [resources.REPORT]: "",
    [resources.BLUEPRINT]: "",
    [resources.PROGRAM_ELEMENT]: "",
    [resources.PROGRAM_SECTION]: "",
    [resources.MEETING]: "",
    [resources.INFORMATION_SYSTEM]: "",
    [resources.RISK_ASSESSMENT]: "",
    [resources.DOCUMENT]: "",
    [resources.VULNERABILITY]: "",
    [resources.TARGET]: "",
    [resources.ASSESSMENT]: "",
    [resources.VENDOR]: "",
    [resources.VENDOR_SOLUTION]: "",
    [resources.VENDOR_REVIEW]: "",
    [resources.VENDOR_CONTROL]: "",
    [resources.VENDOR_CONTROL_CATEGORY]: "",
    [resources.VENDOR_CERTIFICATION]: "",
    [resources.ROLE]: "",
  },
};
