import React, { useEffect, useState } from "react";

import { Checkbox, FormControlLabel } from "@mui/material";

import { convertCamelCaseToSentence, isNullOrUndefined, updateObjectInArray } from "@rivial-security/func-utils";

import { useStateSync } from "../../../../hooks/functional/useStateSync";
import { useForm } from "../../../../hooks/views/useForm";
import { withOrganizationCheck } from "../../../../utils/Context/withOrganizationCheck";
import { tryFunction } from "../../../../utils/Functions/tryFunction";
import DataLoader from "../../../../utils/LoadingComponents/DataLoader";
import ControlDataGrid from "../../../Compliance/Controls/Controls/components/ControlDataGrid";
import CreateControl from "../../../Compliance/Controls/Controls/components/CreateControl";
import CreateEvidence from "../../../Compliance/Evidence/components/CreateEvidence";
import EvidenceDataGrid from "../../../Compliance/Evidence/components/EvidenceDataGrid";
import CustomResourceEntryDataGrid from "../../../CustomResources/CustomResourceEntries/components/CustomResourceEntryDataGrid";
import CustomResourceEntryForm from "../../../CustomResources/CustomResourceEntries/components/CustomResourceEntryForm";
import MetricForm from "../../../Metrics/MetricForm/components/MetricForm";
import ScheduleMeeting from "../../../Program/Meetings/components/MeetingForm";
import MeetingsDataGrid from "../../../Program/Meetings/components/MeetingsDataGrid";
import CreateObservation from "../../../Program/Observations/components/CreateObservation";
import ObservationsGrid from "../../../Program/Observations/components/ObservationsGrid";
import CreateTask from "../../../Program/Tasks/components/CreateTask";
import TaskDataGrid from "../../../Program/Tasks/components/TaskDataGrid";
import CreateExercise from "../../../Response/Exercises/components/CreateExercise";
import ExerciseGrid from "../../../Response/Exercises/components/ExerciseGrid";
import CreateIncident from "../../../Response/Incidents/components/CreateIncident";
import IncidentGrid from "../../../Response/Incidents/components/IncidentGrid";
import CreateChange from "../../../Risk/RiskChanges/components/CreateChange";
import RiskChangeDataGrid from "../../../Risk/RiskChanges/components/RiskChangeDataGrid";
import CreateControlCategorySubControl from "../../../Risk/RiskConfig/ControlCategories/components/SubControls/components/CreateControlCategorySubControl";
import RiskControlDataGrid from "../../../Risk/RiskConfig/ControlCategories/components/SubControls/components/RiskControlDataGrid";
import CreateSystem from "../../../Risk/Systems/components/CreateSystem";
import SystemDataGrid from "../../../Risk/Systems/components/SystemDataGrid";
import CreateTarget from "../../../Testing/Targets/components/CreateTarget";
import TargetsGrid from "../../../Testing/Targets/components/TargetsGrid";
import CreateVendor from "../../../Vendor/Vendor/components/CreateVendor";
import VendorDataGrid from "../../../Vendor/Vendor/components/VendorDataGrid";
import CreateVendorSolution from "../../../Vendor/VendorSolutions/components/VendorSolutionForm";
import VendorSolutionsDataGrid from "../../../Vendor/VendorSolutions/components/VendorSolutionsDataGrid";

export const MUTATE_RESOURCE_AUTOMATION_TYPE = {
  CREATE: "create",
  UPDATE: "update",
};

/**
 * UI for configuring the AutomationStep of a 'createResource' or 'updateResource'.
 * Allows the user to select a Resource and pre-fill the form that will then be used in the Automation
 * @param {function} toggleModal - function callback to close the containing modal
 * @param {object} automations - the current automation step info for the parent node
 * @param {function} setAutomations - function to update automation data in the parent when its updated here
 * @param {AutomationStep} automationStep - the automation data to edit/create
 * @param {MUTATE_RESOURCE_AUTOMATION_TYPE} type - either "create" or "update" for resource automation action type
 * @param {object} fieldConfig - the generic fields that are used by all automation steps
 * @param {string} organizationID - the ID of the organization
 * @param {boolean} isTemplate - whether or not the automation is a template
 * @param {object} formConfig - form configurations to merge into the main form
 * @returns {JSX.Element}
 * @constructor
 */
const ConfigureMutateResourceAutomation = ({
  toggleModal,
  automations,
  setAutomations,
  automationStep,
  fieldConfig,
  type = MUTATE_RESOURCE_AUTOMATION_TYPE.CREATE,
  organizationID,
  isTemplate,
  formConfig,
}) => {
  const RESOURCE_CONFIG = [
    {
      operations: ["create", "update"],
      typename: "Control",
      form: <CreateControl disabledFields={isTemplate && ["controlControlSetId"]} />,
      grid: <ControlDataGrid />,
      fields: ["name", "statementNumber", "controlControlSetId", "inPlace", "isDisabled"],
    },
    {
      operations: ["create", "update"],
      typename: "Exercise",
      form: <CreateExercise />,
      grid: <ExerciseGrid />,
      fields: ["name", "description", "scenario"],
    },
    {
      operations: ["create", "update"],
      typename: "Evidence",
      form: <CreateEvidence />,
      grid: <EvidenceDataGrid />,
      fields: ["name", "status", "type", "frequency", "task", "allowUnauthenticatedSubmissions", "enabled"],
    },
    {
      operations: ["create", "update"],
      typename: "Incident",
      grid: <IncidentGrid />,
      form: <CreateIncident disabledFields={isTemplate && ["classificationTierID"]} />,
      fields: ["who", "what", "when", "where", "why", "status", "classificationTierID"],
    },
    {
      operations: ["create", "update"],
      typename: "Meeting",
      form: <ScheduleMeeting />,
      grid: <MeetingsDataGrid />,
      fields: ["title", "description", "startTime", "endTime", "location", "isAllDay", "organizer"],
    },
    {
      operations: ["create"],
      typename: "Metric",
      form: <MetricForm disabledFields={isTemplate && ["metricTypeID"]} />,
    },
    {
      operations: ["create", "update"],
      typename: "Observation",
      form: <CreateObservation disabledFields={isTemplate && ["addExistingRecommendation", "departmentID"]} />,
      grid: <ObservationsGrid />,
      fields:
        type === MUTATE_RESOURCE_AUTOMATION_TYPE.CREATE
          ? [
              "name",
              "description",
              "module",
              "isFinding",
              "formalResponse",
              "risk",
              "needsDecision",
              "decision",
              "status",
              "insurancePolicyValidated",
              "departmentID",
              "recommendations",
            ]
          : [
              "name",
              "description",
              "module",
              "isFinding",
              "formalResponse",
              "risk",
              "needsDecision",
              "decision",
              "status",
              "departmentID",
            ],
    },
    // {
    //   typename: "Point of Contact",
    //   fields: [
    //     "firstName",
    //     "lastName",
    //     "email",
    //     "title",
    //     "phone",
    //     "departmentID"
    //   ],
    // },
    {
      operations: ["create", "update"],
      typename: "RiskChange",
      form: (
        <CreateChange disableSimulationMessage={"Note: Simulations will be ran when the automation is executed."} />
      ),
      grid: <RiskChangeDataGrid />,
      fields: ["type", "name", "description", "date", "userEmail", "changeOwner", "reason"],
    },
    {
      operations: ["create", "update"],
      typename: "RiskControl",
      form: <CreateControlCategorySubControl isAutomation={true} isTemplate={isTemplate} />,
      grid: <RiskControlDataGrid showNotes={false} />,
      fields: [
        "name",
        "statementNumber",
        "controlCategoryID",
        "costOfControl",
        "strengthRating",
        "implementationRating",
        "keyControl",
      ],
    },
    // {
    //   typename: "RecommendationItem",
    //   form: <CreateRecommendation/>,
    //   grid: <RecommendationsGrid/>
    // },
    // {
    //   typename: "GenericActionItem",
    //   form: <CreateAction/>,
    //   grid: <ActionGrid/>
    // }
    {
      operations: ["create", "update"],
      typename: "System",
      form: <CreateSystem disableImporter={true} />,
      grid: <SystemDataGrid />,
      fields: ["name", "description", "hosting", "availability"],
    },
    {
      operations: ["create", "update"],
      typename: "Target",
      form: <CreateTarget />,
      grid: <TargetsGrid />,
      fields: ["ip", "hostName", "macAddress"],
    },
    {
      operations: ["create", "update"],
      typename: "Vendor",
      form: <CreateVendor />,
      grid: <VendorDataGrid />,
      fields: ["name", "blackKiteCompanyID"],
    },
    {
      operations: ["create", "update"],
      typename: "VendorSolution",
      form: <CreateVendorSolution disabledFields={isTemplate && ["vendorID"]} />,
      grid: <VendorSolutionsDataGrid />,
      fields: ["name", "description", "vendorID"],
    },
    {
      operations: ["create"],
      fields: [
        "name",
        "description",
        "dueDate",
        "status",
        "module",
        "pointOfContactID",
        "departmentID",
        "type",
        "customContent",
      ],
      typename: "Task",
      form: <CreateTask />,
      grid: <TaskDataGrid />,
    },
    {
      operations: ["create", "update"],
      typename: "CustomResourceEntry",
      form: <CustomResourceEntryForm />,
      grid: <CustomResourceEntryDataGrid />,
    },
  ];

  const genericFieldsForm = useForm({
    disableResetButton: true,
    disableSubmitButton: true,
    disableRoleChecking: true,
    fieldConfig,
    formID: "a395a619-8f88-4c97-84cf-111a3a5b7642",
    item: automationStep,
  });

  const form = useForm({
    disableResetButton: true,
    disableSubmitButton: true,
    disableRoleChecking: true,
    fieldConfig: {
      typename: {
        label: "Resource Type",
        inputType: "dropdown",
        defaultValue: automationStep?.config?.typename || "",
        dropdownConfig: {
          data: RESOURCE_CONFIG.map((item) => {
            return {
              text: convertCamelCaseToSentence(item.typename),
              value: item.typename,
              // disable this option if it's not supported
              disabled:
                (type === MUTATE_RESOURCE_AUTOMATION_TYPE.UPDATE && !item?.operations?.includes("update")) ||
                (type === MUTATE_RESOURCE_AUTOMATION_TYPE.CREATE && !item?.operations?.includes("create")),
            };
          }),
        },
      },
    },
    ...formConfig,
  });

  const onFormSubmit = async ({ input, selectedFields }) => {
    let formInput = {};

    // if selectedFields is passed, only allow those fields to be passed to the form
    // else, just pass the full input
    if (Array.isArray(selectedFields)) {
      for (const field of selectedFields) {
        formInput[field] = input[field];
      }
    } else {
      formInput = input;
    }

    const newAutomations = [...(automations || [])];

    updateObjectInArray(newAutomations, {
      id: automationStep.id,
      name: genericFieldsForm?.input.name,
      description: form.input.typename,
      config: {
        ...automationStep.config,
        typename: form.input.typename,
        formInput: formInput,
      },
    });

    setAutomations(newAutomations);

    tryFunction(toggleModal);
  };

  return (
    <div>
      {genericFieldsForm.display}
      {form.display}
      <hr />
      <DisplayResourceForm
        {...form.input}
        onChange={form.setInput}
        onFormSubmit={onFormSubmit}
        automationStep={automationStep}
        RESOURCE_CONFIG={RESOURCE_CONFIG}
        type={type}
        organizationID={organizationID}
        isTemplate={isTemplate}
      />
    </div>
  );
};

/**
 * Renders the form for a particular resource. Assumes it's an instance of the useForm hook
 * @param {string} typename
 * @param {function} onFormSubmit
 * @param {AutomationStep} automationStep - for existing automations, the Automation Step item
 * @param {object[]} RESOURCE_CONFIG - the list of resource type configs
 * @param {string} type - "create" or "update"
 * @param organizationID
 * @param {boolean} isTemplate - whether this is a template or not
 * @returns {JSX.Element|React.DetailedReactHTMLElement<{submitFunction: *, automationStep: *}, HTMLElement>}
 * @constructor
 */
const DisplayResourceForm = ({
  typename,
  onFormSubmit,
  automationStep,
  RESOURCE_CONFIG,
  type = MUTATE_RESOURCE_AUTOMATION_TYPE.CREATE,
  organizationID,
  isTemplate = false,
}) => {
  if (!isNullOrUndefined(typename) && Array.isArray(RESOURCE_CONFIG)) {
    // Get the config for the selected resource
    const found = RESOURCE_CONFIG.find((item) => item.typename === typename);

    // Handle the 'Create Resource' UI
    if (type === MUTATE_RESOURCE_AUTOMATION_TYPE.CREATE) {
      return (
        <DataLoader
          isLoading={false}
          isEnoughData={!!found}
          dataMessage={"Select a Resource Type to be created or updated"}
        >
          {found?.operations?.includes("create") ? (
            <CreateResourceForm
              typename={typename}
              onFormSubmit={onFormSubmit}
              item={automationStep}
              resourceConfig={found}
              organizationID={organizationID}
              isTemplate={isTemplate}
            />
          ) : (
            <span>Sorry, this Resource can not currently be Created with Automations</span>
          )}
        </DataLoader>
      );
    } else if (type === MUTATE_RESOURCE_AUTOMATION_TYPE.UPDATE) {
      return (
        <DataLoader
          isLoading={false}
          isEnoughData={!!found}
          dataMessage={"Select a Resource Type to be created or updated"}
        >
          {found?.operations?.includes("update") ? (
            <UpdateResourceForm
              typename={typename}
              onFormSubmit={onFormSubmit}
              item={automationStep}
              resourceConfig={found}
              organizationID={organizationID}
              isTemplate={isTemplate}
            />
          ) : (
            <span>Sorry, this Resource can not currently be Updated with Automations</span>
          )}
        </DataLoader>
      );
    }

    if (!found) {
      return <div>Select a Resource Type to be created or updated</div>;
    }

    return <div>This resource is not yet supported</div>;
  } else {
    return <div>Select a Resource Type to be created or updated</div>;
  }
};

const CreateResourceForm = ({ onFormSubmit, item, typename, resourceConfig, organizationID }) => {
  // Some forms require the config to be passed in like this, some do it directly as props
  const formHookConfig = {
    submitFunction: (input) => onFormSubmit({ input }),
    item: item?.config?.formInput,
    disableTemplates: true,
    showOverridesButton: true,
    showIDField: false,
    typename,
    headerButtons: [], // don't show header buttons
    fields: resourceConfig?.fields,
    organizationID,
  };

  // Handle CustomResourceTypeID for customResourceEntry types
  if (typename === "CustomResourceEntry") {
    formHookConfig.customResourceTypeID = item?.config?.formInput?.customResourceTypeID;
  }

  if (resourceConfig && resourceConfig.form) {
    return React.cloneElement(resourceConfig.form, {
      formHookConfig,
      ...formHookConfig,
    });
  }

  if (resourceConfig && !resourceConfig?.form) {
    return <div>This resource is not yet supported</div>;
  }
};

/**
 * Selects one or more fields to update.
 *
 * Utilizes the resource's 'useDetails' hook to get the fields and their values,
 * but captures all updates in a separate state variable instead of sending to server
 * @param {string} typename - the schema name of the resource being created
 * @param {function} onFormSubmit - callback to handle form input on submit button press
 * @param {AutomationStep} item - for existing automations, the Automation Step item
 * @param {object[]} RESOURCE_CONFIG - the list of resource type configs
 * @param {boolean} isTemplate - whether this is a template or not
 * @param {string} organizationID - the ID of the organization (needs to be passed through so that Templates work right
 * @returns {JSX.Element}
 */
const UpdateResourceForm = ({ typename, onFormSubmit, item, resourceConfig, isTemplate = false, organizationID }) => {
  const [itemInput, setItemInput] = useStateSync(item?.config?.formInput);

  const [selectedFields, setSelectedFields] = useState([]);

  // Set the default selected fields if a formInput is already passed in
  useEffect(() => {
    if (item?.config?.formInput) {
      setSelectedFields(Object.keys(item?.config?.formInput));
    }
  }, [item?.config?.formInput]);

  const [availableFields] = useStateSync(resourceConfig?.fields || []);

  // Some forms require the config to be passed in like this, some do it directly as props
  const formHookConfig = {
    organizationID,
    submitFunction: (input) => onFormSubmit({ input, selectedFields: ["id", ...selectedFields] }),
    item: itemInput,
    disableTemplates: true,
    showOverridesButton: true,
    showIDField: true,
    grid: resourceConfig?.grid || undefined,
    typename,
    headerButtons: [], // don't show header buttons
    fields: selectedFields,
    onIDFieldChange: (item) => {
      if (!isNullOrUndefined(item)) {
        setItemInput(item);
      }
    },
    disableResetButton: true,
    disableItemSelect: isTemplate, // disabling the 'ID' field item-select component for Automation Templates
  };

  // Handle CustomResourceTypeID for customResourceEntry types
  if (typename === "CustomResourceEntry") {
    formHookConfig.customResourceTypeID = item?.config?.formInput?.customResourceTypeID;
  }

  if (resourceConfig && resourceConfig.form) {
    return (
      <div>
        <div>
          Select Fields to Update:
          <div>
            {Array.isArray(availableFields) &&
              availableFields.map((field) => {
                return (
                  <FormControlLabel
                    label={field}
                    control={
                      <Checkbox
                        checked={selectedFields?.includes(field)}
                        onChange={(event) => {
                          if (event.target.checked) {
                            setSelectedFields(selectedFields.concat(field));
                          } else {
                            setSelectedFields(selectedFields.filter((item) => item !== field));
                          }
                        }}
                      />
                    }
                  />
                );
              })}
          </div>
        </div>

        {React.cloneElement(resourceConfig.form, {
          formHookConfig,
          disableItemSelect: isTemplate, // disabling the 'ID' field item-select component for Automation Templates
          ...formHookConfig,
        })}
      </div>
    );
  }

  if (resourceConfig && !resourceConfig?.form) {
    return <div>This resource is not yet supported</div>;
  }
};

export default withOrganizationCheck(ConfigureMutateResourceAutomation);
