import type { Dispatch, MutableRefObject, SetStateAction } from "react";
import { useEffect, useRef, useState } from "react";

export interface HandleOutsideEventParams<T> {
  ref: MutableRefObject<T | null>;
  isVisible: boolean;
  setIsVisible: Dispatch<SetStateAction<boolean>>;
}

/**
 * This hook is used to create a `ref` which can be assigned to a component.
 * Doing this then provides the ability to know whether that component is
 * currently visible or not.  For example, this is useful for a dropdown menu.
 * Assign the `ref` to the dropdown menu and when it's open `isVisible` will be
 * `true`.  If the user clicks outside of the dropdown menu, or presses the
 * escape key, `isVisible` will become `false`.
 *
 * Note, this hook doesn't actually handle the showing/hiding of a component.
 * It simply deals with the state of whether it should be visible or not.  It's
 * up to the calling component to handle the display.
 *
 * Taken and modified from: https://stackoverflow.com/a/45323523/1595510
 */
function useHandleOutsideEvent<T extends HTMLElement>(initialIsVisible: boolean): HandleOutsideEventParams<T> {
  const [isVisible, setIsVisible] = useState(initialIsVisible);
  const ref = useRef<T | null>(null);

  const handleOnEscape = (event: KeyboardEvent): void => {
    if (event.key === "Escape") {
      setIsVisible(false);
    }
  };

  const handleClickOutside = (event: MouseEvent): void => {
    const target = event.target as Node;
    if (!target?.isConnected) {
      return;
    }
    if (isVisible && ref.current && !ref.current.contains(target)) {
      setIsVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener("keyup", handleOnEscape);
    document.addEventListener("click", handleClickOutside);

    return (): void => {
      document.removeEventListener("keyup", handleOnEscape);
      document.removeEventListener("click", handleClickOutside);
    };
  });

  return { ref, isVisible, setIsVisible };
}

export default useHandleOutsideEvent;
