/**
 * @deprecated Please use GenericEditFieldV2
 * Author: Jacob Blazina
 * Last Edit: 7/18/19
 *            08/01/19 AR: Added Evidence query and mutation
 *            08/02/19 AR: Added Empty string if field is NULL
 *            08/02/19 AR: Added isAdmin check
 *            08/09/19 AR: Added check for NULL
 *            08/16/19 AR: Added Cancel Button
 *            08/16/19 AR: Added Input Type
 *            08/26/19 JB: Updated PropTypes and changed <textarea /> to <Input type="textarea"/>
 *            08/30/19 AR: Added Notes Mutation
 *            09/12/19 JB: Added inputType = "dropdown" functionality
 *            09/17/19 JB: Added disableEdits prop
 *            09/17/19 AR: Added Assessment mutation
 *            10/02/19 JB: Added maturity matrix mutations
 *            09/27/19 AR: Added Finding mutation
 *            10/02/19 AR: Added Target mutation
 *            11/14/19 AR: Added Testing Action mutation
 *            11/14/19 AR: Fixed inputType "Date"
 *            12/16/19 JB: Added Organization mutation
 *
 * Description: A re-usable generic component for editing
 *              the value of a Field. Includes button and
 *              text input.
 *
 *              Requires:
 *               1. Item to be edited (MUST HAVE ID)
 *               2. The NAME of the mutation (mutation must also be present in HOC in this component)
 *               3. The NAME of the field.
 *
 *              Note: To add a type functionality, include the mutation helper method at the bottom.
 *
 *              Using inputType = "dropdown":
 *                1. Use props.inputType = "dropdown"
 *                2. Must include props.dropdownInput from parent component
 *                3. props.dropdownInput needs initial value from parent, this value will replaced by this component on change.
 *                Example:
 *                  <GenericEditField
 *                      item={props.system}
 *                      field="hosting"
 *                      mutation="updateSystem"
 *                      inputType="dropdown"
 *                      dropdownInput={
 *                         <Input type="select" value={props.system.hosting}>
 *                           <option value={''}>None</option>
 *                           <option value="outsourced">Outsourced</option>
 *                           <option value="hybrid">Hybrid</option>
 *                          <option value="internal">Internal</option>
 *                         </Input>
 *                      }
 *                  />
 *
 */

import "react-datepicker/dist/react-datepicker.css";

import React, { useContext, useEffect, useState } from "react";

import { graphqlMutation } from "aws-appsync-react";
import gql from "graphql-tag";
import PropTypes from "prop-types";
import { compose } from "react-apollo";
import DatePicker from "react-datepicker";
import { Button, CustomInput, FormGroup, Input } from "reactstrap";

import {
  updateAssessment,
  updateControl,
  updateDocument,
  updateEvidence,
  updateFinding,
  updateMaturityMatrix,
  updateOrganization,
  updatePointOfContact,
  updatePolicy,
  updateRisk,
  updateRiskControl,
  updateRole,
  updateSystem,
  updateTarget,
} from "../../graphql/mutations";
import {
  getMaturityMatrix,
  listAssessments,
  listControls,
  listDocuments,
  listEvidences,
  listFindings,
  listOrganizations,
  listPointOfContacts,
  listPolicys,
  listRiskControls,
  listRisks,
  listRoles,
  listSystems,
  listTargets,
} from "../../graphql/queries";
import { useCheckPermissions } from "../../hooks/permissions/useCheckPermissions/useCheckPermissions";
import { OrganizationContext } from "../Context/OrganizationContext";
import { InfoLogger } from "../EventLogger";

const GenericEditField = (props) => {
  const [toggleEdit, setToggleEdit] = useState(false);
  const [value, setValue] = useState(props.item[props.field]);

  const context = useContext(OrganizationContext);

  const { module, resource, field, disableRoleChecking } = props;

  let originalValue;

  useEffect(() => {
    originalValue = value;
  }, []);

  const saveChange = () => {
    if (originalValue !== value) {
      props[props.mutation]({
        input: {
          id: props.item.id,
          [props.field]: value ? value : null,
        },
      }).then(({ data }) => InfoLogger(`Field: ${props.field} Updated to: ${data[props.mutation][props.field]}`));
    } else {
      InfoLogger(`The value for Field: ${props.field} hasn't changed.`);
    }
  };

  const toggle = () => {
    if (toggleEdit) {
      saveChange();
    }

    setToggleEdit(!toggleEdit);
  };

  const cancel = () => {
    setToggleEdit(!toggleEdit);
    setValue(props.item[props.field]);
  };

  const isEnabled = useCheckPermissions({
    module,
    resource,
    field,
    disableRoleChecking,
  }).field.update;

  return (
    <>
      {!toggleEdit ? (
        <>
          {value
            ? props.inputType && props.inputType === "date"
              ? new Date(value).toLocaleString()
              : `${value}  `
            : " "}
          {isEnabled && context.isAdmin && !props.disableEdits ? (
            <Button size="sm" color="ghost-warning" className="btn-pill" onClick={() => toggle()}>
              <i className="icon-pencil" />
            </Button>
          ) : null}
        </>
      ) : (
        <FormGroup>
          {props.inputType === "textarea" ? (
            <Input type="textarea" value={value} rows="4" cols="25" onChange={(e) => setValue(e.target.value)} />
          ) : props.inputType === "dropdown" && props.dropdownInput ? (
            React.cloneElement(props.dropdownInput, {
              value: value,
              onChange: (e) => setValue(e.target.value),
            })
          ) : props.inputType === "toggle" ? (
            <CustomInput
              type="switch"
              id="exampleCustomSwitch"
              name="customSwitch"
              label="This Indicator is Satisfied"
            />
          ) : props.inputType === "date" ? (
            <DatePicker
              dateFormat="MMMM d, yyyy h:mm aa"
              placeholderText="Select a Date and Time"
              showTimeInput
              minDate={new Date(null)}
              maxDate={new Date().setMonth(new Date().getMonth() + 24)}
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              selected={new Date(value)}
              onChange={(date) => setValue(date)}
            />
          ) : (
            <Input value={value} onChange={(e) => setValue(e.target.value)} />
          )}
          <br />
          <Button size="sm" color="success" className="btn-pill" onClick={() => toggle()}>
            <i className="icon-check" />
          </Button>
          <Button size="sm" color="danger" className="btn-pill" onClick={() => cancel()}>
            <i className="icon-close" />
          </Button>
        </FormGroup>
      )}
    </>
  );
};

GenericEditField.propTypes = {
  item: PropTypes.object.isRequired,
  field: PropTypes.string.isRequired,
  mutation: PropTypes.string.isRequired,
  inputType: PropTypes.string, // Options: "textarea", "dropdown", "toggle", "date
  dropdownInput: PropTypes.object, // Only applicable is inputType = "dropdown"
  disableEdits: PropTypes.bool,
};

export default compose(
  graphqlMutation(gql(updateControl), gql(listControls), "Control"),
  graphqlMutation(gql(updateEvidence), gql(listEvidences), "Control"),
  graphqlMutation(gql(updateRiskControl), gql(listRiskControls), "RiskControl"),
  graphqlMutation(gql(updateRisk), gql(listRisks), "Risk"),
  graphqlMutation(gql(updateSystem), gql(listSystems), "System"),
  graphqlMutation(gql(updateAssessment), gql(listAssessments), "Assessment"),
  graphqlMutation(gql(updateMaturityMatrix), gql(getMaturityMatrix), "MaturityIndicator"),
  graphqlMutation(gql(updateTarget), gql(listTargets), "Target"),
  graphqlMutation(gql(updateFinding), gql(listFindings), "Finding"),
  graphqlMutation(gql(updatePolicy), gql(listPolicys), "Policy"),
  graphqlMutation(gql(updatePointOfContact), gql(listPointOfContacts), "PointOfContact"),
  graphqlMutation(gql(updateOrganization), gql(listOrganizations), "Organization"),
  graphqlMutation(gql(updateDocument), gql(listDocuments), "Document"),
  graphqlMutation(gql(updateRole), gql(listRoles), "Role"),
)(GenericEditField);
