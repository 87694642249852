import { modules, resources } from "@rivial-security/role-utils";

import { getResourceQueryData } from "../../../../../definitions/functions/getResourceQueryData";
import { fieldContexts } from "../../../../../enums/fieldContexts";
import { useDataGrid } from "../../../../../hooks/views/useDataGrid/useDataGrid";
import CreatePlaybook from "../components/CreatePlaybook";
import PlaybookDetails from "../components/PlaybookDetails";

interface PlaybookDataGridProps {
  organizationID: string;
  gridConfig?: object;
}

export const usePlaybookDataGrid = ({ organizationID, gridConfig = {}, ...props }: PlaybookDataGridProps) => {
  const module = modules.INCIDENT_RESPONSE;
  const resource = resources.PLAYBOOK;
  const typename = "Playbook";

  const initQueryConfig = {
    indexName: "playbooksByOwnerGroup",
    variables: { ownerGroup: organizationID },
    limit: 1000,
  };

  const { queryConfig, updateMutation } =
    getResourceQueryData({
      fieldContext: fieldContexts.GRID,
      overrideQueryConfig: initQueryConfig,
      typename,
    }) || {};

  const fields = [
    {
      name: "name",
      flex: 1,
    },
    {
      name: "description",
      flex: 1,
    },
  ];

  gridConfig = {
    organizationID,
    typename,
    fields,
    module,
    resource,
    updateMutation,

    options: ["details", "delete", "edit"],

    detailsComponent: <PlaybookDetails tableDisplay={true} />,
    createResourceComponent: <CreatePlaybook />,
    persistenceUUID: "d620dba0-1749-4644-977e-e11e9e53706d",
    route: "#/response/playbooks/",

    ...gridConfig,
  };

  return useDataGrid({
    ...gridConfig,
    ...queryConfig,
    ...props,
  });
};
