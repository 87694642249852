import { AUDITS_BY_CONTROL_FRAMEWORK } from "@compliance/api";
import { createLazyQueryHook } from "@hooks/api/createLazyQueryHook";

import type { AuditsByControlFrameworkQuery, AuditsByControlFrameworkQueryVariables } from "@graphql/types/graphql";
import type { LazyQueryHookResult } from "@hooks/api/createLazyQueryHook";

export const useControlFrameworkAudits = (
  controlFrameworkId: string,
): LazyQueryHookResult<AuditsByControlFrameworkQuery, AuditsByControlFrameworkQueryVariables> =>
  createLazyQueryHook<AuditsByControlFrameworkQuery, AuditsByControlFrameworkQueryVariables>(
    AUDITS_BY_CONTROL_FRAMEWORK,
    "Unable to query control framework audits",
  )({
    variables: {
      controlFrameworkId,
    },
  });
