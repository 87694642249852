import type { ReactElement } from "react";

import Fade from "@mui/material/Fade";

interface TourCardProps {
  title?: string;
  content?: string;
  children?: React.ReactNode;
}

const TourCard = ({ title, content, children }: TourCardProps): ReactElement => {
  return (
    <Fade in={true}>
      <span>
        {title && <h5>{title}</h5>}
        {content ?? children}
      </span>
    </Fade>
  );
};

export default TourCard;
