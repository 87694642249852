import React from "react";

import DashboardCard from "../../../../../utils/GenericComponents/DashboardCard/components/DashboardCard";

import MeetingAgendaV2 from "./MeetingAgendaV2";

/**
 * Shows the old and new meeting agenda views (allows users to migrate from old to new views with a click)
 * @param {string} organizationID - currently selected organization id
 * @param {object} detailsHook - meeting details hook instance
 * @param {object} meeting - the meeting data from the database
 * @param {object} sx - inline style properties
 * @returns {JSX.Element}
 */
const MeetingAgendaWrapper = ({ organizationID, detailsHook, meeting, sx }) => {
  return (
    <DashboardCard style={{ overflowY: "auto", ...(sx?.dashboardCard || {}) }} title="Agenda" icon={"icon-calendar"}>
      <MeetingAgendaV2
        organizationID={organizationID}
        meeting={meeting}
        onChangeCallback={(newItems) => {
          detailsHook?.setItem({
            ...meeting,
            agenda: newItems,
          });
        }}
      />
    </DashboardCard>
  );
};

export default MeetingAgendaWrapper;
