import type { ComponentPropsWithoutRef, ElementType, ReactElement } from "react";

import cx from "classnames";

import styles from "./Card.module.scss";

interface ElementProps<E extends ElementType> {
  /** The element to render the card as. */
  as?: E;
}

// Combine the HTML element props with our custom props, excluding overlapping props
type CardProps<E extends ElementType = "article"> = ElementProps<E> &
  Omit<ComponentPropsWithoutRef<E>, keyof ElementProps<E>>;

/**
 * A flexible card component that can be rendered as any HTML element.  Automatically adds interactive styling when click or keyboard handlers are present.
 */
export const Card = <E extends ElementType = "article">({
  as,
  children,
  className,
  onClick,
  onKeyDown,
  ...rest
}: CardProps<E>): ReactElement => {
  const Component = as ?? "article";
  const isInteractive = !!onClick || !!onKeyDown;

  return (
    <Component
      className={cx(
        styles.card,
        {
          [styles["card--interactive"]!]: isInteractive,
        },
        className,
      )}
      onClick={onClick}
      onKeyDown={onKeyDown}
      {...rest}
    >
      {children}
    </Component>
  );
};

export type { CardProps };
