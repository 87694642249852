import { isNullOrUndefined } from "@rivial-security/func-utils";
import { generateGraphql } from "@rivial-security/generategraphql";
import PhoneNumber from "awesome-phonenumber";
import PropTypes from "prop-types";
import { useContext, useEffect, useState } from "react";
import { Button, CustomInput, Spinner, Table } from "reactstrap";

import { modules, resources } from "@rivial-security/role-utils";

import { ErrorLogger } from "@utils/EventLogger";

import awsmobile from "../../../../../../aws-exports";
import { useCheckPermissions } from "../../../../../../hooks/permissions/useCheckPermissions/useCheckPermissions";
import { useDetailsCard } from "../../../../../../hooks/views/useDetailsCardV2";
import { useModal } from "../../../../../../hooks/views/useModal";
import { OrganizationContext } from "../../../../../../utils/Context/OrganizationContext";
import { UIContext } from "../../../../../../utils/Context/UIContext";
import { withDetailsPage } from "../../../../../../utils/Context/withDetailsPage";
import { SendRequestToLambda } from "../../../../../../utils/Functions/Lambda/sendRequestToLambda";
import { getCognitoPhoneNumber } from "../../../../../../utils/Functions/Number/getCognitoPhoneNumber";
import { PasswordGenerator } from "../../../../../../utils/Functions/PasswordGenerator";
import GenericEditField from "../../../../../../utils/GenericComponents/GenericEditFieldV2";
import GenericEditFieldV3 from "../../../../../../utils/GenericComponents/GenericEditFieldV3/GenericEditFieldV3";
import NestedField from "../../../../../../utils/GenericComponents/NestedField/NestedField";
import DataLoader from "../../../../../../utils/LoadingComponents/DataLoader";
import { CheckPhoneNumberRegex } from "../../../../../../utils/Regex/Regex";
import { performToastOperation } from "../../../../../../utils/Toasts/functions/toastOperation";
import DepartmentDetails from "../../../../Departments/components/DepartmentDetails";
import DepartmentGrid from "../../../../Departments/components/DepartmentGrid";
import { useDisplayRole } from "../../../../Roles/hooks/useDisplayRole";
import { checkForExistingUser } from "../../../../Users/functions/CheckForExistingUser";
import { createUser } from "../../../../Users/functions/createUser";
import { deleteUser } from "../../../../Users/functions/deleteUser";
import { useChangeUserPassword } from "../../../../Users/hooks/useChangeUserPassword";
import { useAddUserToRole } from "../../../hooks/useAddUserToRole";

/**
 * Displays basic details for a Point of Contact
 * @param {string} itemId - point of contactID
 * @param {function} updateItemById - callback for updating a parent component after editing in this component
 * @param {string} organizationID - current orgID
 * @param {string} [module = ORGANIZATION_MANAGER] - module used for role checking, can be overwritten
 * @param {string} [resource = POINT_OF_CONTACT] - resource used for role checking, can be overwritten
 * @returns {JSX.Element}
 * @constructor
 */
const PointOfContactInfo = ({ itemId, updateItemById, organizationID, module, resource }) => {
  const queryConfig = {
    query: getPointOfContact,
    itemId,
    disableRoleChecking: true,
  };

  const detailsHook = useDetailsCard({
    disableRoleChecking: true,
    queryConfig,
    config: {
      header: "Account Info",
      detailsComponent: (
        <Body organizationID={organizationID} updateItemById={updateItemById} module={module} resource={resource} />
      ),
    },
    updateItemById,
  });

  return (
    <>
      <DataLoader isLoading={detailsHook.isLoading} isEnoughData={detailsHook.item}>
        {detailsHook.tableDisplay}
      </DataLoader>
    </>
  );
};

PointOfContactInfo.propTypes = {
  pointOfContact: PropTypes.object, //deprecated. use props.item instead
  item: PropTypes.object,
  isAdmin: PropTypes.bool,
};

PointOfContactInfo.defaultProps = {
  pointOfContact: {},
  isAdmin: false,
};

const { updateMutation: updatePointOfContact, getQuery: getPointOfContact } = generateGraphql(
  "PointOfContact",
  ["firstName", "lastName", "email", "title", "phone", "user", "departmentID", "department"],
  {
    user: `{ id username ssoUser name roleLinks { items { id role { id name roleConfig precedence } } } }`,
    department: `{ id name ownerGroup }`,
  },
);

export default withDetailsPage(PointOfContactInfo);

const Body = ({
  item,
  resetFunction,
  organizationID,
  updateItemById,
  module: moduleInput,
  resource: resourceInput,
}) => {
  const { addToast, updateToast } = useContext(UIContext);

  const { userEmail } = useContext(OrganizationContext);

  const [allowResetPassword, setAllowResetPassword] = useState(false);

  const roleInit = item?.user?.roleLinks?.items[0]?.role;

  const roleConfig = item?.user?.roleLinks?.items[0]?.role?.roleConfig;

  const role = { ...roleInit };

  if (roleConfig) {
    role.roleConfig = JSON.parse(roleConfig);
  }

  const displayRole = useDisplayRole({ role });

  const [addNewUser, setAddNewUser] = useState(false);
  const [password] = useState(PasswordGenerator());

  const module = modules.ORGANIZATION_MANAGER;
  const resource = resources.USER;

  const checkPermissionsHook = useCheckPermissions({ module, resource });

  const pleaseWaitModal = useModal(
    "Please wait...",
    <div style={{ textAlign: "center" }}>
      <h2>Please wait!</h2>
      <p>Creating a new user</p>
      <Spinner size="lg" color="primary" />
    </div>,
    null,
    {},
  );

  /**
   * Check if an user exists
   */
  const handleAddNewUser = async () => {
    if (!item) return;

    await checkForExistingUser(item.email, item.phone, organizationID).then((userFound) => {
      if (userFound === true) {
        alert(`User with an email: ${item.email} or Phone Number: ${item.phone} already exists`);
        setAddNewUser(false);
      } else {
        setAddNewUser(!addNewUser);
      }
    });
  };

  /**
   * Assign user to a role
   */
  const addUserToRole = useAddUserToRole({
    organizationID,
    user: item?.user,
    resetFunction,
  });

  /**
   * Change user password
   */
  const changeUserPassword = useChangeUserPassword({ user: item?.user });

  /**
   * Get Cognito user information
   */
  const getCognitoUser = async () => {
    if (item?.user?.username) {
      await SendRequestToLambda({
        functionName: "CognitoAPI",
        invocationType: "RequestResponse ",
        payload: {
          operation: "getUser",
          userName: item?.user?.username,
          userPoolId: awsmobile["aws_user_pools_id"],
        },
        callbackFunction: async (data) => {
          try {
            const dataJson = JSON.parse(data);
            if (dataJson?.user?.UserStatus === "FORCE_CHANGE_PASSWORD") {
              setAllowResetPassword(true);
            }
          } catch (e) {
            ErrorLogger("Error! Cannot parse data from CognitoAPI", e);
          }
        },
      });
    }
  };

  useEffect(() => {
    /**
     * Get user information only if logged in user has role permissions to organization manager -> user
     */
    if (checkPermissionsHook?.resource?.update) {
      getCognitoUser();
    }
  }, [item]);

  return (
    <>
      {item ? (
        <Table striped hover responsive>
          <tbody>
            <tr key={"firstName"}>
              <td>First Name:</td>
              <td>
                <GenericEditField
                  item={item}
                  mutation={updatePointOfContact}
                  module={moduleInput || module}
                  resource={resourceInput || resources.POINT_OF_CONTACT}
                  field="firstName"
                  resetFunction={resetFunction}
                  updateItemById={updateItemById}
                  typename={"PointOfContact"}
                />
              </td>
            </tr>
            <tr key={"lastName"}>
              <td>Last Name:</td>
              <td>
                <GenericEditField
                  item={item}
                  mutation={updatePointOfContact}
                  module={moduleInput || module}
                  resource={resourceInput || resources.POINT_OF_CONTACT}
                  field="lastName"
                  resetFunction={resetFunction}
                  updateItemById={updateItemById}
                  typename={"PointOfContact"}
                />
              </td>
            </tr>
            <tr key={"contact_email"}>
              <td>Email:</td>
              <td>
                <GenericEditField
                  item={item}
                  mutation={updatePointOfContact}
                  module={moduleInput || module}
                  resource={resourceInput || resources.POINT_OF_CONTACT}
                  field="email"
                  forceLowercase={true}
                  updateInputFunction={(value) => value?.trim()}
                  disableEdits={!!(item && item.user)}
                  resetFunction={resetFunction}
                  updateItemById={updateItemById}
                  typename={"PointOfContact"}
                />
              </td>
            </tr>
            <tr key={"contact_title"}>
              <td>Title:</td>
              <td>
                <GenericEditField
                  item={item}
                  mutation={updatePointOfContact}
                  module={moduleInput || module}
                  resource={resourceInput || resources.POINT_OF_CONTACT}
                  field="title"
                  resetFunction={resetFunction}
                  updateItemById={updateItemById}
                  typename={"PointOfContact"}
                />
              </td>
            </tr>
            <tr key={"contact_phone"}>
              <td>Phone Number:</td>
              <td>
                <GenericEditFieldV3
                  item={item}
                  mutation={updatePointOfContact}
                  module={moduleInput || module}
                  resource={resourceInput || resources.POINT_OF_CONTACT}
                  field="phone"
                  updateInputFunction={getCognitoPhoneNumber}
                  displayFormat={(phoneNumber) => {
                    if (isNullOrUndefined(phoneNumber)) {
                      return "";
                    }
                    const pn = new PhoneNumber(phoneNumber);
                    return pn?.getNumber("international") ?? "";
                  }}
                  disableEdits={!!(item && item.user)}
                  resetFunction={resetFunction}
                  updateItemById={updateItemById}
                  inputConfig={{
                    isValidInput: (input) => CheckPhoneNumberRegex(getCognitoPhoneNumber(input)),
                    validInputMessage: "Phone number is valid",
                    invalidInputMessage:
                      "Invalid format, must contain 10 - 15 digits. International numbers must start with a plus sign and contain country code.",
                  }}
                  typename={"PointOfContact"}
                />
              </td>
            </tr>
            <tr key={"contact_department"}>
              <td>Department:</td>
              <td>
                <NestedField
                  item={item}
                  parentTypename={"PointOfContact"}
                  resetFunction={resetFunction}
                  typename={"Department"}
                  field={"department"}
                  idField={"departmentID"}
                  detailsComponent={<DepartmentDetails />}
                  grid={<DepartmentGrid />}
                />
              </td>
            </tr>
            {checkPermissionsHook.resource.read && item && item.email && item.phone ? (
              <tr>
                <td>App Access:</td>
                <td>
                  {item && item.user ? (
                    <>
                      <span style={{ color: "green" }}>This Point of Contact has Access to the App</span>{" "}
                      {checkPermissionsHook.resource.update && !item?.user?.ssoUser && allowResetPassword && (
                        <Button
                          id="button-reset-password"
                          size="md"
                          color="ghost-danger"
                          className="btn-pill"
                          title="Click to reset user's password. Use only if a user account didn't get set up before the temporary password expired."
                          onClick={() => {
                            const toastId = addToast({
                              header: `Trying to reset User's password...`,
                              icon: "spinner",
                              color: "success",
                            });

                            SendRequestToLambda({
                              functionName: "reset_expired_cognito_user",
                              invocationType: "RequestResponse",
                              payload: {
                                temporaryPassword: PasswordGenerator(),
                                username: item && item.user && item.user.username,
                                email: item && item.user && item.user.username,
                                userPoolId: awsmobile["aws_user_pools_id"],
                              },
                              callbackFunction: (data) => {
                                const response = JSON.parse(data);

                                if (!response["errorType"]) {
                                  updateToast({
                                    id: toastId,
                                    header: `Password was successfully reset.`,
                                    icon: "success",
                                  });
                                } else {
                                  updateToast({
                                    id: toastId,
                                    header: `Error! Password was NOT successfully reset. ${response["errorType"]}`,
                                    icon: "danger",
                                  });
                                }
                              },
                            }).then(() => resetFunction && resetFunction());
                          }}
                        >
                          Reset Temporary Password
                        </Button>
                      )}
                    </>
                  ) : (
                    <span style={{ color: "red" }}>This Point of Contact does not have Access to the App</span>
                  )}
                  {item && !item.user && checkPermissionsHook.resource.create ? (
                    <>
                      <hr />
                      <div>
                        Create Credentials to access the App:
                        <CustomInput
                          type="switch"
                          id="add-user"
                          name="add-user"
                          checked={addNewUser}
                          onChange={() => handleAddNewUser()}
                        />
                        {item && !item.user && addNewUser && (
                          <span style={{ color: "red" }}>
                            Warning! A new User will be created and it will have login credentials to the App.
                          </span>
                        )}
                      </div>
                    </>
                  ) : (
                    <>
                      {checkPermissionsHook.resource.delete && (
                        <Button
                          id="button-remove-user-access"
                          type="submit"
                          className="btn-pill"
                          color="ghost-danger"
                          onClick={() =>
                            performToastOperation({
                              addToast,
                              updateToast,
                              operation: async () => await deleteUser({ item: item?.user, callback: resetFunction }),
                              inProgressText: "Removing Platform Access...",
                              failedText: "Failed to remove Platform Access.",
                              successText: "Successfully removed Platform Access.",
                              iconColor: "success",
                            })
                          }
                        >
                          {" "}
                          <i className="icon-trash" />
                          Remove Access
                        </Button>
                      )}
                    </>
                  )}
                </td>
              </tr>
            ) : (
              <tr>
                <td>App Access:</td>
                <td>Email and Phone number are required for Application Access</td>
              </tr>
            )}
            {item && !item.user && addNewUser ? (
              <tr>
                <td>{pleaseWaitModal.modal}</td>
                <td>
                  <Button
                    id="button-submit-newUser"
                    type="submit"
                    disabled={!password}
                    onClick={() => {
                      pleaseWaitModal.setModalIsOpen(true);

                      createUser({
                        name: `${item.firstName} ${item.lastName}`,
                        email: item.email,
                        phoneNumber: item.phone,
                        password: password,
                        organizationID: item.ownerGroup,
                        pointOfContactId: item.id,
                        accountType: "standard",
                        callbackFunction: () => {
                          pleaseWaitModal.setModalIsOpen(false);
                          resetFunction?.();
                        },
                      });
                    }}
                    size="sm"
                    color="danger"
                  >
                    <i className="fa fa-dot-circle-o" /> Give Access
                  </Button>
                </td>
              </tr>
            ) : null}
            <tr>
              <td>Role:</td>
              <td>
                {displayRole.modalButton} {checkPermissionsHook.resource.update && addUserToRole.modalButton}
              </td>
            </tr>
            {item?.user?.id &&
              !item?.user?.ssoUser &&
              checkPermissionsHook.resource.update &&
              userEmail === item?.user?.username && (
                <tr>
                  <td>Change Password:</td>
                  <td>{changeUserPassword.modalButton}</td>
                </tr>
              )}
          </tbody>
        </Table>
      ) : (
        "Point Of Contact Not Found"
      )}
    </>
  );
};
