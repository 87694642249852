import React, { useState } from "react";

import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import ReactCardFlip from "react-card-flip";

import { isNullOrUndefined } from "@rivial-security/func-utils";

import { getIcon } from "../../../Functions/Icon/getIcon";
import DashboardCard from "../../DashboardCard/components/DashboardCard";

/**
 * A version of the DashboardCard that can be flipped over to view another component
 * @param {object} props
 * @param {string} props.flipTitle - the title for the back of the card
 * @param {JSX.Element} props.flipContent - the component for the back of the card
 * @param {string} props.title - the title for the front of the card
 * @param {JSX.Element} props.content - the component for the front of the card
 * @param {object} [props.style] - optional styling
 * @param {function} [props.onFlip] - function gets called when the card gets flipped
 * @param {boolean} props.isFlippedCard
 * @returns {JSX.Element}
 */
export const FlipCard = ({
  flipTitle,
  flipContent,
  title,
  content,
  style = { height: "100%" },
  onFlip,
  icon,
  flipIcon,
  tooltip,
  flipTooltip,
}) => {
  const [isFlipped, setIsFlipped] = useState(false);

  const onClick = () => {
    if (flipContent) {
      setIsFlipped(!isFlipped);
      onFlip?.();
    }
  };

  return (
    <ReactCardFlip
      isFlipped={isFlipped}
      flipDirection="horizontal"
      flipSpeedBackToFront={1.0}
      flipSpeedFrontToBack={1.0}
      containerStyle={{ height: "100%" }}
    >
      <DashboardCard
        title={title}
        icon={icon}
        style={style}
        headerButtons={!isNullOrUndefined(flipContent) && [<FlipButton key={0} title={flipTitle} onClick={onClick} />]}
        tooltip={tooltip}
      >
        {content}
      </DashboardCard>
      <DashboardCard
        title={flipTitle || title}
        icon={flipIcon || icon}
        style={style}
        headerButtons={!isNullOrUndefined(content) && [<FlipButton key={0} title={title} onClick={onClick} />]}
        tooltip={flipTooltip || tooltip}
      >
        {flipContent}
      </DashboardCard>
    </ReactCardFlip>
  );
};

const FlipButton = ({ title, onClick }) => {
  return (
    <IconButton
      style={{ marginTop: "-0.5em", marginRight: "-0.5em" }}
      aria-label="flip-button"
      onClick={onClick}
      size="large"
    >
      <Tooltip title={`Flip to view ${title}`}>{getIcon("mdi:rotate-360")}</Tooltip>
    </IconButton>
  );
};
