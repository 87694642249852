import { useMemo } from "react";
import type { ComponentProps, ReactElement } from "react";

import { ResponsiveRadar } from "@nivo/radar";

import { GridLabel } from "@components/Molecules/RadarChart/GridLabel";
import { Tooltip } from "@components/Molecules/RadarChart/Tooltip";

export type RadarChartProps = ComponentProps<typeof ResponsiveRadar>;

/**
 * Custom color array using Astra UI theme
 */
export const defaultColors = [
  `var(--color-primary-light2)`,
  `var(--color-primary-main)`,
  `var(--color-primary-light1)`,
  `var(--color-secondary-main)`,
  `var(--theme-color-pink-06)`,
  `var(--theme-color-lime-06)`,
  `var(--theme-color-ultramarine-06)`,
  `var(--theme-color-red-06)`,
  `var(--theme-color-green-06)`,
  `var(--theme-color-purple-06)`,
  `var(--theme-color-orange-06)`,
  `var(--theme-color-teal-06)`,
  `var(--theme-color-yellow-06)`,
  `var(--theme-color-blue-06)`,
];

/**
 * A radar chart component that wraps Nivo's ResponsiveRadar component
 * with our design system's styling.
 *
 * Uses our theme colors (primary, secondary, and denotive colors) for the chart areas
 * with no https://nivo.rocks/guides/theming/orders and a higher fill opacity for better visibility.
 */
export const RadarChart = ({ theme, ...rest }: RadarChartProps): ReactElement => {
  const defaultTheme = useMemo<RadarChartProps["theme"]>(
    () =>
      ({
        fontFamily: "var(--font-family)",
        fontSize: 13,
        textColor: "var(--color-text-on-light-secondary)",
        crosshair: {
          line: {
            strokeWidth: 2,
            stroke: "var(--color-bg-gray5)",
          },
        },
        annotations: {
          text: {
            fontFamily: "var(--font-family)",
            fontSize: "var(--font-caption)",
            fontWeight: "var(--font-weight-regular)",
            lineHeight: "var(--line-height-caption)",
          },
        },
        grid: {
          line: {
            stroke: "var(--color-bg-gray5)",
            strokeWidth: 1,
          },
        },
        labels: {
          text: {
            fontFamily: "var(--font-family)",
            fontSize: "var(--font-caption)",
            fill: "var(--color-text-on-light-secondary)",
          },
        },
        ...theme,
      }) satisfies RadarChartProps["theme"],
    [theme],
  );

  return (
    <ResponsiveRadar
      animate={false}
      borderWidth={0}
      colors={defaultColors}
      enableDots={false}
      fillOpacity={0.8}
      gridLabel={GridLabel}
      gridLabelOffset={14}
      gridShape="linear"
      sliceTooltip={Tooltip}
      theme={defaultTheme}
      {...rest}
    />
  );
};

export default RadarChart;
