import { isThemeColor } from "@components/Ions/colors/constants/ThemeColors";
import { CssColor } from "@components/Ions/colors/types/CssColor";

import type { ThemeColor } from "@components/Ions/colors/constants/ThemeColors";

export class TagColor {
  private readonly _baseColor: ThemeColor | undefined;
  private readonly _foregroundColor: CssColor;
  private readonly _backgroundColor: CssColor;

  public static createFromThemeColor(baseColor: string): TagColor {
    if (!isThemeColor(baseColor)) {
      throw new Error("Invalid theme color provided");
    }

    return new TagColor(
      baseColor,
      CssColor.create(`var(--theme-color-${baseColor}-09)`),
      CssColor.create(`var(--theme-color-${baseColor}-03)`),
    );
  }

  public static createFromColors(foregroundColor?: string, backgroundColor?: string): TagColor {
    if (isThemeColor(foregroundColor) && isThemeColor(backgroundColor)) {
      // If both are theme colors, create using first one as base
      return TagColor.createFromThemeColor(foregroundColor);
    }

    if (isThemeColor(foregroundColor)) {
      return new TagColor(
        foregroundColor,
        CssColor.create(`var(--theme-color-${foregroundColor}-09)`),
        CssColor.create(backgroundColor),
      );
    }

    if (isThemeColor(backgroundColor)) {
      return new TagColor(
        backgroundColor,
        CssColor.create(foregroundColor),
        CssColor.create(`var(--theme-color-${backgroundColor}-03)`),
      );
    }
    return new TagColor(undefined, CssColor.create(foregroundColor), CssColor.create(backgroundColor));
  }

  public toString(): string {
    return `${this._foregroundColor.toString()}, ${this._backgroundColor.toString()}`;
  }

  public get baseColor(): ThemeColor | undefined {
    return this._baseColor;
  }

  public get foregroundColor(): CssColor {
    return this._foregroundColor;
  }

  public get backgroundColor(): CssColor {
    return this._backgroundColor;
  }

  public equals(other: TagColor): boolean {
    return (
      this._baseColor === other._baseColor &&
      this._foregroundColor.equals(other._foregroundColor) &&
      this._backgroundColor.equals(other._backgroundColor)
    );
  }

  private constructor(baseColor: ThemeColor | undefined, foregroundColor: CssColor, backgroundColor: CssColor) {
    this._baseColor = baseColor;
    this._foregroundColor = foregroundColor;
    this._backgroundColor = backgroundColor;
  }
}
