import Text, { TextVariant } from "@components/Atoms/Text/Text";
import tooltipStyles from "@components/Atoms/Tooltip/Tooltip.module.scss";

import styles from "./RadarChart.module.scss";

import type { RadarSliceTooltipProps } from "@nivo/radar";

export interface TooltipProps extends RadarSliceTooltipProps {
  /** To translate a datum's ID to a label */
  keyMap?: Record<string, string>;
}

/**
 * Custom tooltip component for radar chart slices.
 */
export const Tooltip = ({ data, index, keyMap }: TooltipProps) => {
  return (
    <div className={`${tooltipStyles.tooltip__content} ${styles.tooltip}`}>
      <Text htmlElement="span" variant={TextVariant.SUBTITLE3} className={styles.tooltip__title}>
        {index}
      </Text>
      <div className={styles.tooltip__grid}>
        {data.map((datum) => (
          <div key={datum.id} className={styles.tooltip__item}>
            <div className={styles.tooltip__swatch} style={{ backgroundColor: datum.color }} />
            <Text variant={TextVariant.CAPTION}>
              {keyMap?.[datum.id] ?? datum?.id}: {datum.formattedValue || datum.value}
            </Text>
          </div>
        ))}
      </div>
    </div>
  );
};
