"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || (function () {
    var ownKeys = function(o) {
        ownKeys = Object.getOwnPropertyNames || function (o) {
            var ar = [];
            for (var k in o) if (Object.prototype.hasOwnProperty.call(o, k)) ar[ar.length] = k;
            return ar;
        };
        return ownKeys(o);
    };
    return function (mod) {
        if (mod && mod.__esModule) return mod;
        var result = {};
        if (mod != null) for (var k = ownKeys(mod), i = 0; i < k.length; i++) if (k[i] !== "default") __createBinding(result, mod, k[i]);
        __setModuleDefault(result, mod);
        return result;
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.generateCoverPage = void 0;
const getImageDimensionsByFitType_1 = require("../functions/getImageDimensionsByFitType");
/**
 * Function responsible for generating the cover page sfdt definition for all reports
 * @param {object} params
 * @param {string} params.reportTitle - the title of the report to be displayed on the cover page
 * @param {string} params.organizationName - the name of the organization to be displayed on the cover page
 * @param {number} [params.verticalHeadingTextPosition] - vertical position of the heading text override
 * @param {number} [params.reportTitleFontSize] - font size override for the report title
 * @param {object} [params.orgLogo] - image object containing {dimensions: {width, height}, url: base64 string}
 * @param {string} [params.customCoverPageImage] - custom cover page image as a base64 string
 * @returns Promise<object> - sfdt definition for cover page
 */
const generateCoverPage = async ({ reportTitle, organizationName, verticalHeadingTextPosition, reportTitleFontSize, orgLogo, customCoverPageImage, }) => {
    const getCoverPageImage = async () => {
        console.log("getting cover page image...");
        try {
            if (customCoverPageImage) {
                return customCoverPageImage;
            }
            const coverPageModule = await Promise.resolve().then(() => __importStar(require("../constants/COVER_PAGE_IMAGE_STRING")));
            return coverPageModule.COVER_PAGE_IMAGE_STRING;
        }
        catch (error) {
            console.error("Unable to import COVER_PAGE_IMAGE_STRING", error);
            return null;
        }
    };
    const coverPageImage = await getCoverPageImage();
    const { finalWidth: finalLogoWidth, finalHeight: finalLogoHeight } = (0, getImageDimensionsByFitType_1.getImageDimensionsByFitType)({
        image: orgLogo,
        maxWidth: 250,
        maxHeight: 150,
        fitType: getImageDimensionsByFitType_1.FIT_TYPE.CONTAIN,
    });
    const images = {
        orgLogo: orgLogo?.url,
    };
    //Forming Current Date String
    const monthNames = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
    ];
    const currentDate = new Date();
    const currentDateString = `${monthNames[currentDate.getMonth()]} ${currentDate.getDate()}, ${currentDate.getFullYear()}`;
    return {
        sectionFormat: {
            pageWidth: 612,
            pageHeight: 792,
            leftMargin: 0,
            rightMargin: 0,
            topMargin: 0,
            bottomMargin: 0,
            differentFirstPage: true,
            differentOddAndEvenPages: false,
            headerDistance: 36,
            footerDistance: 36,
            bidi: false,
        },
        blocks: [
            {
                characterFormat: {
                    fontColor: "#00000000",
                },
                inlines: [
                    ...(images?.orgLogo
                        ? [
                            {
                                allowOverlap: true,
                                alternativeText: null,
                                autoShapeType: "Rectangle",
                                characterFormat: {},
                                distanceBottom: 0,
                                distanceLeft: 0,
                                distanceRight: 0,
                                distanceTop: 0,
                                height: 147.48393000000002,
                                heightScale: 100,
                                horizontalAlignment: "None",
                                horizontalOrigin: "Column",
                                horizontalPosition: 20.32,
                                layoutInCell: true,
                                lineFormat: {
                                    color: "#000000FF",
                                    line: false,
                                    lineFormatType: "None",
                                    lineStyle: "Solid",
                                    weight: 0.5,
                                },
                                lockAnchor: false,
                                name: "Logo Text Box",
                                shapeId: 1004,
                                textFrame: {
                                    blocks: [
                                        {
                                            characterFormat: {
                                                bold: true,
                                                boldBidi: true,
                                                fontColor: "#50576BFF",
                                                fontFamily: "Gothic A1",
                                                fontFamilyAscii: "Gothic A1",
                                                fontFamilyBidi: "Gothic A1",
                                                fontFamilyFarEast: "Gothic A1",
                                                fontFamilyNonFarEast: "Gothic A1",
                                                fontSize: 42,
                                                fontSizeBidi: 42,
                                            },
                                            inlines: [
                                                //========= LOGO IMAGE BLOCK ============ //
                                                {
                                                    characterFormat: {},
                                                    imageString: images.orgLogo,
                                                    isMetaFile: false,
                                                    width: finalLogoWidth,
                                                    height: finalLogoHeight,
                                                    name: "2",
                                                    visible: true,
                                                    widthScale: 43.636364,
                                                    verticalPosition: 0,
                                                    verticalOrigin: "Margin",
                                                    verticalAlignment: "None",
                                                    horizontalPosition: 0,
                                                    horizontalOrigin: "Margin",
                                                    horizontalAlignment: "None",
                                                    allowOverlap: true,
                                                    textWrappingStyle: "Inline",
                                                    textWrappingType: "Both",
                                                    distanceBottom: 0,
                                                    distanceLeft: 9,
                                                    distanceRight: 9,
                                                    distanceTop: 0,
                                                    layoutInCell: true,
                                                    zOrderPosition: 251682576,
                                                    isCompressed: false,
                                                },
                                                //========= LOGO IMAGE BLOCK ============ //
                                            ],
                                            paragraphFormat: {
                                                beforeSpacing: 7,
                                                borders: {
                                                    bottom: {},
                                                    horizontal: {},
                                                    left: {},
                                                    right: {},
                                                    top: {},
                                                    vertical: {},
                                                },
                                                lineSpacing: 0.800000011920929,
                                                lineSpacingType: "Multiple",
                                                listFormat: {},
                                                styleName: "Normal",
                                            },
                                        },
                                    ],
                                    textVerticalAlignment: "Top",
                                    leftMargin: 0.00024000000000000003,
                                    rightMargin: 0.00024000000000000003,
                                    topMargin: 0.00012000000000000002,
                                    bottomMargin: 0.00012000000000000002,
                                },
                                title: null,
                                verticalAlignment: "None",
                                verticalOrigin: "Paragraph",
                                verticalPosition: 25.0,
                                visible: true,
                                width: 337.93542,
                                widthScale: 100,
                                zOrderPosition: 251682576,
                            },
                        ]
                        : []),
                    {
                        characterFormat: {},
                        shapeId: 1001,
                        name: "Text Box 1",
                        visible: true,
                        width: 337.93542,
                        height: 147.48393000000002,
                        widthScale: 100,
                        heightScale: 100,
                        verticalPosition: verticalHeadingTextPosition || 320,
                        verticalOrigin: "Paragraph",
                        verticalAlignment: "None",
                        horizontalPosition: 20.32,
                        horizontalOrigin: "Column",
                        horizontalAlignment: "None",
                        zOrderPosition: 251668480,
                        allowOverlap: true,
                        textWrappingStyle: "InFrontOfText",
                        textWrappingType: "Both",
                        layoutInCell: true,
                        lockAnchor: false,
                        autoShapeType: "Rectangle",
                        lineFormat: {
                            lineFormatType: "None",
                            color: "#000000FF",
                            weight: 0.5,
                            lineStyle: "Solid",
                        },
                        textFrame: {
                            textVerticalAlignment: "Top",
                            leftMargin: 0.00024000000000000003,
                            rightMargin: 0.00024000000000000003,
                            topMargin: 0.00012000000000000002,
                            bottomMargin: 0.00012000000000000002,
                            blocks: [
                                {
                                    paragraphFormat: {
                                        beforeSpacing: 7,
                                        lineSpacing: 0.800000011920929,
                                        lineSpacingType: "Multiple",
                                        listFormat: {},
                                    },
                                    characterFormat: {
                                        bold: true,
                                        fontSize: 42,
                                        fontFamily: "Gothic A1",
                                        fontColor: "#50576BFF",
                                        boldBidi: true,
                                        fontSizeBidi: 42,
                                        fontFamilyBidi: "Gothic A1",
                                    },
                                    inlines: [
                                        {
                                            characterFormat: {
                                                bold: true,
                                                fontSize: reportTitleFontSize || 42,
                                                fontFamily: "Gothic A1",
                                                fontColor: "#50576BFF",
                                                boldBidi: true,
                                                fontSizeBidi: reportTitleFontSize || 42,
                                                fontFamilyBidi: "Gothic A1",
                                            },
                                            text: reportTitle || "Cybersecurity Report",
                                        },
                                    ],
                                },
                            ],
                        },
                    },
                    {
                        characterFormat: {},
                        shapeId: 1002,
                        name: "Text Box 9",
                        visible: true,
                        width: 570.19354,
                        height: 70.25,
                        widthScale: 100,
                        heightScale: 100,
                        verticalPosition: 439.55,
                        verticalOrigin: "Paragraph",
                        verticalAlignment: "None",
                        horizontalPosition: 21.48,
                        horizontalOrigin: "Column",
                        horizontalAlignment: "None",
                        zOrderPosition: 251670528,
                        allowOverlap: true,
                        textWrappingStyle: "InFrontOfText",
                        textWrappingType: "Both",
                        layoutInCell: true,
                        lockAnchor: false,
                        autoShapeType: "Rectangle",
                        lineFormat: {
                            lineFormatType: "None",
                            color: "#000000FF",
                            weight: 0.5,
                            lineStyle: "Solid",
                        },
                        textFrame: {
                            textVerticalAlignment: "Bottom",
                            leftMargin: 5.4,
                            rightMargin: 5.4,
                            topMargin: 2.7,
                            bottomMargin: 2.7,
                            blocks: [
                                {
                                    paragraphFormat: {
                                        styleName: "Normal",
                                        listFormat: {},
                                    },
                                    characterFormat: {
                                        fontFamily: "Gothic A1",
                                        fontColor: "#50576BFF",
                                        fontFamilyBidi: "Gothic A1",
                                    },
                                    inlines: [
                                        {
                                            characterFormat: {
                                                fontFamily: "Gothic A1",
                                                fontColor: "#50576BFF",
                                                fontFamilyBidi: "Gothic A1",
                                            },
                                            text: "Exclusively prepared for:",
                                        },
                                    ],
                                },
                                {
                                    paragraphFormat: {
                                        styleName: "Normal",
                                        listFormat: {},
                                    },
                                    characterFormat: {
                                        fontSize: 18,
                                        fontFamily: "Gothic A1",
                                        fontColor: "#50576BFF",
                                        fontSizeBidi: 18,
                                        fontFamilyBidi: "Gothic A1",
                                    },
                                    inlines: [
                                        {
                                            characterFormat: {
                                                fontSize: 30,
                                                fontFamily: "Gothic A1",
                                                fontColor: "#50576BFF",
                                                fontSizeBidi: 30,
                                                fontFamilyBidi: "Gothic A1",
                                            },
                                            text: organizationName || "Bank Name",
                                        },
                                    ],
                                },
                                {
                                    paragraphFormat: {
                                        styleName: "Normal",
                                        listFormat: {},
                                    },
                                    characterFormat: {
                                        fontSize: 30,
                                        fontFamily: "Gothic A1",
                                        fontColor: "#50576BFF",
                                        fontSizeBidi: 30,
                                        fontFamilyBidi: "Gothic A1",
                                    },
                                    inlines: [],
                                },
                            ],
                        },
                    },
                    {
                        characterFormat: {},
                        shapeId: 1003,
                        name: "Text Box 10",
                        visible: true,
                        width: 357.09677,
                        height: 55.74189,
                        widthScale: 100,
                        heightScale: 100,
                        verticalPosition: 512.13,
                        verticalOrigin: "Paragraph",
                        verticalAlignment: "None",
                        horizontalPosition: 22.06,
                        horizontalOrigin: "Column",
                        horizontalAlignment: "None",
                        zOrderPosition: 251692576,
                        allowOverlap: true,
                        textWrappingStyle: "InFrontOfText",
                        textWrappingType: "Both",
                        layoutInCell: true,
                        lockAnchor: false,
                        autoShapeType: "Rectangle",
                        lineFormat: {
                            lineFormatType: "None",
                            color: "#000000FF",
                            weight: 0.5,
                            lineStyle: "Solid",
                        },
                        textFrame: {
                            textVerticalAlignment: "Bottom",
                            leftMargin: 5.4,
                            rightMargin: 5.4,
                            topMargin: 2.7,
                            bottomMargin: 2.7,
                            blocks: [
                                {
                                    paragraphFormat: {
                                        styleName: "Normal",
                                        listFormat: {},
                                    },
                                    characterFormat: {
                                        fontSize: 18,
                                        fontFamily: "Gothic A1",
                                        fontColor: "#50576BFF",
                                        fontSizeBidi: 18,
                                        fontFamilyBidi: "Gothic A1",
                                    },
                                    inlines: [
                                        {
                                            characterFormat: {
                                                fontSize: 18,
                                                fontFamily: "Gothic A1",
                                                fontColor: "#50576BFF",
                                                fontSizeBidi: 18,
                                                fontFamilyBidi: "Gothic A1",
                                            },
                                            text: currentDateString || "Month DD, YYYY",
                                        },
                                    ],
                                },
                                {
                                    paragraphFormat: {
                                        styleName: "Normal",
                                        listFormat: {},
                                    },
                                    characterFormat: {
                                        fontSize: 30,
                                        fontFamily: "Gothic A1",
                                        fontColor: "#50576BFF",
                                        fontSizeBidi: 30,
                                        fontFamilyBidi: "Gothic A1",
                                    },
                                    inlines: [],
                                },
                            ],
                        },
                    },
                    {
                        allowOverlap: true,
                        textWrappingStyle: "InFrontOfText",
                        textWrappingType: "Both",
                        autoShapeType: "Rectangle",
                        fillFormat: {
                            color: "#FFFFFFFF",
                            fill: false,
                        },
                        characterFormat: {},
                        distanceBottom: 0,
                        distanceLeft: 0,
                        distanceRight: 0,
                        distanceTop: 0,
                        height: 75.21,
                        heightScale: 100,
                        horizontalAlignment: "None",
                        horizontalOrigin: "Column",
                        horizontalPosition: 343.6,
                        layoutInCell: true,
                        lineFormat: {
                            color: "#000000FF",
                            line: false,
                            lineFormatType: "None",
                            lineStyle: "Solid",
                        },
                        lockAnchor: false,
                        name: "Text Box 11",
                        shapeId: 1005,
                        textFrame: {
                            blocks: [
                                {
                                    characterFormat: {
                                        fontSize: 18,
                                        fontFamily: "Gothic A1",
                                        fontColor: "#FFFFFFFF",
                                        fontSizeBidi: 18,
                                        fontFamilyBidi: "Gothic A1",
                                        fontFamilyAscii: "Gothic A1",
                                        fontFamilyFarEast: "Gothic A1",
                                        fontFamilyNonFarEast: "Gothic A1",
                                    },
                                    inlines: [
                                        {
                                            characterFormat: {
                                                fontSize: 18,
                                                fontFamily: "Gothic A1",
                                                fontColor: "#FFFFFFFF",
                                                fontSizeBidi: 18,
                                                fontFamilyBidi: "Gothic A1",
                                                fontFamilyAscii: "Gothic A1",
                                                fontFamilyFarEast: "Gothic A1",
                                                fontFamilyNonFarEast: "Gothic A1",
                                            },
                                            text: "SECURED",
                                        },
                                    ],
                                    paragraphFormat: {
                                        borders: {
                                            bottom: {},
                                            horizontal: {},
                                            left: {},
                                            right: {},
                                            top: {},
                                            vertical: {},
                                        },
                                        listFormat: {},
                                        styleName: "Normal",
                                    },
                                },
                            ],
                            bottomMargin: 0,
                            leftMargin: 0,
                            rightMargin: 0,
                            textVerticalAlignment: "Middle",
                            topMargin: 30, //Return to 0 when centring text in text boxes starts working
                        },
                        verticalAlignment: "None",
                        verticalOrigin: "Paragraph",
                        verticalPosition: 606.45,
                        visible: true,
                        width: 85.1,
                        widthScale: 100,
                        zOrderPosition: 251632576,
                        weight: 0.5,
                    },
                    // ======================== COVER IMAGE BLOCK ==============================
                    {
                        characterFormat: {},
                        imageString: coverPageImage,
                        isMetaFile: false,
                        width: 612,
                        height: 792,
                        name: "1",
                        visible: true,
                        widthScale: 100,
                        heightScale: 100,
                        verticalPosition: 0,
                        verticalOrigin: "Margin",
                        verticalAlignment: "None",
                        horizontalPosition: 0,
                        horizontalOrigin: "Margin",
                        horizontalAlignment: "None",
                        allowOverlap: true,
                        textWrappingStyle: "Inline",
                        textWrappingType: "Both",
                        distanceBottom: 0,
                        distanceLeft: 9,
                        distanceRight: 9,
                        distanceTop: 0,
                        layoutInCell: true,
                        zOrderPosition: 2147483647,
                    },
                    // ======================== COVER IMAGE BLOCK ==============================
                ],
                paragraphFormat: {
                    borders: {
                        bottom: {},
                        horizontal: {},
                        left: {},
                        right: {},
                        top: {},
                        vertical: {},
                    },
                    listFormat: {},
                    styleName: "Normal",
                },
            },
        ],
    };
};
exports.generateCoverPage = generateCoverPage;
