import type { ReactElement } from "react";

import cx from "classnames";
import { useTranslation } from "react-i18next";

import IconHelp from "@assets/svg/functional/icon-help.svg";
import Text, { TextVariant } from "@components/Atoms/Text/Text";
import Tooltip from "@components/Atoms/Tooltip/Tooltip";
import { TooltipIcon, TooltipSizeVariant } from "@components/Atoms/Tooltip/TooltipIcon";
import { TextColor } from "@components/Ions/colors/constants/Color";
import { Card } from "@components/Molecules/Card/Card";
import GridCardContent from "@components/Molecules/Card/GridCard/GridCardContent";
import DropdownMenu from "@components/Molecules/DropdownMenu/DropdownMenu";
import useEllipsisDetection from "@hooks/functional/useEllipsisDetection/useEllipsisDetection";

import styles from "./GridCard.module.scss";

import type { ListItemProps } from "@components/Atoms/ListItem/ListItem";
import type { CardProps } from "@components/Molecules/Card/Card";

export enum GridCardSize {
  SMALL = "small",
  LARGE = "large",
  EXPAND = "expand",
}

export interface GridCardProps extends Omit<CardProps<"article">, "as"> {
  /** The value to display at the top of the grid card. */
  title?: string;
  /** The text to display when hovering over the help icon. */
  helpText?: string;
  /** Whether data is still be retrieved to display.  Shows the spinner icon if `true`. */
  isLoading?: boolean;
  /** Whether there is data to be display in the grid card.  Used to control displaying the "No Data" icon. */
  hasData: boolean;
  /** Set to `true` when the user hasn't purchased the required module to view this data. */
  moduleNotPurchased?: boolean;
  /** Set to `true` when the user doesn't have permission to access this data. */
  accessRestricted?: boolean;
  /** Affects the default min and max width of the card. */
  size: GridCardSize;
  /** Set to `true` when needing to show a horizontal line between the title and the body of the card. */
  showTopSeparator?: boolean;
  /** Set to `true` when needing to show a horizontal line between the body and the footer of the card. */
  showBottomSeparator?: boolean;
  /** Optional content to render in the footer section of the card. */
  footer?: ReactElement;
  /** Optional menu items to show in dropdown when clicking more icon. */
  menuItems?: ListItemProps[];
}

/**
 * A generic card component used to display widgets and data visualizations within a grid layout. Provides a consistent
 * container with a title, optional help text and menu items, while handling loading and empty states gracefully.
 */
const GridCard = ({
  title,
  helpText,
  isLoading,
  hasData,
  moduleNotPurchased,
  accessRestricted,
  size,
  showTopSeparator,
  showBottomSeparator,
  footer,
  menuItems,
  children,
  className,
  ...rest
}: GridCardProps): ReactElement => {
  const { t } = useTranslation();
  const { elementRef: titleRef, isEllipsisActive } = useEllipsisDetection<HTMLSpanElement>({
    content: title,
  });

  const cardProps = accessRestricted === true || moduleNotPurchased === true ? { ...rest, onClick: undefined } : rest;

  const shouldShowFooter = hasData && !isLoading && !moduleNotPurchased && !accessRestricted && footer;
  const cardClassName = cx(styles["grid-card"], {
    [styles["grid-card--small"]!]: size === GridCardSize.SMALL,
    [styles["grid-card--large"]!]: size === GridCardSize.LARGE,
    [styles["grid-card--expand"]!]: size === GridCardSize.EXPAND,
  });

  const titleClassName = cx(styles["grid-card__title"], {
    [styles["grid-card__title--centered"]!]: size === GridCardSize.SMALL,
    [styles["grid-card__title"]!]: size === GridCardSize.LARGE,
  });

  const titleContainerClassName = cx(styles["grid-card__title-container"], {
    [styles["grid-card__title-container--centered"]!]: !menuItems && size === GridCardSize.SMALL,
    [styles["grid-card__title-container--with-separator"]!]: showTopSeparator,
    [styles["grid-card__title-container--hidden"]!]: !title,
  });

  const footerClassName = cx(styles["grid-card__footer"], {
    [styles["grid-card__footer--with-separator"]!]: showBottomSeparator,
  });

  return (
    <Card aria-label={title} className={cx(cardClassName, className)} {...cardProps}>
      <div className={titleContainerClassName}>
        <div className={styles["grid-card__title-container-left"]}>
          <Tooltip content={title} disabled={!isEllipsisActive}>
            <Text
              className={titleClassName}
              color={TextColor.LIGHT_PRIMARY}
              htmlElement="span"
              variant={TextVariant.SUBTITLE2}
              ref={titleRef}
            >
              {title}
            </Text>
          </Tooltip>

          {helpText && (
            <TooltipIcon content={helpText} size={TooltipSizeVariant.LARGE}>
              <IconHelp aria-label={t("show more")} />
            </TooltipIcon>
          )}
        </div>

        {menuItems && menuItems.length > 0 && (
          <DropdownMenu menuItems={menuItems} className={styles["grid-card__dropdown-menu"]} />
        )}
      </div>

      <GridCardContent
        isLoading={isLoading}
        hasData={hasData}
        moduleNotPurchased={moduleNotPurchased}
        accessRestricted={accessRestricted}
      >
        {children}
      </GridCardContent>

      {shouldShowFooter ? <div className={footerClassName}>{footer}</div> : null}
    </Card>
  );
};

export default GridCard;
