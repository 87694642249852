import { convertCamelCaseToSentence, isNullOrUndefined } from "@rivial-security/func-utils";
import { generateGraphql } from "@rivial-security/generategraphql";
import moment from "moment/moment";
import { useContext, useEffect, useState } from "react";

import { useForm } from "@hooks/views/useForm";
import { FORM_INPUT_TYPES } from "@hooks/views/useForm/enums/FORM_INPUT_TYPES";
import { OrganizationContext } from "@utils/Context/OrganizationContext";
import { useOrganizationConfig } from "@views/AdminPanel/Organizations/hooks/useOrganizationConfig";

import useListQuery from "../../../../hooks/graphql/useListQuery";
import { DOCUMENT_EXPORT_BY, fetchDocumentsBy } from "../functions/fetchDocumentsBy";

/**
 * A custom hook for retrieving a nested array of Document objects.
 * Also includes a dropdown form to select various ways to group the document data
 * @param {string} organizationID - selected organization
 * @param {DOCUMENT_EXPORT_BY} groupByInit - value to filter by
 * @param {string} controlFrameworkID - initial control framework ID for controls sorting
 * @param {number} maxIntervalInDays - maximum interval in days for the date range
 */
export const useFetchDocumentsBy = ({
  organizationID,
  groupByInit = DOCUMENT_EXPORT_BY.EVIDENCE,
  controlFrameworkID,
  maxIntervalInDays,
}) => {
  const { value: includeDisabledControlsInExports } = useOrganizationConfig({
    organizationID,
    key: "includeDisabledControlsInExports",
  });

  const [items, setItems] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [groupBy, setGroupBy] = useState(groupByInit);
  const [controlSetId, setControlSetId] = useState(controlFrameworkID || "");

  const { selectedOrganizationObjectMinimal: organization } = useContext(OrganizationContext);

  /**
   * Default root name for the export folder
   */
  const defaultRootName = `Evidence Artifacts by ${convertCamelCaseToSentence(
    groupBy,
  )} (${new Date().toLocaleDateString()} ${organization?.name})`.replace(/\//g, "-");

  const { listQuery } = generateGraphql("ControlSet", ["name"]);
  const controlSets = useListQuery({
    query: listQuery,
    organizationID,
    disableRoleChecking: true,
  });

  const form = useForm({
    id: "compliance-documents-groupby",
    fieldConfig: {
      sortBy: {
        label: "Group By:",
        inputType: FORM_INPUT_TYPES.DROPDOWN,
        defaultValue: groupByInit,
        onChangeFunction: (e) => setGroupBy(e.sortBy),
        dropdownConfig: {
          data: Object.entries(DOCUMENT_EXPORT_BY).map(([_key, value]) => {
            return {
              value: value,
              text: convertCamelCaseToSentence(value),
            };
          }),
        },
      },
      ...(groupBy === DOCUMENT_EXPORT_BY.CONTROL && {
        controlControlSetId: {
          inputType: FORM_INPUT_TYPES.DROPDOWN,
          label: "Control Framework",
          onChangeFunction: (e) => setControlSetId(e.controlControlSetId),
          defaultValue: controlSetId,
          dropdownConfig: {
            data: controlSets?.list.map(({ id, name }) => {
              return {
                value: id,
                text: name,
              };
            }),
          },
        },
      }),
      dateRange: {
        inputType: FORM_INPUT_TYPES.DATE_RANGE,
        label: "Select Date Range:",
        defaultValue:
          !isNullOrUndefined(maxIntervalInDays) && maxIntervalInDays < 365
            ? [null, null]
            : [moment().startOf("year"), moment().endOf("year")],
        dateRangeConfig: {
          maxIntervalInDays,
        },
      },
    },
    disableRoleChecking: true,
    disableResetButton: true,
    disableSubmitButton: true,
  });

  useEffect(() => {
    setIsLoading(true);
    const startDate = form?.input?.dateRange?.[0]?.toDate();
    const endDate = form?.input?.dateRange?.[1]?.toDate();
    fetchDocumentsBy({
      organizationID,
      groupBy,
      controlSetId,
      includeDisabledControlsInExports,
      rootName: defaultRootName,
      dateRange: {
        startDate,
        endDate,
      },
    })
      .then((items) => {
        if (Object.keys(items).length > 0) {
          setItems({ ...items });
        }
      })
      .finally(() => setIsLoading(false));
  }, [groupBy, controlSetId, organizationID, form?.input?.dateRange]);

  return {
    form,
    groupBy,
    setGroupBy,
    items,
    setItems,
    isLoading,
    controlSetId,
    setControlSetId,
    defaultRootName,
  };
};
