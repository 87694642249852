import { formatDistance, isBefore } from "date-fns";

import { ErrorLogger } from "@utils/EventLogger";
import { changeTargetLinkStatus } from "@views/Testing/Vulnerabilities/functions/changeTargetLinkStatusOperations/changeTargetLinkStatus";

import { GetQuery, ItemMutation } from "@rivial-security/appsync-utils";
import { convertCamelCaseToSentence } from "@rivial-security/func-utils";
import { generateGraphql } from "@rivial-security/generategraphql";
import type { GenericActionItem, TargetFindingLink } from "@rivial-security/schema-types";
import { ActionItemStatus, TargetVulnerabilityStatus } from "@rivial-security/schema-types";

interface UseStatusFieldResult {
  /** Applies special string formatting to the status field */
  customFormat: (status: ActionItemStatus) => string;
  /** Mutates the status of the action item */
  mutationFunction: (status: ActionItemStatus) => Promise<void>;
  /** Confirms if user wants to send an email when status is changed to `IN_PROGRESS` or `PAST_DUE` */
  sendEmailConfirmation: (input: ActionItemStatus) => boolean;
}

/**
 * Handles the business logic for updating the status of an action item
 * @returns An object containing functions for formatting the status, mutating the status, and confirming if user wants to send an email
 *
 * @example
 * const { customFormat, mutationFunction, sendEmailConfirmation } = useStatusField(item, resetFunction);
 */
export const useStatusField = (item: GenericActionItem, resetFunction?: VoidFunction): UseStatusFieldResult => {
  const { getQuery: getTargetFindingLink } = generateGraphql("TargetFindingLink", ["id", "status"]);
  const { updateMutation: updateActionItemMutation } = generateGraphql("GenericActionItem");

  const customFormat = (status: ActionItemStatus): string => {
    const statusString = convertCamelCaseToSentence(status ?? "Unknown Status");
    let pastDueDateString = "";
    if (status === ActionItemStatus.PAST_DUE && item.dueDate && isBefore(new Date(item.dueDate), new Date())) {
      pastDueDateString = ` (${formatDistance(new Date(item.dueDate), new Date())})`;
    }

    let completedDateString = "";
    if (status === ActionItemStatus.COMPLETE && item.completionDate) {
      completedDateString = ` (${new Date(item.completionDate).toLocaleDateString()})`;
    }
    return statusString + pastDueDateString + completedDateString;
  };

  const mutationFunction = async (status: ActionItemStatus): Promise<void> => {
    try {
      await ItemMutation({
        mutation: updateActionItemMutation,
        input: {
          id: item.id,
          status,
          ...(status === ActionItemStatus.COMPLETE && { completionDate: new Date() }),
          ...(status === ActionItemStatus.IN_PROGRESS && { startDate: new Date(), completionDate: null }),
        },
      });

      const targetVulnerabilityLinkID = item?.targetVulnerabilityLinkID;
      if (targetVulnerabilityLinkID) {
        const targetVulnerabilityLink = await GetQuery<TargetFindingLink>({
          query: getTargetFindingLink,
          variables: { id: targetVulnerabilityLinkID },
        });
        const newVulnerabilityTargetLinkStatus =
          status === ActionItemStatus.COMPLETE ? TargetVulnerabilityStatus.FIXED : TargetVulnerabilityStatus.NOT_FIXED;
        await changeTargetLinkStatus({
          oldItem: {
            status: targetVulnerabilityLink?.status,
            targetFindingLinkID: targetVulnerabilityLinkID,
            ownerGroup: item.ownerGroup,
          },
          newItem: {
            status: newVulnerabilityTargetLinkStatus,
            ownerGroup: item.ownerGroup,
          },
        });
      }
    } catch (error) {
      ErrorLogger("Unable to update status:", error);
    } finally {
      resetFunction?.();
    }
  };

  const sendEmailConfirmation = (input: ActionItemStatus): boolean => {
    if (input === ActionItemStatus.IN_PROGRESS || input === ActionItemStatus.PAST_DUE) {
      return window.confirm(`This status change will send an email to the point of contact. Do you want to continue?`);
    } else {
      return true;
    }
  };

  return {
    customFormat,
    mutationFunction,
    sendEmailConfirmation,
  };
};
