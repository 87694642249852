import type { ComponentPropsWithoutRef, ReactElement, ReactNode } from "react";
import { forwardRef } from "react";

import cx from "classnames";

import Spinner from "@components/Ions/motion/components/Spinner";

import styles from "./Button.module.scss";

export interface ButtonProps extends ComponentPropsWithoutRef<"button"> {
  /** The variant of the button (example: `primary` or `link`). */
  variant?: ButtonVariant;
  /** The SVG icon to display on the left side of the text.  Set this prop for buttons that should only display an icon without text. */
  startIcon?: ReactNode;
  /** The SVG icon to display on the right side of the text. */
  endIcon?: ReactNode;
  /** When true, the button will render with a spinner and block calling the `onClick` event handler function. */
  isLoading?: boolean;
}

export enum ButtonVariant {
  PRIMARY = "primary",
  SECONDARY = "secondary",
  TEXT = "text",
  LINK = "link",
  ALERT = "alert",
}

/**
 * A button component is an interactive UI element used to trigger actions or navigate within an application,
 * providing a clear and intuitive way for users to interact with the system.
 */
const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    { children, disabled, variant = ButtonVariant.PRIMARY, startIcon, endIcon, isLoading = false, className, ...rest },
    ref,
  ): ReactElement => {
    const classNames = cx(
      styles[variant],
      styles["button-contents-grid"],
      {
        [styles["icon-with-text-padding"]!]:
          children && [ButtonVariant.PRIMARY, ButtonVariant.SECONDARY, ButtonVariant.ALERT].includes(variant),
      },
      { [styles["button-icon"]!]: startIcon ?? endIcon },
      { [styles["button-text"]!]: !(startIcon ?? endIcon) },
      { [styles.loading!]: isLoading },
      className,
    );

    if (isLoading) {
      if (startIcon) {
        startIcon = <Spinner />;
      } else if (endIcon) {
        endIcon = <Spinner />;
      } else {
        children = (
          <div className={styles["icon-only-spinner-container"]}>
            <div className={styles.hidden}>{children}</div>
            <div className={styles["icon-only-spinner"]}>
              <Spinner />
            </div>
          </div>
        );
      }
    }

    return (
      <button
        ref={ref}
        className={classNames}
        disabled={disabled ?? isLoading}
        aria-disabled={disabled ?? isLoading}
        aria-busy={isLoading}
        {...rest}
      >
        {startIcon}
        {children}
        {endIcon}
      </button>
    );
  },
);

Button.displayName = "Button";

export default Button;
