import React from "react";

import { useAccordion } from "../../../../../hooks/views/useAccordion/useAccordion";

import ArtifactComments from "./ArtifactComments";
import ArtifactEvidence from "./ArtifactEvidence";
import ArtifactStatus from "./ArtifactStatus";

/**
 * Component allowing users to determine whether a particular artifact satisfies the evidence requirements
 * @param {string} organizationID - the currently selected organization ID
 * @param {object} artifact - full artifact information from the database, including attached evidence through evidence activity
 * @param {object} detailsHook - hook for the artifact details panel, used to
 * @param {function} onLabelClick - callback function to handle click on a label
 * @returns {JSX.Element}
 */
const ValidationPanel = ({ organizationID, artifact, detailsHook }) => {
  const accordion = useAccordion({
    details: { artifact },
    items: [
      {
        icon: "icon-docs",
        title: "Artifact",
        component: <div>{detailsHook.display}</div>,
        id: "artifact-details-accordion",
        defaultExpanded: true,
      },
      {
        icon: "icon-badge",
        title: "Evidence",
        component: <ArtifactEvidence />,
        id: "artifact-labels-accordion",
      },
      {
        icon: "icon-like",
        title: "Validation",
        component: <ArtifactStatus organizationID={organizationID} resetFunction={detailsHook?.resetFunction} />,
        id: "artifact-status-accordion",
        defaultExpanded: true,
      },
    ],
  });

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      {accordion.display}
      <div style={{ height: "1em" }} />
      <ArtifactComments organizationID={organizationID} artifact={artifact} />
    </div>
  );
};

export default ValidationPanel;
