import { ModuleType } from "@graphql/types/graphql";
import { HelpCenterRoute } from "@utils/types/helpCenterRoutes";

// NOTE: using kebab case for values since these show up in the URL
export enum OnboardingTour {
  TEMPLATE_BLUEPRINT = "template-blueprint",
  CREATE_POLICY = "create-policy",
  CREATE_FINDINGS = "create-findings",
  CREATE_MEETING = "create-meeting",
  SET_UP_LOSS_TOLERANCE = "set-up-loss-tolerance",
  CREATE_SYSTEM = "create-system",
  CREATE_RISK_CHANGE = "create-risk-change",
  CREATE_RISK_RECOMMENDATION = "create-risk-recommendation",
  GENERATE_RISK_ASSESSMENT_REPORT = "generate-risk-assessment-report",
  CREATE_CONTROL_FRAMEWORK = "create-control-framework",
  ASSIGN_EVIDENCE_OWNERS = "assign-evidence-owners",
  ENABLE_EVIDENCE_COLLECTION = "enable-evidence-collection",
  REVIEW_EVIDENCE_ARTIFACT = "review-evidence-artifact",
  GENERATE_COMPLIANCE_REPORT = "generate-compliance-report",
  CREATE_VULNERABILITY_ASSESSMENT = "create-vulnerability-assessment",
  ASSIGN_VULNERABILITIES_TO_CONTACTS = "assign-vulnerabilities-to-contacts",
  MARK_VULNERABILITY_AS_FALSE_POSITIVE = "mark-vulnerability-as-false-positive",
  GENERATE_VULNERABILITY_ASSESSMENT_REPORT = "generate-vulnerability-assessment-report",
  CREATE_RESPONSE_PLAN = "create-response-plan",
  CREATE_PLAYBOOK = "create-playbook",
  CREATE_EXERCISE = "create-exercise",
  CREATE_VENDOR = "create-vendor",
  CREATE_VENDOR_REVIEW = "create-vendor-review",
  GENERATE_EXECUTIVE_SUMMARY_REPORT = "generate-executive-summary-report",
}

export interface FrontendOnboardingTask {
  name: string;
  helpCenterRoute: HelpCenterRoute;
  platformRoute: string;
  module: ModuleType;
  onboardingTourId: OnboardingTour;
  onboardingTourUrl: string;
}

export const onboardingTasks: FrontendOnboardingTask[] = [
  {
    name: "fill out your cybersecurity blueprint",
    helpCenterRoute: HelpCenterRoute.Blueprint,
    platformRoute: "/governance/blueprints",
    module: ModuleType.Governance,
    onboardingTourId: OnboardingTour.TEMPLATE_BLUEPRINT,
    onboardingTourUrl:
      "https://scribehow.com/embed/Creating_a_New_Program_Blueprint_in_Rivial_Security__QZ5pcYvpTf63qz5lLbpbAQ",
  },
  {
    name: "import information security policies",
    helpCenterRoute: HelpCenterRoute.Policies,
    platformRoute: "/governance/policies",
    module: ModuleType.Governance,
    onboardingTourId: OnboardingTour.CREATE_POLICY,
    onboardingTourUrl: "https://scribehow.com/embed/Import_Information_Security_Policies__8jz-yy4JS1SJOqqBnrHjLw",
  },
  {
    name: "import outstanding findings",
    helpCenterRoute: HelpCenterRoute.FindingsImporter,
    platformRoute: "/governance/findings_dashboard",
    module: ModuleType.Governance,
    onboardingTourId: OnboardingTour.CREATE_FINDINGS,
    onboardingTourUrl: "https://scribehow.com/embed/Import_Outstanding_Findings___jne3S30Q7a6iUbK6YE6vw",
  },
  {
    name: "create a meeting and send out minutes",
    helpCenterRoute: HelpCenterRoute.ScheduleNewMeeting,
    platformRoute: "/governance/meetings",
    module: ModuleType.Governance,
    onboardingTourId: OnboardingTour.CREATE_MEETING,
    onboardingTourUrl: "https://scribehow.com/embed/Create_a_meeting_and_send_out_minutes__WvnvKmSzQvijGwSfOPVMGQ",
  },
  {
    name: "set up the loss tolerance curve",
    helpCenterRoute: HelpCenterRoute.LossTolerance,
    platformRoute: "/risk/risk_config",
    module: ModuleType.Risk,
    onboardingTourId: OnboardingTour.SET_UP_LOSS_TOLERANCE,
    onboardingTourUrl: "https://scribehow.com/embed/Set_up_the_Loss_Tolerance_Curve__GxBzfY5jRq2HRYzwFhqGfw",
  },
  {
    name: "add and assess a system",
    helpCenterRoute: HelpCenterRoute.InformationSystems,
    platformRoute: "/risk/systems",
    module: ModuleType.Risk,
    onboardingTourId: OnboardingTour.CREATE_SYSTEM,
    onboardingTourUrl: "https://scribehow.com/embed/Add_and_assess_a_system__l1CRmY8KR-WaA3xCf-V5uw",
  },
  {
    name: "add a risk change to the risk change register",
    helpCenterRoute: HelpCenterRoute.RiskChanges,
    platformRoute: "/risk/changes",
    module: ModuleType.Risk,
    onboardingTourId: OnboardingTour.CREATE_RISK_CHANGE,
    onboardingTourUrl:
      "https://scribehow.com/embed/Add_a_Risk_Change_to_the_Risk_Change_Register__BNtl108_TWu6P9s4-Ao8UQ",
  },
  {
    name: "add a risk recommendation",
    helpCenterRoute: HelpCenterRoute.CreateRiskRecommendation,
    platformRoute: "/risk/recommendations",
    module: ModuleType.Risk,
    onboardingTourId: OnboardingTour.CREATE_RISK_RECOMMENDATION,
    onboardingTourUrl: "https://scribehow.com/embed/Add_a_Risk_recommendation__CyabtfOCSXiahHeWbFsJ9w",
  },
  {
    name: "generate a risk assessment report",
    helpCenterRoute: HelpCenterRoute.GenerateRiskAssessmentReport,
    platformRoute: "/risk/reports/risk_assessment",
    module: ModuleType.Risk,
    onboardingTourId: OnboardingTour.GENERATE_RISK_ASSESSMENT_REPORT,
    onboardingTourUrl: "https://scribehow.com/embed/Generate_a_Risk_Assessment_Report__PgWzmqCIQhKe2Ifh0EUicA",
  },
  {
    name: "select control frameworks to manage",
    helpCenterRoute: HelpCenterRoute.ControlFrameworks,
    platformRoute: "/continuous_compliance/control_frameworks",
    module: ModuleType.Compliance,
    onboardingTourId: OnboardingTour.CREATE_CONTROL_FRAMEWORK,
    onboardingTourUrl: "https://scribehow.com/embed/Select_Control_Frameworks_to_manage__25od2GbySxCtP1OvmWmgZQ",
  },
  {
    name: "assign evidence to employees",
    helpCenterRoute: HelpCenterRoute.AssignEvidenceOwners,
    platformRoute: "/continuous_compliance/evidence",
    module: ModuleType.Compliance,
    onboardingTourId: OnboardingTour.ASSIGN_EVIDENCE_OWNERS,
    onboardingTourUrl: "https://scribehow.com/embed/Assign_Evidence_to_Employees__XGFX44AGR3WAzSRO8rhhsQ",
  },
  {
    name: "enable evidence collection",
    helpCenterRoute: HelpCenterRoute.EnableDisableAutomaticEvidenceGathering,
    platformRoute: "/continuous_compliance/evidence",
    module: ModuleType.Compliance,
    onboardingTourId: OnboardingTour.ENABLE_EVIDENCE_COLLECTION,
    onboardingTourUrl: "https://scribehow.com/embed/Enable_Evidence_Collection__3d9c_k2hS42DFZnUDHBTlQ",
  },
  {
    name: "review uploaded evidence artifacts",
    helpCenterRoute: HelpCenterRoute.ValidatingEvidence,
    platformRoute: "/continuous_compliance/dashboard",
    module: ModuleType.Compliance,
    onboardingTourId: OnboardingTour.REVIEW_EVIDENCE_ARTIFACT,
    onboardingTourUrl: "https://scribehow.com/embed/Review_Uploaded_Evidence_Artifacts__8kD43cS8RfqB-v1iWR0voA",
  },
  {
    name: "generate a compliance report",
    helpCenterRoute: HelpCenterRoute.ContinuousCompliance,
    platformRoute: "/continuous_compliance/reports/compliance",
    module: ModuleType.Compliance,
    onboardingTourId: OnboardingTour.GENERATE_COMPLIANCE_REPORT,
    onboardingTourUrl:
      "https://scribehow.com/embed/How_to_Create_a_Compliance_Report_in_Rivial__QZx-cRwrTH2M6WBggJZJ9w",
  },
  {
    name: "upload a vulnerability assessment",
    helpCenterRoute: HelpCenterRoute.ImportScanResults,
    platformRoute: "/vulnerabilities/assessments",
    module: ModuleType.Vulnerabilities,
    onboardingTourId: OnboardingTour.CREATE_VULNERABILITY_ASSESSMENT,
    onboardingTourUrl: "https://scribehow.com/embed/How_To_Upload_a_Vulnerability_Assessment__jOmJo6N3T0aETFe3P-jaxg",
  },
  {
    name: "assign vulnerabilities for remediation",
    helpCenterRoute: HelpCenterRoute.ActionItems,
    platformRoute: "/vulnerabilities/vulnerabilities",
    module: ModuleType.Vulnerabilities,
    onboardingTourId: OnboardingTour.ASSIGN_VULNERABILITIES_TO_CONTACTS,
    onboardingTourUrl: "https://scribehow.com/embed/Assign_Vulnerabilities_for_Remediation__-c_mNV-AQ9qFWM4C6C9H6w",
  },
  {
    name: "mark a vulnerability as a false positive",
    helpCenterRoute: HelpCenterRoute.MarkVulnerabilityAsFalsePositive,
    platformRoute: "/vulnerabilities/vulnerabilities",
    module: ModuleType.Vulnerabilities,
    onboardingTourId: OnboardingTour.MARK_VULNERABILITY_AS_FALSE_POSITIVE,
    onboardingTourUrl: "https://scribehow.com/embed/Mark_a_Vulnerability_as_a_False_Positive__pzyR2_eDSI6tmQ5v3i8ZyQ",
  },
  {
    name: "run the vulnerability assessment report",
    helpCenterRoute: HelpCenterRoute.GenerateVulnerabilityAssessmentReport,
    platformRoute: "/vulnerabilities/assessments",
    module: ModuleType.Vulnerabilities,
    onboardingTourId: OnboardingTour.GENERATE_VULNERABILITY_ASSESSMENT_REPORT,
    onboardingTourUrl: "https://scribehow.com/embed/Run_the_Vulnerability_Assessment_Report__h-Z1cDtPS5KoJRKf2M8VvA",
  },
  {
    name: "build an incident response plan",
    helpCenterRoute: HelpCenterRoute.AutoSetupResponsePlan,
    platformRoute: "/response/plan",
    module: ModuleType.IncidentResponse,
    onboardingTourId: OnboardingTour.CREATE_RESPONSE_PLAN,
    onboardingTourUrl: "https://scribehow.com/embed/Build_an_Incident_Response_Plan__CvJoJd3NQZ6h443RDZV_Tg",
  },
  {
    name: "build a custom playbook",
    helpCenterRoute: HelpCenterRoute.CustomPlaybooks,
    platformRoute: "/response/plan",
    module: ModuleType.IncidentResponse,
    onboardingTourId: OnboardingTour.CREATE_PLAYBOOK,
    onboardingTourUrl: "https://scribehow.com/embed/Build_a_Custom_Playbook__72jtDS9lR8eTM1cqBcSl0A",
  },
  {
    name: "perform an incident response table-top exercise",
    helpCenterRoute: HelpCenterRoute.IncidentResponseExercises,
    platformRoute: "/response/exercises",
    module: ModuleType.IncidentResponse,
    onboardingTourId: OnboardingTour.CREATE_EXERCISE,
    onboardingTourUrl:
      "https://scribehow.com/embed/Perform_an_Incident_Response_Table-Top_Exercise__j83o23BbTJau7c8buShLnA",
  },
  {
    name: "add a critical vendor",
    helpCenterRoute: HelpCenterRoute.Vendors,
    platformRoute: "/vendors/vendor",
    module: ModuleType.Vendors,
    onboardingTourId: OnboardingTour.CREATE_VENDOR,
    onboardingTourUrl: "https://scribehow.com/embed/How_To_Create_and_Finalize_Vendor_Reviews__dKG0y9u5TeOkwYBs6Apd6w",
  },
  {
    name: "perform a security review on a vendor",
    helpCenterRoute: HelpCenterRoute.PerformVendorReview,
    platformRoute: "/vendors/reviews",
    module: ModuleType.Vendors,
    onboardingTourId: OnboardingTour.CREATE_VENDOR_REVIEW,
    onboardingTourUrl: "https://scribehow.com/embed/Perform_a_Security_Review_on_a_Vendor__AwwSXzMuTQK-lg2SnHENIA",
  },
  {
    name: "generate the executive summary report",
    helpCenterRoute: HelpCenterRoute.BoardReport,
    platformRoute: "/governance/executive_summary_report",
    module: ModuleType.Governance,
    onboardingTourId: OnboardingTour.GENERATE_EXECUTIVE_SUMMARY_REPORT,
    onboardingTourUrl: "https://scribehow.com/embed/Generate_the_Executive_Summary_Report__KQg9lNhEQI69DdEg_tY5uw",
  },
];
