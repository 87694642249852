import { flattenArrayOfObjects, isNullOrUndefined } from "@rivial-security/func-utils";
import { generateGraphql } from "@rivial-security/generategraphql";
import { useContext, useEffect, useState } from "react";

import { ErrorLogger } from "@utils/EventLogger";

import { UIContext } from "../../../../utils/Context/UIContext";
import { ItemMutation } from "../../../../utils/Functions/Graphql/ItemMutation";
import { ListQuery } from "../../../../utils/Functions/Graphql/ListQuery";
import { QueryWithNextToken } from "../../../../utils/Functions/Graphql/QueryWithNextToken";
import { PasswordGenerator } from "../../../../utils/Functions/PasswordGenerator";
import { useCsvImporter } from "../../../CsvImporters/hooks/useCsvImporter";
import { listRoles_roleList } from "../../Roles/graphql/__roleGQL";
import { createPointOfContact } from "../functions/createPointOfContact";

export const useUploadPointOfContact = ({ organizationID, toggleModal, resetFunction }) => {
  const { addToast, updateToast } = useContext(UIContext);
  const [roleList, setRoleList] = useState([]);
  const [roleNamesList, setRoleNamesList] = useState([]);

  const headerObjects = [
    {
      title: "firstName",
      type: "text",
    },
    {
      title: "lastName",
      type: "text",
    },
    {
      title: "title",
      type: "text",
    },
    {
      title: "email",
      type: "text",
    },
    {
      title: "phone",
      type: "text",
    },
    {
      title: "addNewUser",
      type: "dropdown",
      source: ["true", "false"],
    },
    {
      title: "role",
      type: "dropdown",
      source: roleNamesList,
    },
  ];

  const csvImporter = useCsvImporter({
    headerObjects,
    title: "Import Points of Contact",
    passThroughDuplicateScanner: true,
  });

  useEffect(() => {
    QueryWithNextToken({
      organizationID,
      query: listRoles_roleList,
    }).then((listOfRoles) => {
      setRoleNamesList(flattenArrayOfObjects(listOfRoles, "name"));
      setRoleList(listOfRoles);
      csvImporter.reset();
    });
  }, []);

  const getRoleObject = (name) => {
    if (roleList) {
      const role = roleList.find((item) => item.name === name);
      if (role && role.id) {
        return role;
      }
      return undefined;
    }
  };

  const fields = ["id", "firstName", "lastName", "title", "email", "phone"];
  const { listQuery, updateMutation } = generateGraphql("PointOfContact", fields);

  //Set original data for duplicates scanner
  useEffect(() => {
    csvImporter.initSourceQuery(listQuery, organizationID);
  }, []);

  const getFieldValue = (object, field) => {
    if (!object?.[field]) return undefined;

    if (field === "email") {
      return object[field].trim();
    }

    return object[field];
  };

  const createItemsFromCsv = async (operations) => {
    if (
      operations &&
      operations.add &&
      Array.isArray(operations.add) &&
      operations.update &&
      Array.isArray(operations.update) &&
      operations.delete &&
      Array.isArray(operations.delete)
    ) {
      toggleModal?.();
      const totalOperations = operations.add.length + operations.update.length + operations.delete.length;
      let operationCount = 0;

      const toastId = addToast({
        icon: "spinner",
        header: `Updating Points of Contact from a CSV file ... (0/${totalOperations})`,
      });

      let pointOfContactList = await ListQuery({
        query: listQuery,
        organizationID,
      });

      //perform POC updates
      for (const operationItem of operations.update) {
        updateToast({
          id: toastId,
          icon: "spinner",
          header: `Updating Points of Contact ... (${++operationCount}/${totalOperations})`,
        });

        //Check for all required fields to be present
        if (
          !operationItem.firstName ||
          !operationItem.lastName ||
          operationItem.firstName === "" ||
          operationItem.lastName === ""
        ) {
          ErrorLogger("Update Error: Point of Contact needs a first and last name!");
          continue;
        }

        //Check if item to mutate exists
        const itemFound = pointOfContactList.some(
          (originalItem) => originalItem.id != null && operationItem.id != null && operationItem.id === originalItem.id,
        );

        //TODO: check if contact fields can be updated (no user associated)

        if (itemFound) {
          await ItemMutation(updateMutation, operationItem);
        }
      }

      //TODO: correctly perform POC deletions (by deleting associated links)
      //
      // for (const operationItem of operations.delete) {
      //   updateToast({
      //     id: toastId,
      //     icon: "spinner",
      //     header: `Deleting Duplicate Points of Contact ... (${++operationCount}/${totalOperations})`
      //   });
      //
      //   const foundItem = pointOfContactList.find((originalItem) => {
      //     return (originalItem.id != null &&
      //       operationItem.id != null &&
      //       originalItem.id === operationItem.id);
      //   });
      //
      //   if (foundItem != null) {
      //     await DeleteContact(foundItem);
      //   }
      // }

      //Get the list of contacts a second time to with deleted items reflected
      pointOfContactList = await ListQuery({
        query: listQuery,
        organizationID,
      });

      //perform POC additions
      const creationRequests = [];
      for (const operationItem of operations.add) {
        if (operationItem?.firstName && operationItem?.lastName) {
          const itemFound = pointOfContactList.some((contact) => {
            //Do not create a new point of contact if one with the same email already exists
            if (
              !isNullOrUndefined(contact?.email) &&
              !isNullOrUndefined(operationItem?.email) &&
              contact?.email === operationItem?.email.trim()
            ) {
              return true;
            }

            //Do not create a new point of contact if one with the same full name already exists
            if (contact?.firstName === operationItem?.firstName && contact?.lastName === operationItem?.lastName) {
              return true;
            }
          });

          if (!itemFound) {
            updateToast({
              id: toastId,
              icon: "spinner",
              header: `Creating New Points of Contact... (${++operationCount}/${totalOperations})`,
            });

            // set up input
            const input = {};
            input.firstName = getFieldValue(operationItem, "firstName");
            input.lastName = getFieldValue(operationItem, "lastName");
            input.title = getFieldValue(operationItem, "title");
            input.email = getFieldValue(operationItem, "email");
            input.phoneNumber = getFieldValue(operationItem, "phone");
            input.password = PasswordGenerator();
            input.organizationID = organizationID;
            input.addNewUser = getFieldValue(operationItem, "addNewUser")
              ? getFieldValue(operationItem, "addNewUser") === "true"
              : undefined;
            input.role = operationItem.role ? getRoleObject(getFieldValue(operationItem, "role")) : undefined;
            input.listPointOfContact = pointOfContactList;

            creationRequests.push(createPointOfContact(input));
          }
        } else {
          ErrorLogger("Error: Point of Contact needs a first and last name!");
        }
      }

      await Promise.allSettled(creationRequests);

      updateToast({
        id: toastId,
        icon: "success",
        header: `Success! Updated Points of Contact`,
      });

      resetFunction?.();
    }
  };

  // Perform the creations
  useEffect(() => {
    if (csvImporter.dataJSONReady) {
      createItemsFromCsv(csvImporter.dataJSONReady);
    }
  }, [csvImporter.dataJSONReady]);

  return {
    ...csvImporter,
  };
};
