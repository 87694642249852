import { useContext } from "react";

import { ErrorLogger } from "@utils/EventLogger";

import { isNonEmptyArray, isNullOrUndefined } from "@rivial-security/func-utils";

import { AllowedFileUploadTypes } from "../../../../definitions/constants/allowedFileUploadTypes";
import { useForm } from "../../../../hooks/views/useForm";
import { OrganizationContext } from "../../../../utils/Context/OrganizationContext";
import { UIContext } from "../../../../utils/Context/UIContext";
import { tryFunction } from "../../../../utils/Functions/tryFunction";
import { performToastOperation } from "../../../../utils/Toasts/functions/toastOperation";
import { PolicyVersionStatus } from "../constants/PolicyVersionStatus";
import { createPolicy } from "../functions/createPolicy";
import { createPolicyVersion } from "../functions/createPolicyVersion";
import { validatePolicyFiles } from "../functions/validatePolicyFiles";

/**
 * Add a Single Policy to the database.
 * @param {string} moduleField - module of the policy
 * @param {string} organizationID - selected organization ID
 * @param {function} getNewItem - function to get the new item
 * @param {function} resetFunction - function to reset the form
 * @param {function} toggleModal - function to toggle the modal
 * @param {boolean} forceInitialApproval - if true, any uploaded file will be marked as first approved version
 * of the policy, the user wont be able to change this setting
 * @param {string} forceInitialApprovalTooltip - tooltip to show why forceInitialApproval is true
 * @return {{display: *, input: {}, setInput: Function, submitDisabled: boolean}}
 */
export const usePolicyForm = ({
  moduleField,
  organizationID,
  getNewItem,
  resetFunction,
  toggleModal,
  forceInitialApproval,
  forceInitialApprovalTooltip,
}) => {
  const { addToast, updateToast } = useContext(UIContext);
  const context = useContext(OrganizationContext);

  const onSubmit = async (input) => {
    const { name, approveInitialPolicy, description, files } = input || {};
    tryFunction(toggleModal);

    const { validFiles, invalidFiles, validationMessage } = await validatePolicyFiles(files);
    if (isNonEmptyArray(invalidFiles)) {
      addToast({
        color: "warning",
        header: validationMessage,
      });
    }

    const policy = await performToastOperation({
      addToast,
      updateToast,
      operation: async () => {
        const policy = await createPolicy({
          input: {
            name,
            module: moduleField,
            ownerGroup: organizationID,
          },
        });

        if (!policy?.id) {
          throw Error("Policy creation failed.");
        }

        return policy;
      },
      inProgressText: `Creating a Security Policy...`,
      failedText: `Failed to create a Security Policy`,
      successText: `Successfully created a Security Policy`,
      iconColor: `success`,
    });

    // Return when no policy id available to assign a version to
    if (!policy?.id) {
      ErrorLogger("Policy creation has failed!");
      return;
    }

    //Add the policy version if any files were selected in the form
    if (isNonEmptyArray(validFiles)) {
      await performToastOperation({
        addToast,
        updateToast,
        operation: async () => {
          const bucketName = context?.selectedOrganizationObjectMinimal?.s3BucketName;
          await createPolicyVersion({
            policyID: policy?.id,
            description,
            status: approveInitialPolicy ? PolicyVersionStatus.APPROVED : PolicyVersionStatus.DRAFT,
            approvedBy: approveInitialPolicy ? "Initial Policy Upload" : undefined,
            approvalDate: new Date(),
            files: validFiles,
            version: 1,
            organizationID,
            bucketName,
          });
        },
        inProgressText: `Creating a new Policy Version...`,
        failedText: `Creating a new Policy Version Failed`,
        successText: `Successfully created a new Policy Version`,
        iconColor: `success`,
      });
    } else {
      ErrorLogger("No files were selected or the files were invalid.");
    }

    //Calls the general reset function if there is no single element addition function
    if (getNewItem) {
      getNewItem(policy);
    } else if (resetFunction) {
      resetFunction();
    }
  };

  return useForm({
    //Mutation
    submitFunction: onSubmit,

    //Fields
    fieldConfig: {
      name: {
        inputType: "text",
        label: "Name",
        required: true,
      },
      description: {
        inputType: "text",
        label: "Version Description",
        defaultValue: null,
      },
      files: {
        inputType: "file",
        label: "Initial Policy Version to Upload",
        tooltip: `Allowed file types: ${AllowedFileUploadTypes.join(", ")}`,
        fileInputConfig: {
          acceptMultiple: true,
          acceptFileTypes: AllowedFileUploadTypes.join(","),
        },
      },
      approveInitialPolicy: {
        inputType: "switch",
        disabled: !isNullOrUndefined(forceInitialApproval),
        defaultValue: !isNullOrUndefined(forceInitialApproval) ? forceInitialApproval : true,
        label: "Approve Initial Policy Version",
        tooltip: !isNullOrUndefined(forceInitialApprovalTooltip)
          ? forceInitialApprovalTooltip
          : "If enabled, the uploaded files will be marked as the first approved policy version",
        isHidden: ({ input }) => {
          const files = input?.files;
          return !isNonEmptyArray(files);
        },
        warningFunction: (value) => {
          if (!value) {
            return "You will not be able to select this policy as an artifact for an evidence until its first version is approved.";
          } else {
            return "";
          }
        },
      },
    },
  });
};
