/**
 * Author: Jacob Blazina
 * Created At: 10/25/19
 * Edits:
 *
 * Description: A Function.
 *              Deletes a Meeting from the DB.
 *
 *
 */

import { API, graphqlOperation } from "@aws-amplify/api";

import { InfoLogger } from "@utils/EventLogger";

import { generateGraphql } from "@rivial-security/generategraphql";

import { QueryGetItem } from "../../../../hooks/graphql/useQueryGetItem";
import { deleteMeetingParticipant } from "../../../OrganizationManager/PointOfContacts/functions/deleteMeetingParticipant";
import { DeleteTagMeetingLink } from "../../../OrganizationManager/PointOfContacts/functions/DeleteTagMeetingLink";

import { DeleteMeetingDocument } from "./DeleteMeetingDocument";

const { deleteMutation: deleteMeetingMutation } = generateGraphql("Meeting");

const getMeetingDelete = /* GraphQL */ `
  query GetMeeting($id: ID!) {
    getMeeting(id: $id) {
      id
      pointOfContacts(limit: 200) {
        items {
          id
        }
      }
      documents(limit: 200) {
        items {
          id
          file {
            bucket
            region
            key
          }
        }
      }
      tags(limit: 200) {
        items {
          id
          __typename
        }
      }
    }
  }
`;

export const deleteMeeting = async (meeting) => {
  if (meeting && meeting.id) {
    const item = await QueryGetItem({
      query: getMeetingDelete,
      itemId: meeting.id,
    });

    if (item) {
      const deletePOCPromises =
        item.pointOfContacts?.items?.map((meetingLink) => deleteMeetingParticipant(meetingLink)) || [];

      const deleteDocumentPromises = item.documents?.items?.map((document) => DeleteMeetingDocument(document)) || [];

      const deleteTagPromises = item.tags?.items?.map((tagLink) => DeleteTagMeetingLink(tagLink)) || [];

      const allPromises = [...deletePOCPromises, ...deleteDocumentPromises, ...deleteTagPromises];

      await Promise.allSettled(allPromises);

      await API.graphql(
        graphqlOperation(deleteMeetingMutation, {
          input: {
            id: item.id,
          },
        }),
      ).then(() => InfoLogger(`Meeting ${item.name} was Successfully Deleted`));
    }
  }
};
