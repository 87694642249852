import { round } from "@utils/Functions/Number/round";
import { getMaturityLevel } from "@views/Charts/VISCO/functions/getMaturityLevel";

import type { ProgramElement } from "schema-types";

export interface MatrixData {
  programElements?: {
    items?: ProgramElement[];
  };
}

export interface RadarChartDataPoint {
  governance: string;
  "Current Maturity": number;
  "Maturity Goal": number;
}

/**
 * Formats matrix data for use in a radar chart
 * @param matrixData - The matrix data containing program elements
 * @returns Array of formatted data points for the radar chart
 */
export const formatMatrixDataToRadarChart = (matrixData: MatrixData): RadarChartDataPoint[] => {
  if (!matrixData?.programElements?.items?.length) return [];

  return matrixData.programElements.items.map((element: ProgramElement) => {
    const result = getMaturityLevel(element);
    const currentValue = result ? result[0] : 0;
    const goalValue = result ? result[1] : 0;

    const current = typeof currentValue === "number" ? currentValue : 0;
    const goal = typeof goalValue === "number" ? goalValue : 0;

    return {
      governance: element.name ?? "",
      "Current Maturity": isNaN(current) ? 0 : round(current, 2),
      "Maturity Goal": isNaN(goal) ? 0 : round(goal, 2),
    };
  });
};
