import { PriorityLevel, ReactstrapColor } from "./types";

import type { PriorityScaleSegment } from "./types";

export interface GetPriorityScaleSegmentParams {
  priority?: number | null;
}

const criticalScaleSegment: PriorityScaleSegment = {
  text: PriorityLevel.CRITICAL,
  color: ReactstrapColor.DANGER,
  chipTextColor: "var(--color-text-on-error)",
  chipBackgroundColor: "var(--color-denotive-error-light)",
  maxPriority: 600,
};
const noneScaleSegment: PriorityScaleSegment = {
  text: PriorityLevel.NONE,
  color: ReactstrapColor.SECONDARY,
  chipTextColor: "var(--color-text-on-info)",
  chipBackgroundColor: "var(--color-denotive-info-light)",
  maxPriority: -1,
};

export const priorityScale: PriorityScaleSegment[] = [
  noneScaleSegment,
  {
    text: PriorityLevel.LOW,
    color: ReactstrapColor.SUCCESS,
    chipTextColor: "var(--color-text-on-info)",
    chipBackgroundColor: "var(--color-denotive-info-light)",
    maxPriority: 100,
  },
  {
    text: PriorityLevel.LOW_MEDIUM,
    color: ReactstrapColor.SUCCESS,
    chipTextColor: "var(--color-text-on-info)",
    chipBackgroundColor: "var(--color-denotive-info-light)",
    maxPriority: 200,
  },
  {
    text: PriorityLevel.MEDIUM,
    color: ReactstrapColor.WARNING,
    chipTextColor: "var(--color-text-on-warning)",
    chipBackgroundColor: "var(--color-denotive-warning-light)",
    maxPriority: 300,
  },
  {
    text: PriorityLevel.MEDIUM_HIGH,
    color: ReactstrapColor.WARNING,
    chipTextColor: "var(--color-text-on-warning)",
    chipBackgroundColor: "var(--color-denotive-warning-light)",
    maxPriority: 400,
  },
  {
    text: PriorityLevel.HIGH,
    color: ReactstrapColor.DANGER,
    chipTextColor: "var(--color-text-on-error)",
    chipBackgroundColor: "var(--color-denotive-error-light)",
    maxPriority: 500,
  },
  criticalScaleSegment,
];

export const getPriorityScaleSegment = ({ priority }: GetPriorityScaleSegmentParams): PriorityScaleSegment => {
  const sortedPriorityScale: PriorityScaleSegment[] = priorityScale.toSorted((a, b) => b.maxPriority - a.maxPriority);
  if (!priority) {
    return noneScaleSegment;
  }

  let maxScale: PriorityScaleSegment = criticalScaleSegment;
  for (const currentScaleSegment of sortedPriorityScale) {
    const currentScaleMax = currentScaleSegment.maxPriority;
    if (priority <= currentScaleMax) {
      maxScale = currentScaleSegment;
    }
  }

  return maxScale;
};
