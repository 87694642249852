/**
 * @description Get error message on S3 failure
 * @param {object} error - error object from S3
 * @returns {string}
 */
export const onS3UserErrorMessage = ({ error }) => {
  switch (error?.message) {
    case "Network Failure":
      return `Network Failure, please try again`;
    case "Suspicious file upload":
      return `One of the selected files is not a valid file type, please verify the selected files and try again`;
    default:
      return `Failed to upload Document, please try again`;
  }
};
