export const getMaturityMatrix = /* GraphQL */ `
  query GetMaturityMatrix($id: ID!) {
    getMaturityMatrix(id: $id) {
      id
      name
      description
      ownerGroup
      defaultMatrix
      programElements {
        items {
          id
          name
          description
          programSections {
            items {
              id
              name
              description
              implementationLevels {
                items {
                  id
                  description
                  date
                  level
                  indicator
                  frequency
                  accountable
                  consulted
                  informed
                  programSectionID
                  ownerGroup
                  createdAt
                  updatedAt
                }
                nextToken
              }
              currentLevel {
                id
                description
                date
                level
                indicator
                frequency
                accountable
                consulted
                informed
                programSection {
                  id
                  name
                  description
                  programElementID
                  ownerGroup
                  createdAt
                  updatedAt
                }
                programSectionID
                ownerGroup
                createdAt
                updatedAt
                pointOfContact {
                  id
                  firstName
                  lastName
                  title
                  email
                  phone
                  ownerGroup
                  createdAt
                  updatedAt
                }
              }
              goalLevel {
                id
                description
                date
                level
                indicator
                frequency
                accountable
                consulted
                informed
                programSection {
                  id
                  name
                  description
                  programElementID
                  ownerGroup
                  createdAt
                  updatedAt
                }
                programSectionID
                ownerGroup
                createdAt
                updatedAt
                pointOfContact {
                  id
                  firstName
                  lastName
                  title
                  email
                  phone
                  ownerGroup
                  createdAt
                  updatedAt
                }
              }
              programElement {
                id
                name
                description
                programSections {
                  nextToken
                }
                maturityMatrixID
                ownerGroup
                createdAt
                updatedAt
              }
              programElementID
              ownerGroup
              createdAt
              updatedAt
            }
            nextToken
          }
          maturityMatrixID
          ownerGroup
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
