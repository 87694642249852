import { fileTypeFromStream } from "file-type/core";

import { disallowedFileUploadMimeTypes } from "@definitions/constants/disallowedFileUploadTypes";

import { isNonEmptyArray } from "@rivial-security/func-utils";

import { ErrorLogger } from "../../../../../EventLogger";

import type { FileInfo } from "@syncfusion/ej2-react-inputs";

export const handleFileUploads = async <T extends FileInfo | File>(
  files: T[],
  allowedFileTypes: string[],
): Promise<T[]> => {
  if (!isNonEmptyArray(allowedFileTypes)) {
    ErrorLogger("No allowed file types specified.");
    return Promise.resolve([]);
  }

  if (!isNonEmptyArray(files)) {
    ErrorLogger("No files provided for upload.");
    return Promise.resolve([]);
  }

  // Normalize allowed file types by removing leading dots
  const normalizedAllowedFileTypes = allowedFileTypes.map((type) => (type.startsWith(".") ? type.substring(1) : type));

  try {
    const filteredFiles = await Promise.all(
      files.map(async (file) => {
        // Get the actual File object depending on the type
        const actualFile = "rawFile" in file ? file.rawFile : file;

        if (!(actualFile instanceof File)) return null;

        // Get the file name depending on the type
        const fileName = "name" in file ? file.name : actualFile.name;
        const extension = fileName.split(".").pop()?.toLowerCase();

        const fileType = await fileTypeFromStream(actualFile.stream());

        const isExecutable = disallowedFileUploadMimeTypes.has(fileType?.mime ?? "");
        return extension && normalizedAllowedFileTypes.includes(extension) && !isExecutable ? file : null;
      }),
    );

    // Cast the filtered result to the correct type
    return filteredFiles.filter((file): file is NonNullable<typeof file> => file !== null) as T[];
  } catch (error: unknown) {
    ErrorLogger("Error filtering files:", error as object);
    return Promise.resolve([]);
  }
};
