import { EVIDENCES_FOR_CONTROL_FRAMEWORK } from "@compliance/api/evidencesForControlFramework";
import { createQueryHook } from "@hooks/api/createQueryHook";

import type {
  EvidencesForControlFrameworkQuery,
  EvidencesForControlFrameworkQueryVariables,
} from "@graphql/types/graphql";

export const useEvidencesForControlFramework = createQueryHook<
  EvidencesForControlFrameworkQuery,
  EvidencesForControlFrameworkQueryVariables
>(EVIDENCES_FOR_CONTROL_FRAMEWORK, "Unable to query evidences for control framework");
