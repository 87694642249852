import { ErrorLogger, InfoLogger } from "@utils/EventLogger";
import { GenericDeleteGQL } from "@utils/Functions/Graphql/GenericDeleteGQL";

import type { CustomDashboard } from "@rivial-security/schema-types";

interface DeleteInput {
  objectToDelete: {
    id: string;
    __typename: string;
  };
}

export const deleteCustomDashboard = async (customDashboard: CustomDashboard): Promise<void> => {
  const input: DeleteInput = {
    objectToDelete: {
      id: customDashboard.id,
      __typename: "CustomDashboard",
    },
  };

  try {
    await GenericDeleteGQL(input);
    InfoLogger(`Custom Dashboard was Successfully Deleted!`);
  } catch (e) {
    ErrorLogger("Failed to delete Custom Dashboard", e);
  }
};
