import { gql } from "@graphql/types";

export const EVIDENCES_FOR_CONTROL_FRAMEWORK = gql(`
  query evidencesForControlFramework(
    $frameworkId: ID!,
    $filter: EvidenceFilter,
    $evidencesSort: EvidenceSort,
    $pointsOfContactSort: PointOfContactSort,
    $tagsSort: TagSort
  ) {
    evidencesForControlFramework(frameworkID: $frameworkId, filter: $filter, sort: $evidencesSort) {
      id
      name
      status
      type
      frequency
      pointsOfContact(sort: $pointsOfContactSort) {
        id
        fullName
      }
      tags(sort: $tagsSort) {
        id
        name
        backgroundColor
        description
      }
    }
  }
`);
