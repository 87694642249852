import { useTranslation } from "react-i18next";

import { useControlFrameworkAudits } from "@compliance/hooks";
import { ArtifactGroup } from "@graphql/types/graphql";

import type { Framework } from "@compliance/components/ComplianceDashboardOverviewActionBar";
import type { BaseUseDownloadArtifactsFilter } from "@compliance/hooks/types/DownloadArtifactsFilter";
import type { SelectOption } from "@components/Atoms/SelectField/SelectField";

interface UseDownloadArtifactsFilter extends BaseUseDownloadArtifactsFilter {
  getControlFrameworkAudits: VoidFunction;
  frameworkAuditsLoading: boolean;
}

export const useDownloadArtifactsFrameworkFilter = (framework?: Framework): UseDownloadArtifactsFilter => {
  const { t } = useTranslation("compliance");

  const {
    execute: getControlFrameworkAudits,
    data: controlFrameworkAuditsData,
    loading: frameworkAuditsLoading,
  } = useControlFrameworkAudits(framework?.id ?? "");

  const frameworkOption = [
    {
      label: framework?.name ?? "",
      value: framework?.id ?? "",
    },
  ] satisfies SelectOption[];

  const auditOptions = [
    {
      label: t("all audits", { ns: "compliance" }),
      value: "all",
    },
    ...(controlFrameworkAuditsData?.auditsByControlFramework?.items ?? []),
  ] satisfies SelectOption[];

  const getFilterOptions = (groupBy: ArtifactGroup): SelectOption[] => {
    if (groupBy === ArtifactGroup.Audit && auditOptions.length <= 1) {
      return [];
    }

    return groupBy === ArtifactGroup.Audit ? auditOptions : frameworkOption;
  };

  return {
    getControlFrameworkAudits,
    getFilterOptions,
    frameworkAuditsLoading,
  };
};
