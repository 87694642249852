"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createText = void 0;
const func_utils_1 = require("@rivial-security/func-utils");
const createText = (text, styleName = "Normal", config = {}) => {
    text = (0, func_utils_1.removeNonStandardCharactersFromString)({
        stringContent: text,
        removeNullCharacters: true,
    });
    const createInlines = () => {
        const res = [];
        if (Array.isArray(text)) {
            for (const item of text) {
                res.push({ ...item });
            }
        }
        else {
            res.push({
                characterFormat: {
                    bidi: false,
                    fontSize: config?.fontSize || undefined,
                    underline: config?.underline,
                    bold: config?.bold,
                    italic: config?.italic,
                    fontColor: config?.fontColor || undefined,
                },
                text: `${text}`,
            });
        }
        return res;
    };
    let keepWithNext;
    if (config?.keepWithNext) {
        keepWithNext = true;
    }
    else {
        keepWithNext = styleName !== "Normal";
    }
    return {
        paragraphFormat: {
            textAlignment: config?.textAlignment,
            styleName: `${styleName}`,
            listFormat: {},
            keepWithNext,
        },
        characterFormat: {},
        inlines: [...createInlines()],
    };
};
exports.createText = createText;
