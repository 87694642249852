import React from "react";

import RiskControlDataGrid from "@views/Risk/RiskConfig/ControlCategories/components/SubControls/components/RiskControlDataGrid";

import { withOrganizationCheck } from "../../../../utils/Context/withOrganizationCheck";
import NestedField from "../../../../utils/GenericComponents/NestedField/NestedField";
import CreateControlCategorySubControl from "../../../Risk/RiskConfig/ControlCategories/components/SubControls/components/CreateControlCategorySubControl";
import RiskControlDetails from "../../../Risk/RiskConfig/ControlCategories/components/SubControls/components/RiskControlDetails";

/**
 * Custom field displaying the risk control linked this vendor sub control
 * @param {object} item - the vendor control info with the risk control field populated
 * @param {function} resetFunction - function to call when the link has changed and 'item' needs to be required
 * @return {JSX.Element}
 */
const VendorSubControlRiskControl = ({ item, resetFunction }) => {
  return (
    <NestedField
      typename={"RiskControl"}
      parentTypename={"VendorSubControl"}
      item={item}
      field={"riskControl"}
      idField={"riskControlID"}
      parentIdField={"vendorSubControlID"}
      displayField={"name"}
      detailsComponent={<RiskControlDetails />}
      form={<CreateControlCategorySubControl />}
      grid={<RiskControlDataGrid showNotes={false} />}
      biDirectional={true}
      resetFunction={resetFunction}
    />
  );
};

export default withOrganizationCheck(VendorSubControlRiskControl);
