import type { ComponentPropsWithRef, MouseEvent, ReactElement } from "react";

import cx from "classnames";
import { useTranslation } from "react-i18next";

import Button, { ButtonVariant } from "@components/Atoms/Button/Button";
import IconButton from "@components/Atoms/IconButton/IconButton";
import ListItem from "@components/Atoms/ListItem/ListItem";
import { getIcon, IconName } from "@components/Ions/icons/components/Icons";
import List from "@components/Molecules/List/List";
import useHandleOutsideEvent from "@hooks/functional/useHandleOutsideEvent";

import styles from "./DropdownMenu.module.scss";

import type { ListItemProps } from "@components/Atoms/ListItem/ListItem";
import type { ListProps } from "@components/Molecules/List/List";

export interface DropdownMenuProps extends ComponentPropsWithRef<"div"> {
  /** Menu items to display in the dropdown. */
  menuItems: ListItemProps[];
  /** Icon to display in the trigger button. */
  iconName?: IconName;
  /** Optional controlled visibility state. */
  isVisible?: boolean;
  /** Optional callback for visibility changes. */
  onVisibilityChange?: (isVisible: boolean) => void;
  /** Use a `Button` component instead of `IconButton`. */
  isButton?: boolean;
  /** Optional props for the list component. */
  listProps?: ListProps;
}

/**
 * A dropdown menu component that displays a list of clickable items.
 */
const DropdownMenu = ({
  menuItems,
  iconName = IconName.MORE,
  className,
  isVisible: controlledIsVisible,
  onVisibilityChange,
  isButton = false,
  listProps,
  ...rest
}: DropdownMenuProps): ReactElement => {
  const { t } = useTranslation();

  const { ref: menuRef, isVisible: uncontrolledIsVisible, setIsVisible } = useHandleOutsideEvent<HTMLDivElement>(false);

  // Use controlled state if provided, otherwise use internal state
  const isVisible = controlledIsVisible ?? uncontrolledIsVisible;

  const toggleVisibility = (forcedState: boolean | undefined): void => {
    const newState = forcedState ?? !isVisible;

    onVisibilityChange?.(newState);
    setIsVisible(newState);
  };

  const handleMenuItemClick = (
    event: MouseEvent<HTMLButtonElement>,
    onClick?: (event: MouseEvent<HTMLButtonElement>) => void,
  ): void => {
    toggleVisibility(false);
    onClick?.(event);
  };

  return (
    <div ref={menuRef} className={cx(styles["dropdown-menu"], className)} {...rest}>
      {isButton ? (
        <Button
          variant={ButtonVariant.SECONDARY}
          startIcon={getIcon(iconName)}
          aria-label={!isVisible ? t("show menu") : t("close menu")}
          onClick={() => toggleVisibility(undefined)}
        />
      ) : (
        <IconButton
          onClick={() => toggleVisibility(undefined)}
          iconName={iconName}
          aria-label={!isVisible ? t("show menu") : t("close menu")}
        />
      )}
      <List
        hidden={!isVisible}
        className={cx(styles["dropdown-menu__list"], {
          [styles["dropdown-menu__list--hidden"]!]: !isVisible,
          [styles["dropdown-menu__list--is-button"]!]: isButton,
        })}
        {...listProps}
      >
        {menuItems.map((item) => (
          <ListItem
            key={item.id}
            onClick={(e: MouseEvent<HTMLButtonElement>) => handleMenuItemClick(e, item.onClick)}
            variant={item.variant}
            icon={item.icon}
          >
            {item.children}
          </ListItem>
        ))}
      </List>
    </div>
  );
};

export default DropdownMenu;
