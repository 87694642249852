import type { ReactNode } from "react";
import React, { useCallback, useEffect, useState } from "react";

import cx from "classnames";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import Button, { ButtonVariant } from "@components/Atoms/Button/Button";
import { getIcon, IconName } from "@components/Ions/icons/components/Icons";
import { onboardingTasks } from "@dashboard/constants/onboardingTasks";

import styles from "./ScribeHowEmbed.module.scss";

interface ScribeHowEmbedProps {
  showToggle?: boolean;
}

const ScribeHowEmbed = ({ showToggle = true }: ScribeHowEmbedProps): ReactNode => {
  const [isVisible, setIsVisible] = useState(false);
  const iframeRef = React.useRef<HTMLIFrameElement>(null);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const tourId = searchParams.get("tour");

  const { t } = useTranslation(["dashboard"]);
  const onboardingTasksMap = new Map<string, (typeof onboardingTasks)[number]>();

  if (onboardingTasks && Array.isArray(onboardingTasks)) {
    for (const task of onboardingTasks) {
      if (task.platformRoute) {
        onboardingTasksMap.set(task.onboardingTourId, task);
      }
    }
  }

  const tour = onboardingTasksMap.get(tourId ?? "");

  useEffect(() => {
    if (tourId && tour && tour.platformRoute === location.pathname) {
      setIsVisible(true);
    }
  }, [tour, tourId]);

  const toggleTutorial = useCallback(
    (event?: React.MouseEvent): void => {
      if (event) {
        event.preventDefault();
        event.stopPropagation();
      }

      setIsVisible((prev) => !prev);

      if (!isVisible && iframeRef.current) {
        setTimeout(() => {
          iframeRef.current?.focus();
        }, 100);
      }
    },
    [isVisible],
  );

  if (!tourId || !tour) {
    return null;
  }

  const title = t(`onboardingTasks.${tour.name}`, { defaultValue: tour.name });

  return (
    <div className={styles["scribe-tutorial"]}>
      {showToggle && (
        <div className={styles["scribe-tutorial__button-container"]}>
          <Button
            startIcon={getIcon(IconName.HELP)}
            onClick={toggleTutorial}
            aria-label="Open tutorial"
            title={title}
          />
        </div>
      )}

      <div
        className={cx(styles["scribe-tutorial__overlay"], {
          [styles["scribe-tutorial__overlay--visible"]!]: isVisible,
        })}
      >
        <div className={styles["scribe-tutorial__container"]}>
          <div className={styles["scribe-tutorial__header"]}>
            <h3 className={styles["scribe-tutorial__title"]}>{title}</h3>
            <Button
              onClick={toggleTutorial}
              variant={ButtonVariant.TEXT}
              startIcon={getIcon(IconName.CLOSE)}
              aria-label="Close tutorial"
              className={styles["scribe-tutorial__close-button"]}
            />
          </div>

          <div className={styles["scribe-tutorial__content"]}>
            <iframe
              ref={iframeRef}
              src={tour?.onboardingTourUrl}
              className={styles["scribe-tutorial__iframe"]}
              allowFullScreen
              title={title}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ScribeHowEmbed;
