/**
 * Updates the 'value' and 'originalValue' states after the update goes through
 *
 * @param {function} setOriginalValue - sets the originalValue state
 * @param {function} setValue - sets the value state
 * @param {*} result - the result value to set the state to
 */
import { isNullOrUndefined } from "@rivial-security/func-utils";

export const handleValueUpdates = ({ setOriginalValue, setValue, result }) => {
  if (isNullOrUndefined(setValue)) {
    throw Error("setValue function param cannot be null or undefined");
  }

  if (typeof setValue !== "function") {
    throw Error("setValue param must be a function");
  }

  if (isNullOrUndefined(setOriginalValue)) {
    throw Error("setValue function param cannot be null or undefined");
  }

  if (typeof setOriginalValue !== "function") {
    throw Error("setOriginalValue param must be a function");
  }

  setOriginalValue(result);
  setValue(result);
};
