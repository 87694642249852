import type { RiskControl, SystemControlCategoryLink } from "@rivial-security/schema-types";

import { InfoLogger } from "@utils/EventLogger";
import { getDetailedRiskControlItem } from "@views/Compliance/EvidenceRiskControlLinks/functions/getDetailedRiskControlItem";

export interface ProcessControlSystemLinks {
  controlSystemLinks: SystemControlCategoryLink[];
  systemsEncountered: Record<string, boolean>;
  riskControl: RiskControl;
}

export interface ProcessControlSystemLinksResult {
  updatedSystemsEncountered: Record<string, boolean>;
  controlSystemLinksWithOverrides: SystemControlCategoryLink[];
}

export const processControlSystemLinks = ({
  controlSystemLinks,
  systemsEncountered,
  riskControl,
}: ProcessControlSystemLinks): ProcessControlSystemLinksResult => {
  const updatedSystemsEncountered = { ...systemsEncountered };
  const controlSystemLinksWithOverrides: SystemControlCategoryLink[] = [];

  if (!Array.isArray(controlSystemLinks)) {
    return { updatedSystemsEncountered, controlSystemLinksWithOverrides };
  }

  for (const link of controlSystemLinks) {
    const system = link?.system;

    if (Object.hasOwn(updatedSystemsEncountered, system?.id)) {
      InfoLogger("system already encountered, skipping:", system?.id);
      continue;
    }

    const hasOverrides = system?.riskControlOverrides?.some(
      (override) =>
        override?.riskControlId === riskControl?.id && override?.enabledFields && override?.enabledFields?.length > 0,
    );

    if (hasOverrides) {
      updatedSystemsEncountered[system.id] = true;

      const override = system?.riskControlOverrides?.find((o) => o?.riskControlId === riskControl?.id);

      controlSystemLinksWithOverrides.push({
        ...getDetailedRiskControlItem({
          standardRiskControl: riskControl,
          riskControlOverride: {
            ...override,
            system,
          },
        }),
        id: system.id,
      });
    }
  }

  return { updatedSystemsEncountered, controlSystemLinksWithOverrides };
};
