import { withOrganizationCheck } from "../../../../utils/Context/withOrganizationCheck";
import { useVendorSolutionDataGrid } from "../hooks/useVendorSolutionDataGrid";

/**
 * Allows to display a list of vendor solutions for an organization
 * @param props
 */
const VendorSolutionsDataGrid = (props) => {
  const result = useVendorSolutionDataGrid({ dashboardDisplay: true, ...props }).display;

  return result;
};

export default withOrganizationCheck(VendorSolutionsDataGrid);
